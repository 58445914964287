/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';
import {
  type InAppBrowserEvent,
  type InAppBrowserObject,
  InAppBrowser,
} from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Device } from '@ionic-native/device/ngx';

import {
  type Observable,
  type Subscription,
  merge,
  of as observableOf,
  Subject,
} from 'rxjs';
import { delay, filter, first, map, pairwise, startWith } from 'rxjs/operators';

import { SAM_IPHONE_UI_ADAPTER_SCRIPT } from '@core/auth/sam/sam-iphone-ui-adapter';
import { IPhoneXSeriesModels } from '@core/device/device-manufacturer-models';
import { ScreenSecurityService } from '@core/security/screen-security.service';

import { AngularRequestor } from './AngularRequestor';
import { AuthorizationRequestHandlerBase } from './AuthorizationRequestHandler.base';
import {
  AUTHORIZATION_RESPONSE_KEY,
  IN_APP_BROWSER_OPTIONS,
  IOS_INAPPBROWSER_IGNORE_ERROR,
  OpenIdConfig,
  SignInBrowserState,
} from './OpenId.dictionary';
import { StorageService } from './storage-service';

@Injectable()
class AuthorizationRequestHandlerMobile extends AuthorizationRequestHandlerBase {
  protected signInOutInAppBrowserObject: InAppBrowserObject;
  protected signInNavigatorLoadStartSink: Subject<InAppBrowserEvent> =
    new Subject<InAppBrowserEvent>();
  protected signInLoadSubscription: Subscription;
  protected signInCloseSubscription: Subscription;
  protected signInErrorSubscription: Subscription;
  protected signOutNavigatorLoadStartSink: Subject<InAppBrowserEvent> =
    new Subject<InAppBrowserEvent>();
  protected signOutLoadStartSubscription: Subscription;
  protected signOutLoadStopSubscription: Subscription;
  protected signOutCloseSubscription: Subscription;
  protected signOutErrorSubscription: Subscription;
  protected signInSAMIPhoneUISubscription: Subscription;

  private signInInAppBrowserState: SignInBrowserState =
    SignInBrowserState.close;
  private shouldForceUpdate: boolean = false;

  private isIphoneXSeries: boolean;

  // customPostLogoutUrl and it usage should be removed as soon as IA redirect to post_logout_url
  // from logout page after session expiration will be fixed by IA team
  private customPostLogoutUrl: string = `${this.config.authority}logout`;
  private customSAMPostLogoutUrls: string[] = [
    'https://login.samexternal.net/Logout?v=2.0',
    'https://login.ihsmarkit.com/Logout?v=2.0',
  ];
  private customSAMEndsessionLogoutUrls: string[] = [
    'https://sam.ihsmarkit.com/sso/oauth2/connect/endSession',
    'https://sam.samexternal.net/sso/oauth2/connect/endSession',
  ];

  private signInRedirect$: Observable<string> =
    this.signInNavigatorLoadStartSink.asObservable().pipe(
      filter((v: InAppBrowserEvent) => this.isRedirectURIIncluded(v)),
      map((inAppBrowserEvent: InAppBrowserEvent) => inAppBrowserEvent.url)
    );

  private signOutNavigatorLoadStart$: Observable<InAppBrowserEvent> =
    this.signOutNavigatorLoadStartSink.asObservable();

  private signOutRedirect$: Observable<InAppBrowserEvent> = merge(
    this.signOutNavigatorLoadStart$,
    this.onSignOutErrorSink.asObservable()
  ).pipe(
    startWith(undefined as InAppBrowserEvent),
    pairwise(),
    delay(1000),
    filter(([prev, next]: [InAppBrowserEvent, InAppBrowserEvent]) =>
      this.isPostLogOutRedirectURIIncluded(prev, next)
    ),
    map(([_, next]: [InAppBrowserEvent, InAppBrowserEvent]) => next)
  );

  private signedOut$: Observable<void> = this.signOutRedirect$.pipe(
    map(() => this.clearSignOutInAppBrowser())
  );

  constructor(
    protected requestor: AngularRequestor,
    protected config: OpenIdConfig,
    // @ts-ignore
    protected iab: InAppBrowser,
    protected storage: StorageService,
    protected device: Device
  ) {
    super(requestor, config, iab, storage);
    this.init();

    this.signInRedirect$.subscribe((url: string) => this.onSignIn(url));
    this.signedOut$.subscribe();

    this.isIphoneXSeries = IPhoneXSeriesModels.includes(this.device.model);
    this.listenToAppMinimize();
  }

  public stopSignIn(): void {
    this.shouldForceUpdate = true;
  }

  public getSignInBrowserState(): Observable<SignInBrowserState> {
    return observableOf(this.signInInAppBrowserState);
  }

  public signOut(url: string): Observable<void> {
    this.signInOutInAppBrowserObject = this.iab.create(
      url,
      '_blank',
      IN_APP_BROWSER_OPTIONS
    );

    this.signOutLoadStartSubscription = this.signInOutInAppBrowserObject
      .on('loadstart')
      .subscribe((event: InAppBrowserEvent) =>
        this.signOutNavigatorLoadStartSink.next(event)
      );

    this.signOutLoadStopSubscription = this.signInOutInAppBrowserObject
      .on('loadstop')
      .subscribe((event: InAppBrowserEvent) =>
        this.signOutNavigatorLoadStartSink.next(event)
      );

    this.signOutErrorSubscription = this.signInOutInAppBrowserObject
      .on('loaderror')
      .subscribe((event: InAppBrowserEvent) => this.onSignOutError(event));

    this.signOutCloseSubscription = this.signInOutInAppBrowserObject
      .on('exit')
      .subscribe((event: InAppBrowserEvent) =>
        this.onSignOutErrorSink.next(event)
      );

    this.signInOutInAppBrowserObject.show();

    return this.signedOut$;
  }

  protected getAuthorizationResponseKey(): string {
    return undefined;
  }

  protected signIn(url: string): void {
    if (this.shouldForceUpdate) {
      return;
    }
    this.signInOutInAppBrowserObject = this.iab.create(
      url,
      '_blank',
      IN_APP_BROWSER_OPTIONS
    );

    this.signInLoadSubscription = this.signInOutInAppBrowserObject
      .on('loadstart')
      .subscribe((event: InAppBrowserEvent) =>
        this.signInNavigatorLoadStartSink.next(event)
      );

    this.signInErrorSubscription = this.signInOutInAppBrowserObject
      .on('loaderror')
      .subscribe((event: InAppBrowserEvent) => {
        if (event && event.message !== IOS_INAPPBROWSER_IGNORE_ERROR) {
          this.onSignInError(event);
        }
      });

    if (this.isIphoneXSeries) {
      this.adaptUIForIphone();
    }

    this.signInCloseSubscription = this.signInOutInAppBrowserObject
      .on('exit')
      .subscribe(() => {
        this.setSignInBrowserState(SignInBrowserState.close);
        this.onSignInErrorSink.next();
      });

    this.signInOutInAppBrowserObject.show();
    this.setSignInBrowserState(SignInBrowserState.open);
  }

  private onSignInError(event: InAppBrowserEvent): void {
    this.clearSignInInAppBrowser();

    if (!this.isRedirectURIIncluded(event)) {
      this.onSignInErrorSink.next();
    }
  }

  private onSignIn(url: string): void {
    this.clearSignInInAppBrowser();
    this.completeAuthorization(url).pipe(first()).subscribe();
  }

  private clearSignInInAppBrowser(): void {
    this.signInLoadSubscription.unsubscribe();
    this.signInErrorSubscription.unsubscribe();
    this.signInCloseSubscription.unsubscribe();

    this.signInOutInAppBrowserObject.close();
    this.setSignInBrowserState(SignInBrowserState.close);
  }

  private setSignInBrowserState(state: SignInBrowserState): void {
    this.signInInAppBrowserState = state;
  }

  private onSignOutError(event: InAppBrowserEvent): void {
    const NSURLErrorDomainErrorCode = -999;

    if (event.code !== NSURLErrorDomainErrorCode) {
      if (!this.isPostLogOutRedirectURIIncluded(event, event)) {
        this.onSignOutErrorSink.next(event);
      }
    }

    this.clearSignOutInAppBrowser();
  }

  private clearSignOutInAppBrowser(): void {
    this.signOutLoadStartSubscription.unsubscribe();
    this.signOutLoadStopSubscription.unsubscribe();
    this.signOutCloseSubscription.unsubscribe();
    this.signOutErrorSubscription.unsubscribe();

    if (this.signInSAMIPhoneUISubscription) {
      this.signInSAMIPhoneUISubscription.unsubscribe();
    }

    this.signInOutInAppBrowserObject.close();

    this.setSignInBrowserState(SignInBrowserState.close);
  }

  private isRedirectURIIncluded({ url }: InAppBrowserEvent): boolean {
    return url.includes(this.config.redirect_uri);
  }

  private isPostLogoutUrl(url: string): boolean {
    return (
      url === this.config.post_logout_redirect_uri ||
      Boolean(
        this.customSAMPostLogoutUrls.filter((customUrl) => url === customUrl)
          .length
      ) ||
      Boolean(
        this.customSAMEndsessionLogoutUrls.filter((customUrl) =>
          url.includes(customUrl)
        ).length
      )
    );
  }

  private isPostLogOutRedirectURIIncluded(
    prev: InAppBrowserEvent,
    { url, type }: InAppBrowserEvent
  ): boolean {
    console.log(url);
    if (this.isPostLogoutUrl(url)) {
      return true;
    }
    return (
      type === 'loadstop' &&
      prev.type !== 'loaderror' &&
      url === this.customPostLogoutUrl
    );
  }

  private adaptUIForIphone(): void {
    this.signInSAMIPhoneUISubscription = this.signInOutInAppBrowserObject
      .on('loadstop')
      .subscribe({
        next: () => {
          this.signInOutInAppBrowserObject.executeScript({
            code: SAM_IPHONE_UI_ADAPTER_SCRIPT,
          });
        },
      });
  }

  private listenToAppMinimize(): void {
    ScreenSecurityService.onPause?.subscribe(() => {
      if (this.signInOutInAppBrowserObject) {
        this.signInOutInAppBrowserObject?.hide();
      }
    });
    ScreenSecurityService.onResume?.subscribe(() => {
      if (this.signInOutInAppBrowserObject) {
        this.signInOutInAppBrowserObject?.show();
      }
    });
  }
}

export { AUTHORIZATION_RESPONSE_KEY, AuthorizationRequestHandlerMobile };
