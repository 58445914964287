/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';
import { type TokenResponse } from '@openid/appauth';

import {
  BehaviorSubject,
  defer as observableDefer,
  interval as observableInterval,
  Subscription,
  throwError as observableThrowError,
} from 'rxjs';
import { first, mapTo, mergeMap, switchMap, tap } from 'rxjs/operators';

import { AuthService } from '@core/auth/auth.service';

import { BiometricIDAuthVerifyService } from '../biometric-id-auth-verify.service';
import { BiometricIDNotSetForAuthException } from '../biometric-id-not-set-for-auth.exception';

// does not used adter MOB-1795
// TODO make this great again
@Injectable()
export class BiometricOfflineAuthService {
  private subscriptions: Subscription = new Subscription();

  constructor(
    private authService: AuthService,
    private biometricIDAuthVerifyService: BiometricIDAuthVerifyService
  ) {}

  public start(): void {
    observableDefer(() =>
      this.biometricIDAuthVerifyService.checkBiometricAuthAvailability()
    )
      .pipe(
        first(),
        mergeMap((isBiometricAuthAvailable) => {
          if (isBiometricAuthAvailable) {
            return this.authService.getTokenFromStorage();
          }

          return observableThrowError(
            new BiometricIDNotSetForAuthException(
              'BiometricIDAuthService:: Unable to verify user with Biometric ID'
            )
          );
        }),
        tap((tokenResponse) => this.startTokenExpirationSub(tokenResponse))
      )
      .subscribe();
  }

  public stop(): void {
    this.subscriptions.unsubscribe();
  }

  private startTokenExpirationSub(tokenResponse: TokenResponse): void {
    const expiresAtInSeconds = tokenResponse.issuedAt + tokenResponse.expiresIn;
    const expiresIn = expiresAtInSeconds * 1000 - Date.now();
    const tokenInspiredSub = new BehaviorSubject<number>(expiresIn);

    this.subscriptions.add(
      tokenInspiredSub
        .pipe(
          switchMap((period) => observableInterval(period)),
          mergeMap(() => this.biometricIDAuthVerifyService.verifyViaHardware()),
          mergeMap((isVerified: boolean) => {
            if (isVerified) {
              return this.authService.startSignInMainWindow().pipe(mapTo(null));
            }
          }),
          tap(() => {
            tokenInspiredSub.next(tokenResponse.expiresIn * 1000);
          })
        )
        .subscribe()
    );
  }
}
