/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';

import { type BaseRootDocumentModel } from '@workspace-documents/root-structure/base-root-document.model';

import { type PermissionMap } from '../../models/permission-map.model';
import { type PermissionNode } from '../../models/permission-node.model';
import {
  type PermissionPayload,
  PermissionLevel,
} from '../../models/permission-set.model';

@Injectable()
export class PermissionTreeService {
  public mapRootToPermissionsTree(
    root: BaseRootDocumentModel,
    permissions: PermissionPayload[]
  ): PermissionNode[] {
    const nodes = [
      {
        ...root,
        name: 'Documents',
        isExpanded: true,
      },
    ];

    const permissionNodes: PermissionNode[] = nodes as PermissionNode[];
    const permissionMap: PermissionMap = this.getPermissionMap(permissions);

    this.setPermissionsToTree(permissionNodes, permissionMap);

    return permissionNodes;
  }

  public hasPermissionLevel(
    node: PermissionNode,
    permissionLevel: PermissionLevel
  ): boolean {
    return node.permissionLevels.has(permissionLevel);
  }

  public hasMixedPermissionLevel(node: PermissionNode): boolean {
    return node.permissionLevels.size > 1;
  }

  public hasAnyPermissionLevel(node: PermissionNode): boolean {
    return !!node.permissionLevels.size;
  }

  public isFolder(node: BaseRootDocumentModel): boolean {
    return node.type === 'Folder' || node.type === 'Package';
  }

  public isFile(node: BaseRootDocumentModel): boolean {
    return node.type === 'File';
  }

  private getPermissionMap(permissions: PermissionPayload[]): PermissionMap {
    return permissions.reduce((permissionMap, permission) => {
      permissionMap.set(permission.nodeId, permission);

      return permissionMap;
    }, new Map());
  }

  private setPermissionsToTree(
    nodes: PermissionNode[],
    permissionMap: PermissionMap
  ): Set<PermissionLevel> {
    return nodes.reduce(
      (permissionLevels: Set<PermissionLevel>, node: PermissionNode) => {
        if (this.isFolder(node)) {
          node.permissionLevels = this.setPermissionsToTree(
            node.children,
            permissionMap
          );
          node.permissionLevels.forEach((level) => permissionLevels.add(level));
        } else {
          const permissionLevel: PermissionLevel = this.getNodePermissionLevel(
            node.id,
            permissionMap
          );
          node.permissionLevels = new Set([permissionLevel]);
          permissionLevels.add(permissionLevel);
        }

        return permissionLevels;
      },
      new Set()
    );
  }

  private getNodePermissionLevel(
    nodeId: number,
    permissionMap: PermissionMap
  ): PermissionLevel {
    const mappedPermission: PermissionPayload = permissionMap.get(nodeId);

    return mappedPermission
      ? mappedPermission.permissionLevel
      : PermissionLevel.NoAccess;
  }
}
