/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';

import {
  type Observable,
  forkJoin as observableForkJoin,
  of as observableOf,
  throwError as observableThrowError,
} from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { OngoingCompanyDetails } from '@core/company-data/ongoingCompanyDetails';
import { type TransactionWorkspace } from '@tvdr/workspace/transaction-workspace.model';

import { type OngoingWorkspace } from '../../../workspace/ongoing/workspace/ongoing-workspace.model';
import { CompanyOfflineStoreService } from './company-offline-store.service';
import { OngoingOfflineDashboardStoreService } from './ongoing-offline-dashboard-store.service';
import { PcsOfflineDashboardNoDataException } from './pcs-offline-dashboard-no-data.exception';
import { TransactionOfflineDashboardStoreService } from './transaction-offline-dashboard-store.service';

@Injectable({
  providedIn: 'root',
})
export class OfflineDashboardContentService {
  constructor(
    private companyOfflineStoreService: CompanyOfflineStoreService,
    private ongoingOfflineDashboardStoreService: OngoingOfflineDashboardStoreService,
    private transactionOfflineDashboardStoreService: TransactionOfflineDashboardStoreService
  ) {}

  public getCompanies(): Observable<OngoingCompanyDetails[]> {
    return this.companyOfflineStoreService.getCompanies().pipe(
      mergeMap((companies) => {
        if (!companies) {
          return observableOf([]);
        }
        return observableOf(companies);
      })
    );
  }

  public getOngoingDashboardItems(
    companyId: string
  ): Observable<OngoingWorkspace[]> {
    return this.ongoingOfflineDashboardStoreService
      .getDashboardItemByCompanyId(companyId)
      .pipe(
        mergeMap((dashboardItems) => {
          if (!dashboardItems) {
            const exception = new PcsOfflineDashboardNoDataException(
              'OfflineDashboardContentService::getOngoingDashboardItems: ' +
                'No saved ongoing dashboard items data'
            );
            return observableThrowError(exception);
          }
          return observableOf(dashboardItems);
        })
      );
  }

  public getTransactionDashboardItems(): Observable<TransactionWorkspace[]> {
    return this.transactionOfflineDashboardStoreService
      .getDashboardItems()
      .pipe(
        mergeMap((dashboardItems) => {
          if (!dashboardItems) {
            return observableOf([]);
          }
          return observableOf(dashboardItems);
        })
      );
  }

  public getOngoingCompanyDetails(
    companyId: string
  ): Observable<OngoingCompanyDetails> {
    const companiesStream = this.companyOfflineStoreService.getCompanies();
    const ongoingDashboardItemsStream =
      this.ongoingOfflineDashboardStoreService.getDashboardItemByCompanyId(
        companyId
      );

    return observableForkJoin([
      companiesStream,
      ongoingDashboardItemsStream,
    ]).pipe(
      mergeMap(([companies, ongoingDashboardItems]) => {
        const companyData: OngoingCompanyDetails =
          companies &&
          companies.find(
            (company: OngoingCompanyDetails) => company.id === companyId
          );
        if (!companyData || !ongoingDashboardItems) {
          const exception = new PcsOfflineDashboardNoDataException(
            'OfflineDashboardContentService::' +
              'getOngoingCompanyDetails: ' +
              'No saved companyData or ongoingDashboardItems data'
          );
          return observableThrowError(exception);
        }

        const ongoingCompanyDetails = new OngoingCompanyDetails();
        ongoingCompanyDetails.id = companyData.id;
        ongoingCompanyDetails.name = companyData.name;
        ongoingCompanyDetails.newDocumentsCount = companyData.newDocumentsCount;

        ongoingCompanyDetails.ongoings = ongoingDashboardItems;

        return observableOf(ongoingCompanyDetails);
      })
    );
  }
}
