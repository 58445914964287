/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';
import { TouchID } from '@ionic-native/touch-id/ngx';

import {
  type Observable,
  from as observableFrom,
  of as observableOf,
} from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { BiometricIDType } from '@core/biometric-id/biometric-id.enum';
import { type BiometricID } from '@core/biometric-id/biometric-id.model';
import { BiometricIDAvailability } from '@core/biometric-id/biometric-id-availability.enum';
import { BiometricIDDeviceModelService } from '@core/biometric-id/biometric-id-device-model.service';
import { type BiometricIDError } from '@core/biometric-id/biometric-id-error.model';
import { PcsPlatformService } from '@core/pcs-platform.service';

@Injectable()
export class BiometricIDService {
  constructor(
    private touchID: TouchID,
    private biometricIDDeviceModelService: BiometricIDDeviceModelService,
    private pcsPlatformService: PcsPlatformService
  ) {}

  public get(): Observable<BiometricID> {
    return observableFrom(this.pcsPlatformService.ready()).pipe(
      mergeMap(() =>
        observableFrom(this.touchID.isAvailable()).pipe(
          map((type: string) => {
            switch (type) {
              case 'touch':
                return {
                  type: BiometricIDType.TouchID,
                  availability: BiometricIDAvailability.Available,
                };
              case 'face':
                return {
                  type: BiometricIDType.FaceID,
                  availability: BiometricIDAvailability.Available,
                };
              default:
                return null;
            }
          }),
          catchError((error: BiometricIDError) => {
            if (error.code === -6) {
              return observableOf({
                type: null,
                availability: BiometricIDAvailability.NotAvailable,
              });
            }
            if (error.code === -7) {
              return this.biometricIDDeviceModelService.determineIDType().pipe(
                map((type) => ({
                  type,
                  availability: BiometricIDAvailability.NotEnrolled,
                }))
              );
            }
            if (error.code === -8) {
              return this.biometricIDDeviceModelService.determineIDType().pipe(
                map((type) => ({
                  type,
                  availability: BiometricIDAvailability.LockedOut,
                }))
              );
            }
            return observableOf({ type: null, availability: null });
          })
        )
      )
    );
  }

  public isAvailable(): Observable<boolean> {
    return this.get().pipe(
      map(
        (biometricID) =>
          biometricID &&
          biometricID.availability === BiometricIDAvailability.Available
      )
    );
  }
}
