/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable, NgZone } from '@angular/core';
import { type IonContent, LoadingController } from '@ionic/angular';

import { Subject } from 'rxjs';

import { OngoingDocumentType } from '../../workspace/ongoing/documents/shared/ongoing-document-type';
import {
  type ReorderStartEvent,
  type ReorderTouchEndEvent,
} from './documents-reorder-events';

@Injectable({
  providedIn: 'root',
})
export class DocumentsReorderService {
  public reorderStartSub: Subject<ReorderStartEvent> =
    new Subject<ReorderStartEvent>();
  public touchEndSub: Subject<ReorderTouchEndEvent> =
    new Subject<ReorderTouchEndEvent>();
  public scrollTopSub: Subject<any> = new Subject();
  public scrollBottomSub: Subject<any> = new Subject();
  public hidePlaceholderSub: Subject<any> = new Subject();
  public placeholderMoveSub: Subject<any> = new Subject();
  public lastMove: TouchEvent;
  public isDocumentSlidingInProgress: boolean = false;
  public scrollShift: number = 0;

  private page: IonContent;
  private documentType: string;
  private documentId: number;
  private documentName: string;
  private reorderEventTarget: EventTarget;
  private isDragScrollingActive: boolean = false;
  private scrollContainer: HTMLElement;
  private moveOverElement: HTMLElement;

  constructor(private ngZone: NgZone, private loadingCtrl: LoadingController) {}

  public setScrollContainer(elementRef: HTMLElement) {
    this.scrollContainer = elementRef;
    if (this.scrollContainer) {
      this.scrollContainer.addEventListener('scroll', this.onContainerScroll);
    }
  }

  public startReorder(
    page: IonContent,
    event: PointerEvent,
    element: HTMLElement,
    documentType: string,
    documentId: number,
    documentName: string
  ): void {
    this.page = page;
    this.documentType = documentType;
    this.documentId = documentId;
    this.documentName = documentName;
    this.reorderEventTarget = event.target;

    this.ngZone.runOutsideAngular(() => {
      this.reorderEventTarget.addEventListener('touchmove', this.onTouchMove);
      this.reorderEventTarget.addEventListener('touchend', this.onTouchEnd);
      this.reorderEventTarget.addEventListener('touchcancel', this.onTouchEnd);

      document.addEventListener('touchend', this.onTouchEnd);
      document.addEventListener('touchcancel', this.onTouchEnd);
    });

    // fromEvent(this.reorderEventTarget, 'touchmove',)

    this.reorderStartSub.next({ pageY: event.pageY, element });
  }

  public scrollToBottom() {
    if (!this.isDragScrollingActive) {
      this.isDragScrollingActive = true;

      requestAnimationFrame(() => this.scroll());
    }
  }
  public scrollToTop() {
    if (!this.isDragScrollingActive) {
      this.isDragScrollingActive = true;

      requestAnimationFrame(() => this.scroll(false));
    }
  }

  public stopScroll() {
    this.isDragScrollingActive = false;
  }

  private scroll: (scrollDown?: boolean) => void = (
    scrollDown: boolean = true
  ): void => {
    if (!this.isDragScrollingActive) {
      return;
    }

    requestAnimationFrame(() => {
      let scrollOffset;
      const offetPerScrollIteration = 80; // calculated during empirical research
      const scrollIterationDuration = 300; // calculated during empirical research
      if (scrollDown) {
        scrollOffset = this.scrollShift + offetPerScrollIteration;
      } else {
        scrollOffset = this.scrollShift - offetPerScrollIteration;
      }

      this.page
        .scrollToPoint(0, scrollOffset, scrollIterationDuration)
        .then(() => this.scroll(scrollDown));
    });
  };

  private onTouchEnd: (event: Event) => void = ($event: Event): void => {
    $event.preventDefault();

    this.stopScroll();
    this.resetLastMoveOverElement();

    this.reorderEventTarget.removeEventListener('touchend', this.onTouchEnd);
    this.reorderEventTarget.removeEventListener('touchmove', this.onTouchMove);
    this.reorderEventTarget.removeEventListener('touchcancel', this.onTouchEnd);
    document.removeEventListener('touchcancel', this.onTouchEnd);
    document.removeEventListener('touchend', this.onTouchEnd);
    this.isDocumentSlidingInProgress = false;
    this.hidePlaceholderSub.next({});

    if (!this.lastMove) {
      return;
    }

    this.showLoader().then((loadingIndicator) => {
      this.touchEndSub.next({
        ordinalPosition: this.getOrdinalPosition(),
        documentId: this.documentId,
        isFile: this.documentType === OngoingDocumentType.File,
        loadingIndicator,
      });
      this.lastMove = null;
    });
  };

  private onTouchMove: (event: TouchEvent) => void = (
    event: TouchEvent
  ): void => {
    this.lastMove = event;
    const hoverOver = document.elementFromPoint(
      0,
      this.lastMove.touches[0].pageY
    ) as HTMLElement;

    if (hoverOver && hoverOver.dataset.ordinalPosition) {
      this.setMoveOverClass(hoverOver, this.lastMove.touches[0].pageY);

      if (this.moveOverElement && this.moveOverElement !== hoverOver) {
        this.resetLastMoveOverElement();
      }

      this.moveOverElement = hoverOver;
    }
    this.placeholderMoveSub.next(event);
  };

  private resetLastMoveOverElement(): void {
    if (!this.moveOverElement) {
      return;
    }
    this.removeMoveClass(this.moveOverElement);
  }

  private setMoveOverClass(element: HTMLElement, pageY: number): void {
    const boundingRect = element.getBoundingClientRect();
    const elementCenterY = boundingRect.top + boundingRect.height / 2;

    element.classList.add('drag-over-reorder');

    if (pageY < elementCenterY) {
      element.classList.remove('drag-over-reorder-bottom');
      element.classList.add('drag-over-reorder-top');
    } else {
      element.classList.remove('drag-over-reorder-top');
      element.classList.add('drag-over-reorder-bottom');
    }
  }

  private removeMoveClass(element: HTMLElement): void {
    element.classList.remove('drag-over-reorder');
    element.classList.remove('drag-over-reorder-top');
    element.classList.remove('drag-over-reorder-bottom');
  }

  private onContainerScroll: (scroll: Event) => void = (
    scroll: Event
  ): void => {
    const targetElement: HTMLElement = scroll.target as HTMLElement;
    this.scrollShift = targetElement.scrollTop;
  };

  private getOrdinalPosition(): number {
    let ordinalPosition = Number(this.moveOverElement.dataset.ordinalPosition);
    const isAbove = this.moveOverElement.classList.contains(
      'drag-over-reorder-top'
    );

    if (isAbove) {
      ordinalPosition -= 1;
    }

    return ordinalPosition || 1;
  }

  private async showLoader(): Promise<any> {
    const loading = await this.loadingCtrl.create({
      spinner: 'crescent',
      cssClass: 'pcs-loading-spinner',
      message: `
        <div class="pcs-loading-spinner__content">
        <h3 class="pcs-loading-spinner__title">Reordering...</h3>
        <p class="pcs-loading-spinner__text">${this.documentName}</p>
        </div>`,
    });

    loading.present();

    return loading;
  }
}
