/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';

import { type Observable, type Subscription, of as observableFrom } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { OfflineActivityReleaseService } from '@core/offline-core/activity/offline-activity-release.service';
import { OfflineAnnotationsReleaseService } from '@core/offline-core/annotations/offline-annotations-release.service';
import { InternetConnectionService } from '@core/offline-core/internet-connection/internet-connection.service';
import { PcsOfflineMigrationService } from '@core/offline-core/migration/pcs-offline-migration.service';
import { UserFeaturesDataService } from '@core/user/user-features-data.service';
import { GlobalStorageDataService } from '@core/user-storage/global-storage.service';
import { OngoingDeferredConfiHandlerService } from '@ongoing/confidentiality-notice/offline/ongoing-deferred-confi-handler';
import { SpinnerAppStartingService } from '@shared/spinners/spinner-app-starting/spinner-app-starting.service';
import { TransactionDeferredConfiHandler } from '@tvdr/confidentiality-notice/offline/transaction-deferred-confi-handler';
import { PdftronLicenseRetrieverService } from '@workspace-shared/pdftron-previewer/services/pdftron-license-retriever.service';

import { AuthNavigationService } from '../auth/auth-navigation.service';

@Injectable()
export class SetupService {
  private internetUpSubscription: Subscription;
  private internetDownSubscription: Subscription;

  // prettier-ignore
  constructor( //NOSONAR
    private internetConnectionService: InternetConnectionService,
    private authNavigationService: AuthNavigationService,
    private ongoingDeferredConfiHandlerService: OngoingDeferredConfiHandlerService,
    private transactionDeferredConfiHandler: TransactionDeferredConfiHandler,
    private pcsOfflineMigrationService: PcsOfflineMigrationService,
    private offlineActivityReleaseService: OfflineActivityReleaseService,
    private userFeaturesDataService: UserFeaturesDataService,
    private spinnerAppStartingService: SpinnerAppStartingService,
    private offlineAnnotationsReleaseService: OfflineAnnotationsReleaseService,
    private globalStorageDataService: GlobalStorageDataService,
    private pdftronLicenseRetrieverService: PdftronLicenseRetrieverService,
  ) {}

  public setupAppInOnlineMode(): Observable<void> {
    this.userFeaturesDataService.getUserFeatures().subscribe();
    this.globalStorageDataService.fetch().subscribe();

    return this.setupApp();
  }

  public setupAppInOfflineMode(): Observable<void> {
    return this.setupApp();
  }

  public setupInternetChangeObserver(): void {
    if (this.internetDownSubscription) {
      this.internetDownSubscription.unsubscribe();
    }

    if (this.internetUpSubscription) {
      this.internetUpSubscription.unsubscribe();
    }

    this.internetDownSubscription = this.internetConnectionService
      .startObserveInternetDown()
      .pipe(mergeMap(() => this.goInOfflineInRuntime()))
      .subscribe();

    this.internetUpSubscription = this.internetConnectionService
      .startObserveInternetUp()
      .pipe(mergeMap(() => this.goInOnlineInRuntime()))
      .subscribe();
  }

  private goInOfflineInRuntime(): Observable<void> {
    return observableFrom(this.authNavigationService.navigateToHome()).pipe(
      map(() => null)
    );
  }

  private goInOnlineInRuntime(): Observable<void> {
    return this.userFeaturesDataService
      .getUserFeatures()
      .pipe(
        mergeMap(() =>
          observableFrom(this.authNavigationService.navigateToHome()).pipe(
            map(() => null)
          )
        )
      );
  }

  private setupApp(): Observable<void> {
    this.spinnerAppStartingService.finishLoading();
    this.setupInternetChangeObserver();
    this.ongoingDeferredConfiHandlerService.setup();
    this.transactionDeferredConfiHandler.setup();
    this.offlineActivityReleaseService.setup();
    this.offlineAnnotationsReleaseService.setup();
    this.pdftronLicenseRetrieverService.setup();

    return this.pcsOfflineMigrationService.run();
  }
}
