/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { type OnInit, Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

import { type ParticipantGroup } from '../../participant-group.model';

@Component({
  selector: 'pcs-participant-groups',
  templateUrl: './participant-groups.component.html',
  styleUrls: ['./participant-groups.component.scss'],
})
export class ParticipantGroupsComponent implements OnInit {
  public participantGroups: ParticipantGroup[] = [];
  public workspaceGroups: ParticipantGroup[] = [];
  public newGroupsList: ParticipantGroup[] = [];

  constructor(
    private navParams: NavParams,
    private viewCtrl: ModalController
  ) {}

  public ngOnInit(): void {
    this.participantGroups = this.navParams.get('participantGroups');
    this.initGroups();
  }

  public onClose(): void {
    this.viewCtrl.dismiss();
  }

  public onApply(): void {
    this.viewCtrl.dismiss(this.newGroupsList);
  }

  public onGroupSelected(select, group): void {
    if (select) {
      this.newGroupsList.push(group);
    } else {
      this.newGroupsList = this.newGroupsList.filter(
        (item) => item.id !== group.id
      );
    }
  }

  private initGroups(): void {
    const paramsWorkspaceGroups: ParticipantGroup[] =
      this.navParams.get('workspaceGroups');

    this.workspaceGroups = paramsWorkspaceGroups.map(
      (group: ParticipantGroup) => {
        const isGroupSelected = this.participantGroups.some(
          ({ id }) => id === group.id
        );

        if (isGroupSelected) {
          this.newGroupsList.push(group);
        }

        return {
          id: group.id,
          name: group.name,
          selected: isGroupSelected,
        };
      }
    );
  }
}
