/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';
import { type RouterEvent, NavigationStart, Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { Store } from '@ngrx/store';

import { type Observable, Subject } from 'rxjs';
import { filter, withLatestFrom } from 'rxjs/operators';

import { StatusNotificationToastsService } from '@shared/status-notification-toasts/status-notification-toasts.service';

import { closeMenu, openMenu } from './store/account-menu.actions';
import {
  accountMenuOpenedSelector,
  sideMenuDisabledSelector,
} from './store/account-menu.reducers';

@Injectable()
export class AccountMenuService {
  private sideMenuCloseSubject$: Subject<never> = new Subject<never>();

  constructor(
    private store: Store<any>,
    private menuController: MenuController,
    private router: Router,
    private statusNotificationToastsService: StatusNotificationToastsService
  ) {
    this.listenToRoutesChange();
  }

  public get sideMenuDisabled$(): Observable<boolean> {
    return this.store.select(sideMenuDisabledSelector);
  }

  public get sideMenuClosed$(): Observable<void> {
    return this.sideMenuCloseSubject$.asObservable();
  }

  public openAccountMenu() {
    this.statusNotificationToastsService.closeLastActiveToast();

    this.store.dispatch(openMenu());
    this.menuController.open('side').then(() => {
      this.router.navigate(['dashboard', { outlets: { menu: 'menu' } }]);
    });
  }

  public closeAccountMenu() {
    this.store.dispatch(closeMenu());
  }

  private listenToRoutesChange(): void {
    this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationStart),
        withLatestFrom(this.store.select(accountMenuOpenedSelector)),
        filter(this.isNavigatedOutFromSideMenuRoutes)
      )
      .subscribe(this.sideMenuCloseSubject$);
  }

  private isNavigatedOutFromSideMenuRoutes([event, isMenuOpened]: [
    NavigationStart,
    boolean
  ]): boolean {
    const isMenuRoute: boolean = event.url.includes('menu:');

    return isMenuOpened && !isMenuRoute;
  }
}
