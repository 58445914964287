/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import {
  type SecureStorageObject,
  SecureStorage,
} from '@ionic-native/secure-storage';
import { StorageBackend } from '@openid/appauth';

import { KEYSTORE } from '@core/openId/src/constants/keystore.const';

import { CordovaDocument } from './cordova-document';

// REQUIRES CORDOVA PLUGINS
// cordova-plugin-secure-storage
export class CordovaSecureStorage extends StorageBackend {
  private localData: any = {};
  private KEYSTORE: string = KEYSTORE;

  public async SecureStorageExists(): Promise<boolean> {
    await CordovaDocument.ready();
    return SecureStorage.create(this.KEYSTORE).then(
      () => true,
      () => false
    );
  }

  public async hasRecord(store: SecureStorageObject, key: string) {
    const keys: string[] = await store.keys();

    return keys.includes(key);
  }

  public async getItem(name: string): Promise<string | null> {
    await CordovaDocument.ready();
    return SecureStorage.create(this.KEYSTORE)
      .then((store) => store.get(name).catch(() => null))
      .catch(() => this.getTemp(name));
  }

  public async removeItem(name: string): Promise<void> {
    await CordovaDocument.ready();
    return SecureStorage.create(this.KEYSTORE)
      .then((store) => {
        store.remove(name);
      })
      .catch(() => {
        this.removeTemp(name);
      });
  }

  public async setItem(name: string, value: string): Promise<void> {
    await CordovaDocument.ready();
    return SecureStorage.create(this.KEYSTORE)
      .then((store) => {
        store.set(name, value);
      })
      .catch(() => {
        this.setTemp(name, value);
      });
  }

  public async clear(): Promise<void> {
    await CordovaDocument.ready();
    return SecureStorage.create(this.KEYSTORE)
      .then((store) => {
        store.clear();
      })
      .catch(() => {
        this.clearTemp();
      });
  }

  private getTemp(key: string): string | null {
    if (this.localData[key]) {
      return this.localData[key];
    }
    return null;
  }

  private setTemp(key: string, data: string): void {
    this.localData[key] = data;
  }

  private removeTemp(key: string): void {
    if (this.localData[key]) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete this.localData[key];
    }
  }

  private clearTemp(): void {
    this.localData = {};
  }
}
