/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';

import { type Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { SessionStoreService } from '@core/auth/session/session-store.service';
import { ConfidentialityNoticeDataService } from '@core/confidentiality-notice/confidentiality-notice-data.service';
import { UserActions } from '@core/confidentiality-notice/enums/confidentiality-notice.user-actions.enum';
import { DeferredConfiActionsStoreService } from '@core/confidentiality-notice-offline/deferred-confi-actions-store.service';
import { ExecutedConfiActionsStoreService } from '@core/confidentiality-notice-offline/executed-confi-actions-store.service';
import { ConfidentialityNoticeDeferredAction } from '@workspace-shared/confidentiality-notice/confidantiality-notice-deferred-action';
import { type ConfidentialityNoticeInfoModel } from '@workspace-shared/confidentiality-notice/confidentiality-notice-info.model';

import { type ConfidentialityNoticeAction } from '../confidentiality-notice/models/confidentiality-notice-action.model';

@Injectable()
export class DeferredConfiActionReleaseService {
  constructor(
    private sessionStoreService: SessionStoreService,
    private executedConfiActionsStoreService: ExecutedConfiActionsStoreService,
    private confidentialityNoticeDataService: ConfidentialityNoticeDataService,
    private deferredConfiActionsStoreService: DeferredConfiActionsStoreService
  ) {}

  public releaseDeferredConfiAction(
    confiInfo: ConfidentialityNoticeInfoModel,
    deferredAction: ConfidentialityNoticeAction
  ): Observable<void> {
    const memorizedDeferredAction = deferredAction;
    const deferredActionDto = this.formatDeferredAction(deferredAction);
    return this.confidentialityNoticeDataService
      .handleDeferredNotice(deferredActionDto, confiInfo.noticeUrl)
      .pipe(
        mergeMap(() =>
          this.executedConfiActionsStoreService.saveAction(
            memorizedDeferredAction,
            confiInfo
          )
        ),
        mergeMap(() =>
          this.deferredConfiActionsStoreService.removeDeferredAction(
            memorizedDeferredAction,
            confiInfo
          )
        ),
        catchError((error) => {
          const { status } = error;
          const isNotFoundError: boolean = status === 404;
          if (isNotFoundError) {
            return this.deferredConfiActionsStoreService.removeDeferredAction(
              memorizedDeferredAction,
              confiInfo
            );
          }
          return observableThrowError(error);
        })
      );
  }

  private formatDeferredAction(
    deferredAction: ConfidentialityNoticeAction
  ): ConfidentialityNoticeDeferredAction {
    const actualSessionId: string = this.sessionStoreService.getSessionId();

    return new ConfidentialityNoticeDeferredAction(
      UserActions[deferredAction.action],
      deferredAction.agreementUploadedOn,
      deferredAction.actualTimestamp,
      actualSessionId
    );
  }
}
