/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import {
  type OnDestroy,
  type OnInit,
  Component,
  Renderer2,
} from '@angular/core';
import {
  type FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Store } from '@ngrx/store';

import { countriesSelector } from '../../../countries/countries.reducer';
import { Country } from '../../../models/country.model';
import { ParticipantCompany } from '../../../models/participant-create-request.model';
import { type CompanyMatchesResponse } from '../../../models/participant-match-response.model';
import { UserEntityDataService } from './user-entity-data.service';

@Component({
  selector: 'pcs-user-entity',
  templateUrl: './user-entity.component.html',
  styleUrls: ['./user-entity.component.scss'],
})
export class UserEntityComponent implements OnInit, OnDestroy {
  public companyMatches: CompanyMatchesResponse;
  public userCompanyId: number;
  public isSearchAvailable: boolean;
  public companyName: string = '';
  public companyMatchesFound: boolean = false;
  public companySearchUrl: string;
  public isSearchInProgress: boolean = false;
  public createEntityMode: boolean = false;
  public error: string = '';
  public searchResults: ParticipantCompany[] = [];
  public country: Country = new Country();
  public countries: Country[] = [];
  public isGetCountriesInProgress: boolean = false;

  public formInput: FormGroup;
  public newEntity: ParticipantCompany = new ParticipantCompany();

  constructor(
    private navParams: NavParams,
    private viewCtrl: ModalController,
    private formBuilder: FormBuilder,
    private userEntityDataService: UserEntityDataService,
    private keyboard: Keyboard,
    private store: Store<Country[]>,
    private renderer: Renderer2
  ) {}

  public ngOnInit(): void {
    this.userCompanyId = this.navParams.get('userCompanyId');
    this.companyMatches = this.navParams.get('companyMatches');
    this.companySearchUrl = this.navParams.get('companySearchUrl');
    const customCompany: ParticipantCompany =
      this.navParams.get('customCompany');

    if (customCompany && customCompany.isNewCompany) {
      this.companyMatchesFound = false;
      this.createEntityMode = true;
      this.newEntity = customCompany;
      this.companyName = this.newEntity.companyName;
      this.getCountries();
      this.country = this.countries.find(
        (country: Country) => this.newEntity.country === country.name
      );
      this.setSelectedField('countryItem');
      if (this.newEntity.state) {
        this.setSelectedField('stateItem');
      }
    }

    this.isSearchAvailable = !this.companyMatches.all.length;

    this.formInit();

    this.keyboard.hideFormAccessoryBar(false);
  }

  public ngOnDestroy(): void {
    this.keyboard.hideFormAccessoryBar(true);
  }

  public onClose(): void {
    this.viewCtrl.dismiss();
  }

  public onApply(): void {
    if (this.createEntityMode) {
      this.newEntity.isNewCompany = true;
      this.viewCtrl.dismiss(this.newEntity);
    } else {
      const newUserCompany = this.companyMatches.recent
        .concat(this.companyMatches.all)
        .concat(this.searchResults)
        .find(
          (company: ParticipantCompany) =>
            company.companyId === this.userCompanyId
        );

      newUserCompany.isNewCompany = false;
      this.viewCtrl.dismiss(newUserCompany);
    }
  }

  public onSearch(name: string): void {
    this.isSearchInProgress = true;

    const skipLoader = true;

    this.userEntityDataService
      .searchCompany(name, this.companySearchUrl, skipLoader)
      .subscribe(
        (companyMatches: ParticipantCompany[]) => {
          if (!companyMatches.length) {
            this.error = '0 results found.';
            this.isSearchInProgress = false;
          }

          if (companyMatches.length) {
            this.searchResults = companyMatches;
            this.isSearchInProgress = false;
          }

          this.companyMatchesFound = true;
        },
        (error) => {
          this.error = error.message;
          this.isSearchInProgress = false;
        }
      );
  }

  public onBackToSearch(): void {
    this.error = '';
    this.createEntityMode = false;
    this.newEntity = new ParticipantCompany();
    this.companyMatchesFound = false;
    this.searchResults = [];
    this.formInit();
  }

  public onCreateNewEntity(): void {
    this.error = '';
    this.companyMatchesFound = false;
    this.searchResults = [];
    this.createEntityMode = true;
    this.newEntity.companyName = this.companyName;
    this.getCountries();
  }

  public onCompanyNameChange() {
    this.newEntity.companyName = this.companyName;
  }

  public onStreetChanged(newStreet) {
    this.newEntity.street = newStreet;
  }

  public onCityChanged(newCity) {
    this.newEntity.city = newCity;
  }

  public onZipChanged(newZip) {
    this.newEntity.zip = newZip;
  }

  public onCountryChanged(newCountry) {
    this.country = this.countries.find(
      (country: Country) => newCountry === country.name
    );
    this.newEntity.country = newCountry;
    this.newEntity.state = undefined;
    this.unsetSelectedField('stateItem');
  }

  public onUserEntitySelected(newUserCompanyId: number) {
    this.userCompanyId = newUserCompanyId;
  }

  public isSaveDisabled(): boolean {
    if (this.createEntityMode && this.formInput.invalid) {
      return true;
    }

    if (!this.createEntityMode) {
      return !this.companyMatches.recent
        .concat(this.companyMatches.all)
        .concat(this.searchResults)
        .find(
          (company: ParticipantCompany) =>
            company.companyId === this.userCompanyId
        );
    }

    return !this.newEntity.country || !this.newEntity.companyName;
  }

  private formInit(): void {
    this.formInput = this.formBuilder.group({
      inputName: new FormControl(
        {
          value: this.companyName,
          disabled: this.companyMatchesFound,
        },
        Validators.required
      ),
    });
  }

  private getCountries(): void {
    this.isGetCountriesInProgress = true;

    this.store
      .select(countriesSelector)
      .subscribe((storedCountries: Country[]) => {
        if (storedCountries && storedCountries.length) {
          this.onGetCountries(storedCountries);
          this.isGetCountriesInProgress = false;
        } else {
          this.userEntityDataService.getCountries().subscribe(
            (countries: Country[]) => {
              this.onGetCountries(countries);
              this.isGetCountriesInProgress = false;
            },
            (error) => {
              this.error = error.message;
              this.isGetCountriesInProgress = false;
            }
          );
        }
      });
  }

  private onGetCountries(countries: Country[]): void {
    this.countries = this.sortByName(countries).map((country: Country) => ({
      ...country,
      provinces: this.sortByName(country.provinces),
    }));
  }

  private setSelectedField(id: string) {
    setTimeout(() => {
      this.renderer.addClass(
        document.getElementById(id),
        'item-input-has-value'
      );
      this.renderer.addClass(document.getElementById(id), 'input-has-value');
    }, 0);
  }

  private unsetSelectedField(id: string) {
    this.renderer.removeClass(
      document.getElementById(id),
      'item-input-has-value'
    );
    this.renderer.removeClass(document.getElementById(id), 'input-has-value');
  }

  private sortByName<T extends { name: string }>(entitiesArray: T[]): T[] {
    if (entitiesArray) {
      return [...entitiesArray].sort((entityA, entityB) =>
        entityA.name.localeCompare(entityB.name)
      );
    }

    return entitiesArray;
  }
}
