/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { type ErrorHandler, Injectable, Injector } from '@angular/core';

import { AuthService } from '@core/auth/auth.service';
import { InternetConnectionService } from '@core/offline-core/internet-connection/internet-connection.service';
import { PcsAppErrorLevel } from '@core/pcs-error-handler/pcs-app-error-level.enum';
import { type PcsErrorModel } from '@core/pcs-error-handler/pcs-error.model';
import { SkipLoaderOptionsService } from '@core/pcs-http/skip-loader-options.service';
import { environment } from '@environment';
import { type PcsException } from '@shared/pcs-exceptions/pcs.exception';

@Injectable()
export class PCSErrorHandler implements ErrorHandler {
  private clientErrors: PcsErrorModel[] = [];
  private skipErrorsWords: string[] = ['ConfidentialityAgreement'];
  private isEnabled: boolean = environment.ENABLE_LOGGING === 'true';

  constructor(private injector: Injector) {
    setInterval(() => this.releaseErrors(), 60000);
  }

  public handleError(err: any): void {
    console.error(err);

    if (!this.isEnabled) {
      return;
    }
    const message = err.message;
    const isNeedToSkip = this.skipErrorsWords.some((skipErrorsWord: string) =>
      message.includes(skipErrorsWord)
    );
    if (!isNeedToSkip) {
      this.handlePCSInfo(message);
    }

    if (!this.isAuthorized()) {
      this.releaseErrors();
    }
  }

  public handlePCSInfo(message: string) {
    if (!this.isEnabled) {
      return;
    }

    const page = location.hash;

    this.clientErrors.push({
      message,
      type: PcsAppErrorLevel.Information,
      page,
      appVersion: environment.appVersion,
    });
  }

  public handlePCSError<T extends PcsException>(exception: T): void {
    if (!this.isEnabled) {
      return;
    }

    const page = location.hash;

    this.clientErrors.push({
      message: exception.message,
      type: PcsAppErrorLevel.Error,
      page,
      appVersion: environment.appVersion,
    });
  }

  private releaseErrors(): void {
    if (!this.clientErrors.length) {
      return;
    }

    const http = this.injector.get(HttpClient);
    const internetConnectionService = this.injector.get(
      InternetConnectionService
    );

    if (internetConnectionService.isOffline()) {
      return;
    }

    const logs = [];

    this.clientErrors.forEach((error: PcsErrorModel) => {
      logs.push({
        level: error.type,
        message: `Page: ${error.page}; Message: ${error.message}; AppVersion: ${error.appVersion}`,
      });
    });

    const headers = new HttpHeaders({
      'X-Logs-Secret-Key-Type': environment.LOGS_SECRET_KEY,
    });

    const options = {
      ...SkipLoaderOptionsService.getSkipLoaderOptions(),
      headers,
    };

    http.post('/MobileApp/logs', { logs }, options).subscribe(() => {
      this.clientErrors = [];
    });
  }

  private isAuthorized(): boolean {
    const authService = this.injector.get(AuthService);

    return Boolean(authService.getCurrentUser().profile);
  }
}
