<!--*
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 *-->
<form
  class="passcode-keyboard"
  [ngClass]="{
    'passcode-keyboard_error': step.status === stepPossibleStatuses.Error,
    'passcode-keyboard_success': step.status === stepPossibleStatuses.Success
  }"
>
  <img
    class="passcode-keyboard__logo"
    width="60"
    height="50"
    src="assets/icon/passcode-prism-logo.svg"
    alt="prism log"
  />

  <div class="passcode-keyboard__message">{{ step.message }}</div>

  <ul
    class="passcode-display passcode-display_{{
      passcodeFilledDigitsCount
    }}_filled"
  >
    <li class="passcode-display__digit"></li>
    <li class="passcode-display__digit"></li>
    <li class="passcode-display__digit"></li>
    <li class="passcode-display__digit"></li>
  </ul>

  <div class="passcode-keyboard__controls">
    <button
      *ngFor="let number of passcodeKeyboardNumbers"
      (click)="typeDigit(number.digit)"
      class="passcode-keyboard-number-button"
    >
      <div class="passcode-keyboard-number-button__digit">
        {{ number.digit }}
      </div>
      <div class="passcode-keyboard-number-button__letters">
        {{ number.letters }}
      </div>
    </button>

    <button
      (click)="onReject()"
      class="passcode-keyboard__service-button passcode-keyboard__service-button_reject"
    >
      {{ getRejectButtonText() }}
    </button>
  </div>
</form>
