/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MaintenanceHelperService } from '@pcs/core/features/maintenance';

import { AuthService } from '@core/auth/auth.service';
import { AuthHttpInterceptor } from '@core/auth/auth-http-interceptor.service';
import { SessionStoreService } from '@core/auth/session/session-store.service';
import { UnauthorizedHandleService } from '@core/auth/unauthorized/unauthorized-handle.service';
import { IpreoUrlHttpInterceptor } from '@core/ipreo-url-interceptor/ipreo-url-http-interceptor.service';
import { SpinnerHttpInterceptor } from '@core/spinner-interceptor/spinner-http-interceptor.service';
import { OngoingConfidentialityHttpInterceptorService } from '@ongoing/confidentiality-notice/confidentilaity-notice-interceptor/ongoing-confidentiality-http-interceptor.service';
import { OngoingConfidentialityNoticeHandlerService } from '@ongoing/confidentiality-notice/confidentilaity-notice-interceptor/ongoing-confidentilaity-notice-handler.service';
import { TransactionConfidentialityHttpInterceptorService } from '@tvdr/confidentiality-notice/confidentilaity-notice-handler-interceptor/transaction-confidentiality-http-interceptor.service';
import { TransactionConfidentialityNoticeHandlerService } from '@tvdr/confidentiality-notice/confidentilaity-notice-handler-interceptor/transaction-confidentiality-notice-handler.service';

import { MaintenanceInterceptor } from './maintenance/maintenance.interceptor';
import { NotFoundPageHttpInterceptor } from './not-found-page/not-found-page-http-interceptor';
import { ServerErrorHttpInterceptor } from './server-error/server-error-http-interceptor.service';

export const httpInterceptors = [
  {
    deps: [MaintenanceHelperService, Router],
    provide: HTTP_INTERCEPTORS,
    useClass: MaintenanceInterceptor,
    multi: true,
  },
  {
    deps: [],
    provide: HTTP_INTERCEPTORS,
    useClass: IpreoUrlHttpInterceptor,
    multi: true,
  },
  {
    deps: [Store],
    provide: HTTP_INTERCEPTORS,
    useClass: SpinnerHttpInterceptor,
    multi: true,
  },
  {
    deps: [AuthService, UnauthorizedHandleService, SessionStoreService, Router],
    provide: HTTP_INTERCEPTORS,
    useClass: AuthHttpInterceptor,
    multi: true,
  },
  {
    deps: [Router],
    provide: HTTP_INTERCEPTORS,
    useClass: ServerErrorHttpInterceptor,
    multi: true,
  },
  {
    deps: [OngoingConfidentialityNoticeHandlerService],
    provide: HTTP_INTERCEPTORS,
    useClass: OngoingConfidentialityHttpInterceptorService,
    multi: true,
  },
  {
    deps: [TransactionConfidentialityNoticeHandlerService],
    provide: HTTP_INTERCEPTORS,
    useClass: TransactionConfidentialityHttpInterceptorService,
    multi: true,
  },
  {
    deps: [Router],
    provide: HTTP_INTERCEPTORS,
    useClass: NotFoundPageHttpInterceptor,
    multi: true,
  },
];
