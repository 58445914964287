/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';
import { type Resolve } from '@angular/router';

import { type Observable, EMPTY, of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import { InternetConnectionService } from '@core/offline-core/internet-connection/internet-connection.service';
import { PcsPlatformService } from '@core/pcs-platform.service';

import { SessionSecureStoreService } from '../../cache/session-secure-store.service';
import { SetupService } from '../../setup/setup.service';
import { type Session } from '../../shared/session.model';
import { AuthService } from '../auth.service';
import { SessionDataService } from './session-data.service';
import { SessionEventEmitterService } from './session-event-emitter.service';
import { SessionStoreService } from './session-store.service';

@Injectable()
export class SessionResolver implements Resolve<Session> {
  // prettier-ignore
  constructor( //NOSONAR
    private sessionDataService: SessionDataService,
    private setupService: SetupService,
    private internetConnectionService: InternetConnectionService,
    private sessionSecureStoreService: SessionSecureStoreService,
    private sessionStoreService: SessionStoreService,
    private sessionEventEmitterService: SessionEventEmitterService,
    private authService: AuthService,
    private platformService: PcsPlatformService
  ) {}

  public resolve(): Observable<Session> {
    let session: Session;
    if (this.internetConnectionService.isOffline()) {
      return this.sessionSecureStoreService.getSession().pipe(
        mergeMap((receivedSession: Session) => {
          session = this.sessionStoreService.setSession(receivedSession);

          return observableOf(session);
        }),
        mergeMap(() => this.setupService.setupAppInOfflineMode()),
        map(() => session)
      );
    }

    const sessionObservable: Observable<Session> = this.isOnDevice()
      ? this.sessionDataService.startSession()
      : this.tryToGetStoredSession();

    return sessionObservable.pipe(
      map((receivedSession: Session) =>
        this.sessionStoreService.setSession(receivedSession)
      ),
      mergeMap((receivedSession: Session) => {
        session = receivedSession;
        return this.sessionSecureStoreService.setSession(receivedSession);
      }),
      catchError((error) =>
        this.authService.startSignOutMainWindow().pipe(
          catchError(() => EMPTY),
          mergeMap(() => {
            throw error;
          })
        )
      ),
      mergeMap(() => this.setupService.setupAppInOnlineMode()),
      map(() => session),
      tap((newSession) =>
        this.sessionEventEmitterService.startSession(newSession)
      )
    );
  }

  private tryToGetStoredSession(): Observable<Session> {
    return this.sessionSecureStoreService.getSession().pipe(
      mergeMap((storedSession: Session) => {
        if (storedSession?.id) {
          return observableOf(storedSession);
        }

        return this.sessionDataService.startSession();
      })
    );
  }

  private isOnDevice(): boolean {
    return this.platformService.isOnDevice();
  }
}
