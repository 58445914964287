/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Inject, Injectable, NgZone } from '@angular/core';
import { type TokenResponse } from '@openid/appauth';

import jwt_decode from 'jwt-decode';
import { type User, type UserManager, OidcClientSettings } from 'oidc-client';
import {
  type Observable,
  from as observableFrom,
  of as observableOf,
} from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';

import { AppInitializationHelperService } from '@core/app-initialization/app-initialization-helper.service';
import { AuthActionsService } from '@core/auth/auth-actions.service';
import { OPEN_ID_CONFIG_WEB } from '@core/auth/auth-settings-web-token';
import { SessionStoreService } from '@core/auth/session/session-store.service';
import { type PcsUser } from '@core/auth/strategies/pcs-user.model';
import { SessionSecureStoreService } from '@core/cache/session-secure-store.service';
import { type UserProfile } from '@core/openId/src/OpenId.dictionary';

import { type AuthStrategyInterface } from '../auth-strategy.interface';
import { UserManagerFactoryService } from '../user-manager-factory.service';

@Injectable()
export class AuthWebService implements AuthStrategyInterface {
  private currentUser: User;
  private userManager: UserManager;
  private ihsMarkitDomain: string = 'ihsmarkit.com';
  private samExternalDomain: string = 'samexternal.net';

  constructor(
    private authActionsService: AuthActionsService,
    private userManagerFactoryService: UserManagerFactoryService,
    private zone: NgZone,
    @Inject(OPEN_ID_CONFIG_WEB) public config: OidcClientSettings,
    private sessionStoreService: SessionStoreService,
    private sessionSecureStoreService: SessionSecureStoreService
  ) {}

  public init() {
    this.zone.runOutsideAngular(() => {
      this.userManager = this.userManagerFactoryService.create(this.config);

      this.userManager.events.addUserLoaded((user: User) => {
        if (user) {
          this.currentUser = user;
        }
      });

      this.userManager.events.addUserSignedOut(() => {
        this.startSignInMainWindow();
      });
    });
  }

  public getCurrentUser(): PcsUser {
    return { ...this.currentUser };
  }

  public getUser(): Observable<PcsUser> {
    return observableFrom(this.userManager.getUser()).pipe(
      map((user: User) => user as PcsUser)
    );
  }

  public startSignInMainWindow(): Observable<boolean> {
    const getUser = observableFrom(this.userManager.getUser());
    return getUser.pipe(
      mergeMap((user) => {
        if (user && !user.profile) {
          user.profile = this.decodeIdToken(user.id_token);
        }
        this.currentUser = user;

        if (user) {
          return observableOf(user);
        }

        return observableFrom(
          this.userManager.signinRedirect({
            data: { redirectUrl: window.location.hash },
          })
        );
      }),
      map((value) => !!value),
      tap(() => this.authActionsService.signIn(this.getCurrentUser()))
    );
  }

  public startSignOutMainWindow(): Observable<void> {
    this.sessionSecureStoreService.clearSession().subscribe();
    this.sessionStoreService.clearSession();

    return observableFrom(this.userManager.getUser()).pipe(
      mergeMap((user: User) => {
        const postLogoutRedirect = `post_logout_redirect_uri=${window.location.origin}`;
        const logoutUrl = `${this.getLogoutUri()}&id_token_hint=${
          user.id_token
        }&${postLogoutRedirect}`;
        return observableFrom(this.userManager.removeUser()).pipe(
          tap(() => window.location.assign(logoutUrl))
        );
      })
    );
  }

  public stopSignIn() {
    // There no reason for this method in auth web strategy
  }

  public getTokenFromStorage(): Observable<TokenResponse> {
    // Manual silent token renew is not available in Web flow;
    return observableOf(null);
  }

  private decodeIdToken(idToken: string): UserProfile {
    return jwt_decode<UserProfile>(idToken);
  }

  private getLogoutUri(): string {
    const domain: string = AppInitializationHelperService.isProduction()
      ? this.ihsMarkitDomain
      : this.samExternalDomain;

    return `https://login.${domain}/Logout/Oidc?v=2.0`;
  }
}
