/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import {
  type OnInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Renderer2,
  ViewChild,
} from '@angular/core';

import { DocumentsReorderService } from '../documents-reoder.service';

@Component({
  selector: 'pcs-draggable-placeholder',
  styleUrls: ['./draggable-placeholder.component.scss'],
  templateUrl: './draggable-placeholder.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DraggablePlaceholderComponent implements OnInit {
  @ViewChild('container') public container: ElementRef<HTMLDivElement>;

  constructor(
    private elementRef: ElementRef,
    private documentsReorderService: DocumentsReorderService,
    private renderer: Renderer2
  ) {}

  public ngOnInit(): void {
    this.documentsReorderService.reorderStartSub.subscribe((event) => {
      if (event) {
        const child = this.container.nativeElement.childNodes[0];

        if (child) {
          this.renderer.removeChild(this.container.nativeElement, child);
        }

        this.elementRef.nativeElement.style.display = 'block';
        this.elementRef.nativeElement.style.top = `${event.pageY - 10}px`;
        this.renderer.appendChild(this.container.nativeElement, event.element);
      }
    });

    const bodyHeight = document.body.getBoundingClientRect().height;

    this.documentsReorderService.placeholderMoveSub.subscribe(
      (data: TouchEvent) => {
        const touchYPosition = data.touches[0].pageY + 10;

        this.elementRef.nativeElement.style.top = `${
          data.touches[0].pageY - 10
        }px`;

        if (touchYPosition < 100) {
          this.documentsReorderService.scrollToTop();
        }

        if (touchYPosition > 100 && touchYPosition < bodyHeight - 100) {
          this.documentsReorderService.stopScroll();
        }

        if (
          touchYPosition > bodyHeight - 100 &&
          parseFloat(this.elementRef.nativeElement.style.top) < touchYPosition
        ) {
          this.documentsReorderService.scrollToBottom();
        }
      }
    );

    this.documentsReorderService.hidePlaceholderSub.subscribe(() => {
      this.elementRef.nativeElement.style.display = 'none';

      const child = this.container.nativeElement.childNodes[0];
      if (child) {
        this.renderer.removeChild(this.container.nativeElement, child);
      }
    });
  }

  @HostListener('document:click')
  public clickOutside(): void {
    this.elementRef.nativeElement.style.display = 'none';
  }

  public preventClick($event): void {
    this.elementRef.nativeElement.style.display = 'none';

    $event.preventDefault();
    $event.stopPropagation();
  }
}
