/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';
import { type TokenResponse } from '@openid/appauth';

import { type Observable } from 'rxjs';

import { type PcsUser } from '@core/auth/strategies/pcs-user.model';
import { PcsPlatformService } from '@core/pcs-platform.service';

import { type AuthStrategyInterface } from './strategies/auth-strategy.interface';
import { AuthNativeService } from './strategies/native/auth-native.service';
import { AuthWebService } from './strategies/web/auth-web.service';

@Injectable()
export class AuthService {
  private strategy: AuthStrategyInterface;

  constructor(
    private authNativeService: AuthNativeService,
    private authWebService: AuthWebService,
    private pcsPlatformService: PcsPlatformService
  ) {
    this.setupStrategy();
  }

  public getUser(): Observable<any> {
    return this.strategy.getUser();
  }

  public getCurrentUser(): PcsUser {
    return this.strategy.getCurrentUser();
  }

  public startSignInMainWindow(): Observable<boolean> {
    return this.strategy.startSignInMainWindow();
  }

  public startSignOutMainWindow(): Observable<any> {
    return this.strategy.startSignOutMainWindow();
  }

  public stopSignIn(): void {
    return this.strategy.stopSignIn();
  }

  public getTokenFromStorage(): Observable<TokenResponse> {
    return this.strategy.getTokenFromStorage();
  }

  private setupStrategy() {
    this.strategy = this.pcsPlatformService.isOnDevice()
      ? this.authNativeService
      : this.authWebService;
    this.strategy.init();
  }
}
