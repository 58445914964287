/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

import { type ToastOptionalParams } from '@shared/status-notification-toasts/toast-optional-params.model';

import { type ToastButton } from './status-notification-toasts';

@Injectable()
export class StatusNotificationToastsService {
  public lastDisaplyedToast: HTMLIonToastElement;

  private toasCloseButton: ToastButton = {
    icon: 'close',
    side: 'end',
    role: 'cancel',
    cssClass: 'pcs-status-notification-toast-btn',
  };

  constructor(private toastCtrl: ToastController) {}

  public async showSuccessToast(
    successText: string,
    optionalParams?: ToastOptionalParams
  ): Promise<void> {
    const baseClasses = ['pcs-status-notification-toast_success'];
    const cssClass = this.getCssClass(baseClasses, optionalParams);

    const toast = await this.toastCtrl.create({
      message: `&#9989; ${successText}`,
      duration: 5000,
      position: 'bottom',
      cssClass,
      buttons: [this.toasCloseButton],
    });

    toast.present();
    this.lastDisaplyedToast = toast;
  }

  public async showNotificationToast(notificationText: string): Promise<void> {
    const toast = await this.toastCtrl.create({
      message: `&#9432; ${notificationText}`,
      duration: 5000,
      position: 'bottom',
      cssClass: 'pcs-status-notification-toast--notification',
      buttons: [this.toasCloseButton],
    });
    this.lastDisaplyedToast = toast;

    await toast.present();
  }

  public async showErrorToast(
    errorText: string,
    optionalParams?: ToastOptionalParams
  ): Promise<void> {
    const baseClasses = ['pcs-status-notification-toast_error'];
    const cssClass = this.getCssClass(baseClasses, optionalParams);

    const toast = await this.toastCtrl.create({
      message: `&#10071; ${errorText}`,
      duration: 5000,
      position: 'bottom',
      cssClass,
      buttons: [this.toasCloseButton],
    });
    toast.present();
    this.lastDisaplyedToast = toast;
  }

  public closeLastActiveToast(): void {
    if (this.lastDisaplyedToast) {
      this.lastDisaplyedToast.dismiss();
    }
  }

  private getCssClass(
    baseClasses: string[],
    optionalParams: ToastOptionalParams = {} as ToastOptionalParams
  ): string {
    if (optionalParams.isBottomPadding) {
      baseClasses.push('pcs-status-notification-toast_bottom-padding');
    }

    if (optionalParams.isOverModal) {
      baseClasses.push('pcs-status-notification-toast_over-modal');
    }

    return baseClasses.join(' ');
  }
}
