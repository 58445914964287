/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';

import { type Observable, of as observableOf } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { SessionStoreService } from '@core/auth/session/session-store.service';
import { type OfflinePreviewInfo } from '@core/offline-core/files/offline-preview-info.model';
import { putItemToCollection } from '@core/shared/store-utils';
import { BaseStorageService } from '@core/storage/base-storage.service';
import { StorageStrategyFactoryResolverService } from '@core/storage/storage-strategy-factory-resolver.service';

@Injectable()
export class PreviewOfflineStoreService extends BaseStorageService<
  OfflinePreviewInfo[]
> {
  constructor(
    protected storageStrategyResolverService: StorageStrategyFactoryResolverService,
    private sessionStoreService: SessionStoreService
  ) {
    super(storageStrategyResolverService);
  }

  public getData(documentId: string): Observable<OfflinePreviewInfo> {
    const key = this.getKey();

    return super.get(key).pipe(
      map((previewInfos: OfflinePreviewInfo[]) => {
        if (!previewInfos) {
          return null;
        }

        const previewInfo = previewInfos.find((item) => item.id === documentId);
        return previewInfo || null;
      })
    );
  }

  public isExists(documentId: string): Observable<boolean> {
    const key = this.getKey();

    return super.get(key).pipe(
      map((previewInfos: OfflinePreviewInfo[]) => {
        if (!previewInfos) {
          return false;
        }

        return previewInfos.some(
          (item: OfflinePreviewInfo) => item.id === documentId
        );
      })
    );
  }

  public getDownloadedPresentations(documentId: string): Observable<string[]> {
    return this.getData(documentId).pipe(
      map((item: OfflinePreviewInfo) => {
        if (item && item.annotationsPath) {
          return [item.downloadedFilePaths[0], item.annotationsPath];
        }
        return item ? item.downloadedFilePaths : null;
      })
    );
  }

  public save(previewInfo: OfflinePreviewInfo): Observable<void> {
    const key = this.getKey();

    return this.get(key).pipe(
      mergeMap((savedItems: OfflinePreviewInfo[]) => {
        if (!savedItems) {
          return super.set(key, [previewInfo]);
        }
        const isDataEqual = (
          dataA: OfflinePreviewInfo,
          dataB: OfflinePreviewInfo
        ) => dataA.id === dataB.id;

        const allItemsToSave = putItemToCollection<OfflinePreviewInfo>(
          previewInfo,
          savedItems,
          isDataEqual
        );

        return super.set(key, allItemsToSave);
      })
    );
  }

  public update(documentPreview: OfflinePreviewInfo): Observable<void> {
    const key = this.getKey();

    return this.get(key).pipe(
      mergeMap((savedItems: OfflinePreviewInfo[]) => {
        if (!savedItems) {
          return observableOf(null);
        }

        const updatedItems = savedItems.map((item: OfflinePreviewInfo) => {
          if (item.id === documentPreview.id) {
            item.bookmarks = documentPreview.bookmarks;
            item.agenda = documentPreview.agenda;
          }
          return item;
        });

        return super.set(key, updatedItems);
      })
    );
  }

  public updateAnnotationsLocalLink(
    id: string,
    annotationsLocalLink: string
  ): Observable<void> {
    const key = this.getKey();

    return this.get(key).pipe(
      mergeMap((savedItems: OfflinePreviewInfo[]) => {
        if (!savedItems) {
          return observableOf(null);
        }

        const updatedItems = savedItems.map((item: OfflinePreviewInfo) => {
          if (item.id === id) {
            console.log('update annotations local link');
            console.log(annotationsLocalLink);
            item.annotationsPath = annotationsLocalLink;
          }
          return item;
        });

        return super.set(key, updatedItems);
      })
    );
  }

  public removeAnnotationsLocalLink(id: string): Observable<void> {
    const key = this.getKey();

    return this.get(key).pipe(
      mergeMap((savedItems: OfflinePreviewInfo[]) => {
        if (!savedItems) {
          return observableOf(null);
        }

        const updatedItems = savedItems.map((item: OfflinePreviewInfo) => {
          if (item.id === id) {
            const { annotationsPath, ...itemToSave } = item;

            return itemToSave;
          }
          return item;
        });

        return super.set(key, updatedItems);
      })
    );
  }

  public remove(documentId: string): Observable<void> {
    const key = this.getKey();

    return super.get(key).pipe(
      mergeMap((savedItems: OfflinePreviewInfo[]) => {
        if (!savedItems || savedItems.length === 0) {
          return observableOf(null);
        }

        const itemsToSet = savedItems.filter(
          (item: OfflinePreviewInfo) => item.id !== documentId
        );

        return super.set(key, itemsToSet);
      })
    );
  }

  private getKey(): string {
    const userId: string = this.sessionStoreService.getUserId();

    // this is legacy key for storing file preview info to offline, do not change it
    return `BOX_METADATA::${userId}`;
  }
}
