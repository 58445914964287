/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import {
  type AfterViewInit,
  type OnInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  type FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { IonInput } from '@ionic/angular';
import { safeInputValidator } from '@pcs/core/form/validators';

import { InputValidatorService } from '@workspace-documents/input-validator/input-validator.service';

@Component({
  selector: 'pcs-editable-form-item',
  templateUrl: './editable-form-item.component.html',
  styleUrls: [
    './editable-form-item.component.scss',
    './editable-form-item-error.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableFormItemComponent implements OnInit, AfterViewInit {
  @ViewChild('input') public inputRef: IonInput;

  @Input() public title: string = '';
  @Input() public content: string = '';
  @Input() public isRequired: boolean = false;
  @Input() public isValidationRequired: boolean = false;
  @Input() public isSameNameValidationRequired: boolean = false;
  @Input() public sameNameValidationList: string[] = [];
  @Input() public maxInputLength: number;
  @Input() public isDisabled: boolean = false;
  @Input() public autoFocus: boolean = false;
  @Input() public sameNameError: string = '';
  @Input() public markRequired: boolean = false;
  @Input() public tabindex: number = 0;

  @Output() public onValueChanged: EventEmitter<string> = new EventEmitter();
  @Output() public validityStateChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  public value: string = '';
  public formInput: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private inputValidatorService: InputValidatorService
  ) {}

  public ngOnInit(): void {
    this.value = this.content;

    this.formInit();
  }

  public onUpdateValue(): void {
    this.value = this.value.trim();

    const validationErrors = this.formInput.get('inputName').errors;
    const isValueChanged = this.value !== this.content;

    this.validityStateChange.emit(this.formInput.valid);

    if (!validationErrors && isValueChanged) {
      this.content = this.value;
      this.onValueChanged.emit(this.value);
    }
  }

  public ngAfterViewInit(): void {
    if (this.autoFocus) {
      setTimeout(() => {
        this.inputRef.setFocus();
      }, 1000);
    }
  }

  private formInit(): void {
    const validatorOptions = [safeInputValidator()];

    if (this.isRequired) {
      validatorOptions.push(Validators.required);
    }

    if (this.isValidationRequired) {
      validatorOptions.push(
        this.inputValidatorService.getUnsupportedCharactersValidator()
      );
    }

    if (!!this.maxInputLength) {
      validatorOptions.push(
        this.inputValidatorService.getMaxLengthValidator(this.maxInputLength)
      );
    }

    if (!!this.isSameNameValidationRequired) {
      validatorOptions.push(
        this.inputValidatorService.getUniquNameValidator(
          this.sameNameValidationList
        )
      );
    }

    this.formInput = this.formBuilder.group({
      inputName: new FormControl(
        {
          value: this.value,
          disabled: this.isDisabled,
        },
        validatorOptions
      ),
    });

    this.validityStateChange.emit(this.formInput.valid);
  }
}
