/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { type ManageWorkspaceAgreementType } from '@workspace-documents/manage/manage-workspace-agreement-type.enum';
import { type WorkspaceEmailedDocuments } from '@workspace-documents/manage/models/workspace-emailed-documents.model';
import { type WorkspaceUsageStatistic } from '@workspace-documents/manage/models/workspace-usage-statistic.model';

import { type OngoingWorkspaceSettings } from '../documents/shared/models/ongoing-workspace-settings.model';

export const ongoingManageWorkspaceActions = {
  SET_WORKSPACE_SETTINGS: 'SET_ONGOING_WORKSPACE_SETTINGS',
  SET_WORKSPACE_SETTINGS_WATERMARK: 'SET_ONGOING_WORKSPACE_SETTINGS_WATERMARK',
  SET_WORKSPACE_SETTINGS_NAME: 'SET_ONGOING_WORKSPACE_SETTINGS_NAME',
  SET_WORKSPACE_SETTINGS_NOTIFICATIONS:
    'SET_ONGOING_WORKSPACE_SETTINGS_NOTIFICATIONS',
  SET_WORKSPACE_SETTINGS_STATISTIC: 'SET_ONGOING_WORKSPACE_SETTINGS_STATISTIC',
  SET_WORKSPACE_SETTINGS_EMAILED_DOCUMENTS:
    'SET_ONGOING_WORKSPACE_SETTINGS_EMAILED_DOCUMENTS',
  SET_WORKSPACE_SETTINGS_AGREEMENT: 'SET_ONGOING_WORKSPACE_SETTINGS_AGREEMENT',
  REMOVE_WORKSPACE_SETTINGS: 'REMOVE_ONGOING_WORKSPACE_SETTINGS',
};

export const setOngoingWorkspaceSettings = (
  workspaceSettings: OngoingWorkspaceSettings
) => ({
  type: ongoingManageWorkspaceActions.SET_WORKSPACE_SETTINGS,
  payload: workspaceSettings,
});

export const setOngoingWorkspaceSettingsName = (workspaceName: string) => ({
  type: ongoingManageWorkspaceActions.SET_WORKSPACE_SETTINGS_NAME,
  payload: workspaceName,
});

export const setOngoingWorkspaceSettingsWatermark = (
  workspaceWatermarkStatus: boolean
) => ({
  type: ongoingManageWorkspaceActions.SET_WORKSPACE_SETTINGS_WATERMARK,
  payload: workspaceWatermarkStatus,
});

export const setOngoingWorkspaceSettingsNotifications = (
  workspaceNotificationsStatus: boolean
) => ({
  type: ongoingManageWorkspaceActions.SET_WORKSPACE_SETTINGS_NOTIFICATIONS,
  payload: workspaceNotificationsStatus,
});

export const setOngoingWorkspaceSettingsStatistic = (
  workspaceUsageStatistic: WorkspaceUsageStatistic
) => ({
  type: ongoingManageWorkspaceActions.SET_WORKSPACE_SETTINGS_STATISTIC,
  payload: workspaceUsageStatistic,
});

export const setOngoingWorkspaceSettingsEmailedDocuments = (
  workspaceEmailedDocumentsStatus: WorkspaceEmailedDocuments
) => ({
  type: ongoingManageWorkspaceActions.SET_WORKSPACE_SETTINGS_EMAILED_DOCUMENTS,
  payload: workspaceEmailedDocumentsStatus,
});

export const setOngoingWorkspaceSettingsAgreement = (
  workspaceAgreement: ManageWorkspaceAgreementType
) => ({
  type: ongoingManageWorkspaceActions.SET_WORKSPACE_SETTINGS_AGREEMENT,
  payload: workspaceAgreement,
});

export const removeOngoingWorkspaceSettings = () => ({
  type: ongoingManageWorkspaceActions.REMOVE_WORKSPACE_SETTINGS,
});
