/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { InjectionToken } from '@angular/core';
import { type InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/ngx';

const TOKEN_RESPONSE_KEY: string = 'token_response';
const AUTH_CODE_KEY: string = 'authorization_code';
/** key in local storage which represents the current authorization request. */
const AUTHORIZATION_REQUEST_HANDLE_KEY: string =
  'appauth_current_authorization_request';
const AUTHORIZATION_RESPONSE_KEY: string = 'auth_repsonse';

const AUTHORIZATION_REQUEST_KEY: string = 'appauth_authorization_request';
const AUTHORIZATION_SERVICE_CONFIGURATION: string =
  'appauth_authorization_service_configuration';

const STORAGE_BACKEND_ERROR: string = 'Couldn`t read from storageBackend.';
const GET_USER_INFO_ERROR: string =
  "[OpenIdModule] Couldn't retrieve user info";
const GET_CONFIGURATION_ERROR: string =
  "[OpenIdModule] Couldn't retrieve configuration";
const AUTHORIZATION_REQUEST_ERROR: string =
  '[OpenIdModule] Authorization request error';
const ACCESS_TOKEN_REQUEST_ERROR: string =
  '[OpenIdModule] Access token request error';
const ACCESS_TOKEN_UPDATE_ERROR: string =
  '[OpenIdModule] Access token update error';
const UNEXPECTED_BROWSER_CLOSING_ERROR: string =
  '[OpenIdModule] Unexpected  InAppBrowser closing';

const POST_LOGOUT_REDIRECT_URI: string = 'post_logout_redirect_uri';
const ID_TOKEN_HINT: string = 'id_token_hint';

const DEFAULT_TIME_TO_CHECK_TOKEN_TO_UPDATE_IN_SECONDS: number = 10;
const HOW_OFTEN_CHECK_ACCESS_TOKEN_FOR_REFRESH: number = 3;
const SEC_IN_MINUTES: number = 60;
const MILLSEC_IN_SECONDS: number = 1000;
const IOS_INAPPBROWSER_IGNORE_ERROR: string =
  'CDVWebViewDelegate: Navigation started when state=1';

// TODO remove after inAppBrowser plugin crash while opening new window just after closing previous will be fixed
// https://github.com/apache/cordova-plugin-inappbrowser/issues/330
const APPROXIMATE_DELAY_BEFORE_OPENING_INAPP_BROWSER: number = 500;

const IN_APP_BROWSER_OPTIONS: InAppBrowserOptions = {
  location: 'no',
  closebuttoncaption: 'Close',
};

interface OpenIdConfig {
  authority: string;
  response_type: string;
  scope: string;
  automaticSilentRenew: true;
  client_id: string;
  redirect_uri: string;
  post_logout_redirect_uri: string;
  silent_redirect_uri: string;
  popupWindowFeatures: string;
  accessTokenExpiringNotificationTime: number;
  silentRequestTimeout: number;
}

class UserProfile {
  public amr: string[];
  public auth_time: number;
  public email: string;
  public family_name: string;
  public fednotused: string;
  public given_name: string;
  public idp: string;
  public name: string;
  public sid: string;
  public stier: string;
  public sub: string;
  public zoneinfo: string;
}

class User {
  public id_token: string;
  public access_token: string;
  public token_type: string;
  public scope: string;
  public profile: UserProfile;
  public refreshToken: string;
  public expires_at: number;
  public expires_in: number | undefined;
  public expired: boolean | undefined;
  public scopes?: string;

  constructor(source: User) {
    for (const key of Object.keys(source)) {
      this[key] = source[key];
    }
  }
}

class AuthorizationResponseParsed {
  constructor(
    public state: string | undefined,
    public code: string | undefined,
    public error: string | undefined,
    public error_description: any
  ) {}
}

interface XhrSettings {
  url: string;
  dataType: string;
  method: 'GET' | 'POST';
  data: any;
  headers: any; // {key : string, value: any}
}

const OPEN_ID_CONFIG: InjectionToken<string> = new InjectionToken<string>(
  'OPEN_ID_CONFIG'
);

const DEFAULT_OPEN_ID_CONFIG: OpenIdConfig = {} as any;

enum SignInBrowserState {
  open,
  close,
}

export {
  OpenIdConfig,
  UserProfile,
  User,
  AuthorizationResponseParsed,
  XhrSettings,
  OPEN_ID_CONFIG,
  DEFAULT_OPEN_ID_CONFIG,
  AUTH_CODE_KEY,
  TOKEN_RESPONSE_KEY,
  AUTHORIZATION_RESPONSE_KEY,
  AUTHORIZATION_REQUEST_HANDLE_KEY,
  STORAGE_BACKEND_ERROR,
  AUTHORIZATION_SERVICE_CONFIGURATION,
  AUTHORIZATION_REQUEST_KEY,
  GET_USER_INFO_ERROR,
  GET_CONFIGURATION_ERROR,
  AUTHORIZATION_REQUEST_ERROR,
  ACCESS_TOKEN_REQUEST_ERROR,
  ACCESS_TOKEN_UPDATE_ERROR,
  UNEXPECTED_BROWSER_CLOSING_ERROR,
  POST_LOGOUT_REDIRECT_URI,
  ID_TOKEN_HINT,
  DEFAULT_TIME_TO_CHECK_TOKEN_TO_UPDATE_IN_SECONDS,
  APPROXIMATE_DELAY_BEFORE_OPENING_INAPP_BROWSER,
  HOW_OFTEN_CHECK_ACCESS_TOKEN_FOR_REFRESH,
  SEC_IN_MINUTES,
  MILLSEC_IN_SECONDS,
  IN_APP_BROWSER_OPTIONS,
  SignInBrowserState,
  IOS_INAPPBROWSER_IGNORE_ERROR,
};
