/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';

import { type Observable } from 'rxjs';

import { BaseStorageService } from '@core/storage/base-storage.service';
import { StorageStrategyFactoryResolverService } from '@core/storage/storage-strategy-factory-resolver.service';

@Injectable()
export class PasscodeStoreService extends BaseStorageService<string> {
  private userIdErrorMessageStart: string = 'PasscodeStoreService:: Cannot ';
  private userIdErrorMessageEnd: string = ' due to the invalidity of userId';

  constructor(
    protected storageStrategyResolverService: StorageStrategyFactoryResolverService
  ) {
    super(storageStrategyResolverService);
  }

  public getPasscode(userId: string): Observable<string> {
    if (!this.isUserIdValid(userId)) {
      throw new Error(
        `${this.userIdErrorMessageStart}getPasscode${this.userIdErrorMessageEnd}`
      );
    }

    const storeKey: string = this.getKey(userId);
    return super.get(storeKey);
  }

  public savePasscode(
    encryptedPasscode: string,
    userId: string
  ): Observable<void> {
    if (!this.isUserIdValid(userId)) {
      throw new Error(
        `${this.userIdErrorMessageStart}savePasscode${this.userIdErrorMessageEnd}`
      );
    }

    const storeKey: string = this.getKey(userId);

    return super.set(storeKey, encryptedPasscode);
  }

  public removePasscode(userId: string): Observable<void> {
    if (!this.isUserIdValid(userId)) {
      throw new Error(
        `${this.userIdErrorMessageStart}removePasscode${this.userIdErrorMessageEnd}`
      );
    }

    const storeKey: string = this.getKey(userId);
    return super.delete(storeKey);
  }

  private getKey(userId: string): string {
    return `PASSCODE::${userId}`;
  }

  private isUserIdValid(userId: string): boolean {
    return !!userId;
  }
}
