/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { type User, UserManager } from 'oidc-client';

import { type TokensResponse } from '@core/app-initialization/models/tokens-response.model';
import { HttpMethod } from '@core/pcs-http/enums/http-method.enum';
import { UrlHelperService } from '@shared/url-helper.service';

import { AuthCoreHelperService } from './auth-core-helper.service';
import { type AuthParams } from './models/auth-params.model';

export class AuthCoreService {
  private static get rootUrl(): string {
    return location.origin;
  }

  public static async tryToAuthenticate(): Promise<void> {
    let authParams: AuthParams = AuthCoreService.getAuthParamsFromQuery();
    let loginUrl: string;

    if (AuthCoreHelperService.isNewLoginFlow(authParams)) {
      const tokensResponse: TokensResponse = await this.fetchTokens(authParams);

      authParams = AuthCoreHelperService.fillAuthParams(
        authParams,
        tokensResponse
      );
      loginUrl = AuthCoreHelperService.buildLoginUrl(authParams);
    }

    if (!authParams.id_token) {
      return;
    }

    try {
      const userManager: UserManager = AuthCoreService.getUserManager();

      const user: User = loginUrl
        ? await userManager.signinRedirectCallback(loginUrl)
        : await userManager.signinRedirectCallback();
      user.id_token = authParams.id_token;

      await userManager.storeUser(user);

      AuthCoreService.softRedirectToRoot();
    } catch (e) {
      alert(e.message);
      AuthCoreService.hardRedirectToRoot();
    }
  }

  private static getUserManager(): UserManager {
    return new UserManager({});
  }

  private static getAuthParamsFromQuery(): AuthParams {
    const dirtyParams: string[] = location.search.substring(1).split('&');
    const result: AuthParams = {};

    dirtyParams.forEach((param: string) => {
      const temp = param.split('=');
      const [key, value] = temp;

      if (key) {
        result[key] = value;
      }
    });

    return result;
  }

  private static softRedirectToRoot(): void {
    history.replaceState(null, '', AuthCoreService.rootUrl);
  }

  private static hardRedirectToRoot(): void {
    location.assign(AuthCoreService.rootUrl);
  }

  private static fetchTokens(authParams: AuthParams): Promise<TokensResponse> {
    const requestBody: string = JSON.stringify({
      tokenTicket: authParams.token_ticket,
      tokenTicketKey: authParams.token_ticket_key,
    });

    return fetch(UrlHelperService.getTokensUrl(), {
      method: HttpMethod.Post,
      headers: {
        'Content-Type': 'application/json',
      },
      body: requestBody,
    }).then((response) => response.json());
  }
}
