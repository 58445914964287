/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SkipLoaderOptionsService } from '@core/pcs-http/skip-loader-options.service';
import { type PcsResponse } from '@shared/pcs-response.model';

import { setCountries } from '../../../countries/countries.actions';
import { type Country } from '../../../models/country.model';
import { type ParticipantCompany } from '../../../models/participant-create-request.model';

@Injectable()
export class UserEntityDataService {
  constructor(private http: HttpClient, private store: Store<Country[]>) {}

  public searchCompany(
    name: string,
    callUrl: string,
    skipLoader?: boolean
  ): Observable<ParticipantCompany[]> {
    const skipNotFoundError = true;
    const skipServerError = true;
    const options = SkipLoaderOptionsService.getSkipOptions(
      skipLoader,
      skipNotFoundError,
      skipServerError
    );

    options.params.name = name;

    return this.http
      .get<PcsResponse<ParticipantCompany>>(callUrl, options)
      .pipe(
        map(
          (companies: PcsResponse<ParticipantCompany>): ParticipantCompany[] =>
            companies.items
        )
      );
  }

  public getCountries(): Observable<Country[]> {
    const skipNotFoundError = true;
    const skipServerError = true;
    const skipLoader = true;
    const options = SkipLoaderOptionsService.getSkipOptions(
      skipLoader,
      skipNotFoundError,
      skipServerError
    );
    const callUrl = '/geography/countries';

    return this.http.get<PcsResponse<Country>>(callUrl, options).pipe(
      map((countries: PcsResponse<Country>): Country[] => {
        countries.items = countries.items.map((country: Country) => {
          /* State should be disabled for country other than United States, Canada, or Australia*/
          if (
            country.name !== 'United States' &&
            country.name !== 'Canada' &&
            country.name !== 'Australia'
          ) {
            country.provinces = [];
          }
          return country;
        });
        return this.onGetCountries(countries.items);
      })
    );
  }

  private onGetCountries(countries: Country[]): Country[] {
    this.store.dispatch(setCountries(countries));
    return countries;
  }
}
