/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { IonicModule } from '@ionic/angular';

import { PcsAppVersion } from '@shared/pcs-app-version/pcs-app-version.service';
import { StatusNotificationToastsService } from '@shared/status-notification-toasts/status-notification-toasts.service';
import { StringToColorDirectiveModule } from '@shared/string-to-colour/string-to-color-directive.module';
import { UpdateAppService } from '@shared/update-app/update-app.service';

import { PasscodeModalModule } from '../passcode-modal/passcode-modal.module';
import { AboutMenuComponent } from './about-menu/about-menu.component';
import { AccountMenuService } from './account-menu.service';
import { AccountSettingsDrawerComponent } from './account-settings-drawer/account-settings-drawer.component';
import { BiometricIDAuthMenuComponent } from './biometric-id-auth-menu/biometric-id-auth-menu.component';
import { BiometricIDEnableConfirmService } from './biometric-id-auth-menu/biometric-id-device-settings-confirm.service';
import { DrawerLogoComponent } from './drawer-logo/drawer-logo.component';
import { HelpSupportMenuComponent } from './help-support-menu/help-support-menu.component';
import { MainMenuManipulationService } from './main-menu-manipulation.service';
import { MenuItemsModule } from './menu-items/menu-items.module';
import { SecurityMenuComponent } from './security-menu/security-menu.component';
import { SettingsMenuComponent } from './settings-menu/settings-menu.component';
import { UpdateVersionComponent } from './update-version/update-version.component';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    IonicModule,
    PasscodeModalModule,
    MenuItemsModule,
    FormsModule,
    StringToColorDirectiveModule,
  ],
  declarations: [
    AccountSettingsDrawerComponent,
    UpdateVersionComponent,
    SettingsMenuComponent,
    SecurityMenuComponent,
    AboutMenuComponent,
    HelpSupportMenuComponent,
    DrawerLogoComponent,
    BiometricIDAuthMenuComponent,
  ],
  providers: [
    MainMenuManipulationService,
    BiometricIDEnableConfirmService,
    AccountMenuService,
    InAppBrowser,
    PcsAppVersion,
    UpdateAppService,
    StatusNotificationToastsService,
  ],
})
export class NavigationBarModule {}
