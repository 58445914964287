/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';
import { Dialogs } from '@ionic-native/dialogs/ngx';

import {
  type Observable,
  from as observableFrom,
  of as observableOf,
} from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';

import { PasscodeValidationService } from '@core/security/passcode-validation.service';

import { MainMenuManipulationService } from '../../../navigation-bar/main-menu-manipulation.service';
import { MainMenuOptions } from '../../../navigation-bar/main-menu-options.enum';
import { PasscodeVerificationFail } from './passcode-verification-fail.model';
import { type PasscodeVerificationModalConfig } from './passcode-verification-modal-config.model';

@Injectable()
export class PasscodeVerificationService {
  private buttonLabels: string[] = ['Cancel', 'Settings'];
  // Possible values: 0 - dialog was dismissed, 1 - 'Cancel' clicked, 2 - 'Settings' clicked
  private settingsButtonIndex: number = 2;

  constructor(
    private passcodeValidationService: PasscodeValidationService,
    private mainMenuManipulationService: MainMenuManipulationService,
    private dialogs: Dialogs
  ) {}

  public verifyPasscodeSetup(
    config: PasscodeVerificationModalConfig
  ): Observable<void> {
    return this.passcodeValidationService.isPasscodeAlreadySet().pipe(
      mergeMap((isPasscodeAlreadySet) => {
        if (isPasscodeAlreadySet) {
          return observableOf(null);
        }

        return observableFrom(
          this.dialogs.confirm(config.message, config.title, this.buttonLabels)
        ).pipe(
          tap((clickedButtonIndex: number) => {
            const areSettingsWereChosen =
              clickedButtonIndex === this.settingsButtonIndex;
            if (areSettingsWereChosen) {
              this.mainMenuManipulationService.openMenuOption(
                MainMenuOptions.Security
              );
            }
          }),
          map(() => {
            throw new PasscodeVerificationFail("Passcode wasn't specified");
          })
        );
      })
    );
  }
}
