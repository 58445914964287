/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable, NgZone } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { type TokenResponse } from '@openid/appauth';

import {
  type Observable,
  defer as observableDefer,
  from as observableFrom,
  of as observableOf,
  throwError as observableThrowError,
  throwError,
} from 'rxjs';
import { catchError, map, mergeMap, shareReplay, tap } from 'rxjs/operators';

import { AuthActionsService } from '@core/auth/auth-actions.service';
import { PcsNotAuthorizedOfflineException } from '@core/auth/auth-not-authorized-offline.exception';
import { AuthNativeUserStoreService } from '@core/auth/strategies/native/auth-native-user-store.service';
import { InternetConnectionService } from '@core/offline-core/internet-connection/internet-connection.service';
import { type User } from '@core/openId/src/OpenId.dictionary';
import { UserManager } from '@core/openId/src/userManager/UserManager';
import { PcsPlatformService } from '@core/pcs-platform.service';
import { PcsNoInternetConnectionException } from '@shared/pcs-exceptions/pcs-no-internet-connection.exception';
import { SpinnerAppStartingService } from '@shared/spinners/spinner-app-starting/spinner-app-starting.service';

import { AuthNavigationService } from '../../auth-navigation.service';
import { type AuthStrategyInterface } from '../auth-strategy.interface';
import { type PcsUser } from '../pcs-user.model';

@Injectable()
export class AuthNativeService implements AuthStrategyInterface {
  private currentUser: User;

  private silentSignInFlow: Observable<User>;

  private window: any;

  // prettier-ignore
  constructor( //NOSONAR
    private userManager: UserManager,
    private platform: PcsPlatformService,
    private authNavigationService: AuthNavigationService,
    private authActionsService: AuthActionsService,
    private authNativeUserStoreService: AuthNativeUserStoreService,
    private internetConnectionService: InternetConnectionService,
    private zone: NgZone,
    private menu: MenuController,
    private spinnerAppStartingService: SpinnerAppStartingService
  ) {
    this.window = window;
    this.tryToSetUser = this.tryToSetUser.bind(this);
  }

  public init() {
    this.zone.runOutsideAngular(() => {
      this.userManager.events.addUserLoaded(this.tryToSetUser);

      this.userManager.events.addUserSignedOut(() => {
        /* Delay required for case when we try navigate from root to root
                and "navigate" method does not work */
        this.menu.close();
        observableFrom(this.authNavigationService.navigateToRoot())
          .pipe(
            mergeMap(() => {
              this.spinnerAppStartingService.startLoading();
              return this.clearUser();
            })
          )
          .subscribe(() => {
            this.spinnerAppStartingService.finishLoading();
            this.window.location.reload();
          });
      });

      this.userManager.getUser().subscribe(this.tryToSetUser);

      this.userManager.events.addSilentRenewError(() => {
        this.userManager
          .clearStaleState()
          .pipe(
            mergeMap(() =>
              observableFrom(this.authNavigationService.navigateToRoot())
            ),
            mergeMap(() => this.clearUser()),
            tap(() => this.window.location.reload())
          )
          .subscribe();
      });
    });
  }

  public getCurrentUser(): PcsUser {
    return { session_state: null, ...this.currentUser };
  }

  public getUser(): Observable<PcsUser> {
    return this.userManager
      .getUser()
      .pipe(map((user: User) => ({ session_state: null, ...user })));
  }

  public startSignInMainWindow(): Observable<boolean> {
    const platformReady = observableFrom(this.platform.ready());
    if (this.internetConnectionService.isOffline()) {
      return this.signInOffline(platformReady);
    }
    return this.signInOnline(platformReady);
  }

  public startSignOutMainWindow() {
    return this.clearUser().pipe(
      mergeMap(() => this.userManager.signoutPopup()),
      tap(() => this.authActionsService.signOut()),
      catchError(() => this.startSignInMainWindow())
    );
  }

  public signinSilent(): Observable<any> {
    if (this.internetConnectionService.isOffline()) {
      throw new PcsNoInternetConnectionException(
        'AuthNativeService::signinSilent:' +
          ' Can not silent renew, internet connection is required for this action'
      );
    }

    if (this.silentSignInFlow) {
      return this.silentSignInFlow;
    }

    this.silentSignInFlow = observableDefer(() => this.platform.ready()).pipe(
      mergeMap(() => this.userManager.signinSilent()),
      tap((user) => {
        this.silentSignInFlow = null;
        this.onOnlineSignIn(user);
      }),
      catchError((error) => {
        this.silentSignInFlow = null;
        return observableThrowError(() => error);
      }),
      shareReplay()
    );

    return this.silentSignInFlow;
  }

  public stopSignIn(): void {
    this.userManager.stopSignIn();
  }

  public getTokenFromStorage(): Observable<TokenResponse> {
    return this.userManager.getTokenFromStorage();
  }

  private signInOffline(
    platformReady: Observable<string>
  ): Observable<boolean> {
    return platformReady.pipe(
      mergeMap(() => this.authNativeUserStoreService.getUser()),
      mergeMap((user) => {
        user.expires_at += user.expires_in;

        if (user) {
          this.currentUser = user;
          return observableOf(true);
        }

        return observableThrowError(
          () =>
            new PcsNotAuthorizedOfflineException(
              'AuthNativeService:: Not authorized user in offline'
            )
        );
      })
    );
  }

  private signInOnline(platformReady: Observable<string>): Observable<boolean> {
    return platformReady.pipe(
      mergeMap(() => this.userManager.signinPopup()),
      map((user) => {
        this.onOnlineSignIn(user);
        return true;
      }),
      catchError((err) => {
        console.log(err);
        return throwError(err);
      })
    );
  }

  private tryToSetUser(user: User): void {
    if (user) {
      this.setUser(user);
    }
  }

  private setUser(user: User): void {
    this.currentUser = user;
    this.authNativeUserStoreService.saveUser(user).subscribe();
  }

  private clearUser(): Observable<void> {
    this.currentUser = null;
    return this.authNativeUserStoreService.removeUser();
  }

  private onOnlineSignIn(user: User): void {
    this.setUser(user);

    const pcsUser = { session_state: null, ...this.currentUser };
    this.authActionsService.signIn(pcsUser);
  }
}
