/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { type OnDestroy, Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Store } from '@ngrx/store';

import {
  type Observable,
  type Subscription,
  combineLatest as observableCombineLatest,
  from as observableFrom,
} from 'rxjs';

import { AuthService } from '@core/auth/auth.service';
import { PcsPlatformService } from '@core/pcs-platform.service';
import { versionDetailsSelector } from '@core/version/version.reducers';
import { PcsAppVersion } from '@shared/pcs-app-version/pcs-app-version.service';
import { UpdateAppService } from '@shared/update-app/update-app.service';

import { type AppVersionDetails } from '../app-version-details.model';
import { VersionCompareService } from '../version-compare.service';

@Injectable()
export class UpdateControlService implements OnDestroy {
  public versionStream: Observable<[AppVersionDetails, string, string]>;
  private versionStreamSubscription: Subscription;

  constructor(
    private alertController: AlertController,
    private updateAppService: UpdateAppService,
    private versionCompareService: VersionCompareService,
    private pcsAppVersion: PcsAppVersion,
    private store: Store<AppVersionDetails>,
    private authService: AuthService,
    private pcsPlatformService: PcsPlatformService
  ) {}

  public ngOnDestroy(): void {
    this.versionStreamSubscription.unsubscribe();
  }

  public startCheckForAppVersionUpdate(): void {
    const appVersionDefer: Promise<any> = this.pcsAppVersion.getVersionNumber();
    const appNameDefer: Promise<any> = this.pcsAppVersion.getPackageName();

    this.versionStream = observableCombineLatest([
      this.store.select(versionDetailsSelector),
      observableFrom(appVersionDefer),
      observableFrom(appNameDefer),
    ]);

    this.versionStreamSubscription = this.versionStream.subscribe(
      (result: [AppVersionDetails, string, string]) =>
        this.onGetVersionDetails(result)
    );
  }

  private onGetVersionDetails([
    versionDetails,
    currentAppVersion,
    appName,
  ]): void {
    if (!versionDetails || !currentAppVersion || !appName) {
      return;
    }

    const { minimumClientCompatibleVersion } = versionDetails;
    const shouldForceUpdate: boolean =
      this.versionCompareService.isVersionGreaterThanAppVersion(
        minimumClientCompatibleVersion,
        currentAppVersion
      );

    const isOnDevice = this.pcsPlatformService.isOnDevice();

    if (shouldForceUpdate && isOnDevice) {
      this.authService.stopSignIn();
      this.forceToUpdate(appName);
    }
  }

  private async forceToUpdate(appName: string): Promise<void> {
    if (this.isIPhoneApp(appName)) {
      const alert = await this.alertController.create({
        header: 'It’s time to update',
        message:
          'Beginning November 2019, this iPhone app will no longer be supported or updated. Please download "IHS Markit Prism Portal".',
        cssClass: 'pcs-update-version-alert',
        backdropDismiss: false,
        buttons: [
          {
            text: 'Download',
            handler: () => {
              this.updateAppService.openAppStoreForUpdate();
            },
          },
        ],
      });

      alert.present();
    } else {
      const alert = await this.alertController.create({
        header: 'It’s time to update',
        message: 'To continue using the Prism App, you’ll have to update now.',
        cssClass: 'pcs-update-version-alert',
        backdropDismiss: false,
        buttons: [
          {
            text: 'Update',
            handler: () => {
              this.updateAppService.openAppStoreForUpdate();
            },
          },
        ],
      });

      alert.present();
    }
  }

  private isIPhoneApp(appName): boolean {
    return appName === 'com.ipreo.pcs.boardportal.phone';
  }
}
