/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { createAction, props } from '@ngrx/store';

import { type TransactionWorkspace } from './transaction-workspace.model';
import { type TransactionWorkspaceFeatures } from './transaction-workspace-features.model';
import { type TransactionWorkspaceStatistics } from './transaction-workspace-statistics.model';

export const transactionWorkspaceActions = {
  SET_ACTIVE_WORKSPACE: 'SET_ACTIVE_TRANSACTION_WORKSPACE',
  SET_ACTIVE_WORKSPACE_NAME: 'SET_ACTIVE_TRANSACTION_WORKSPACE_NAME',
  SET_ACTIVE_WORKSPACE_STATISTICS:
    'SET_ACTIVE_TRANSACTION_WORKSPACE_STATISTICS',
  SET_ACTIVE_WORKSPACE_FEATURES: 'SET_ACTIVE_TRANSACTION_WORKSPACE_FEATURES',
  SET_ACTIVE_WORKSPACE_ANNOTATIONS_STATE:
    'SET_ACTIVE_TRANSACTION_WORKSPACE_ANNOTATIONS_STATE',
  SET_ACTIVE_WORKSPACE_DOCUMENT_INDEXING:
    'SET_ACTIVE_TRANSACTION_WORKSPACE_DOCUMENT_INDEXING',
  SET_NEW_COUNT: 'SET_TRANSACTION_NEW_COUNT',
};

export const setActiveTransactionWorkspaceDocumentAnnotationsState =
  createAction(
    transactionWorkspaceActions.SET_ACTIVE_WORKSPACE_ANNOTATIONS_STATE,
    props<{ isEnabled: boolean }>()
  );

export const setActiveTransactionWorkspace = (
  activeWorkspace: TransactionWorkspace
) => ({
  type: transactionWorkspaceActions.SET_ACTIVE_WORKSPACE,
  payload: activeWorkspace,
});

export const setActiveTransactionWorkspaceName = (
  newWorkspaceName: string
) => ({
  type: transactionWorkspaceActions.SET_ACTIVE_WORKSPACE_NAME,
  payload: newWorkspaceName,
});

export const setActiveTransactionWorkspaceStatistics = (
  workspaceStatistics: TransactionWorkspaceStatistics
) => ({
  type: transactionWorkspaceActions.SET_ACTIVE_WORKSPACE_STATISTICS,
  payload: workspaceStatistics,
});

export const setActiveTransactionWorkspaceFeatures = (
  workspaceFeatures: TransactionWorkspaceFeatures
) => ({
  type: transactionWorkspaceActions.SET_ACTIVE_WORKSPACE_FEATURES,
  payload: workspaceFeatures,
});

export const setTransactionNewDocumentsCount = (newDocumentsCount: number) => ({
  type: transactionWorkspaceActions.SET_NEW_COUNT,
  payload: newDocumentsCount,
});

export const setActiveTransactionWorkspaceDocumentIndexing = (
  isIndexShowing: boolean
) => ({
  type: transactionWorkspaceActions.SET_ACTIVE_WORKSPACE_DOCUMENT_INDEXING,
  payload: isIndexShowing,
});
