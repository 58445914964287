/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { createFeatureSelector } from '@ngrx/store';

import { type UnsafeAction } from '@core/ngrx-helper';

import { type TransactionWorkspaceSettings } from '../documents/shared/models/transaction-workspace-settings.model';
import { transactionManageWorkspaceActions } from './transaction-manage-workspace.actions';

export const transactionManageWorkspaceSelector =
  createFeatureSelector<TransactionWorkspaceSettings>(
    'transactionManageWorkspaceSettings'
  );

export const transactionManageWorkspace = (
  state: TransactionWorkspaceSettings,
  action: UnsafeAction
) => {
  switch (action.type) {
    case transactionManageWorkspaceActions.SET_WORKSPACE_SETTINGS:
      return { ...action.payload };
    case transactionManageWorkspaceActions.SET_WORKSPACE_SETTINGS_NAME:
      const isWorkspaceSettingsDetails = !!state;
      if (isWorkspaceSettingsDetails) {
        const workspaceDetails = state.details;
        const newWorkspaceDetails = {
          ...workspaceDetails,
          name: action.payload,
        };
        return { ...state, details: newWorkspaceDetails };
      }
      return state;
    case transactionManageWorkspaceActions.SET_WORKSPACE_SETTINGS_WATERMARK:
      const isWorkspaceSettingsWatermark = !!state;
      if (isWorkspaceSettingsWatermark) {
        const workspaceWatermark = state.watermark;
        const newWorkspaceWatermark = {
          ...workspaceWatermark,
          defaultWatermarkPreference: action.payload,
        };
        return { ...state, watermark: newWorkspaceWatermark };
      }
      return state;
    case transactionManageWorkspaceActions.SET_WORKSPACE_SETTINGS_NOTIFICATIONS:
      const isWorkspaceSettingsNotifications = !!state;
      if (isWorkspaceSettingsNotifications) {
        const workspaceNotifications = state.notifications;
        const newWorkspaceNotifications = {
          ...workspaceNotifications,
          isNotificationEnabled: action.payload,
        };
        return { ...state, notifications: newWorkspaceNotifications };
      }
      return state;
    case transactionManageWorkspaceActions.SET_WORKSPACE_SETTINGS_EMAILED_DOCUMENTS:
      if (!!state) {
        return { ...state, emailedDocuments: action.payload };
      }
      return state;
    case transactionManageWorkspaceActions.SET_WORKSPACE_SETTINGS_STATUS:
      const isWorkspaceSettingsStatus = !!state;
      if (isWorkspaceSettingsStatus) {
        const workspaceStatus = state.status;
        const newWorkspaceStatus = { ...workspaceStatus, name: action.payload };
        return { ...state, status: newWorkspaceStatus };
      }
      return state;
    case transactionManageWorkspaceActions.SET_WORKSPACE_SETTINGS_STATISTIC:
      if (!!state) {
        return { ...state, statistics: action.payload };
      }
      return state;
    case transactionManageWorkspaceActions.SET_WORKSPACE_SETTINGS_AGREEMENT:
      const isWorkspaceSettingsAgreement = !!state;
      if (isWorkspaceSettingsAgreement) {
        const workspaceAgreement = state.agreement;
        const newWorkspaceAgreement = {
          ...workspaceAgreement,
          type: action.payload,
        };
        return { ...state, agreement: newWorkspaceAgreement };
      }
      return state;
    case transactionManageWorkspaceActions.REMOVE_WORKSPACE_SETTINGS:
      return {};
    default:
      return state;
  }
};
