/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';

import { type Observer, Observable } from 'rxjs';

import { InternetConnectionService } from '@core/offline-core/internet-connection/internet-connection.service';

import { type PermissionNode } from '../../models/permission-node.model';
import { PermissionLevel } from '../../models/permission-set.model';
import { PermissionTreeService } from './permissions-tree.service';

@Injectable()
export class PermissionsSetDetailsActionSheetService {
  constructor(
    private actionSheetController: ActionSheetController,
    private internetConnectionService: InternetConnectionService,
    private permissionTreeService: PermissionTreeService
  ) {}

  public openPermissionLevelEdit(node: PermissionNode): Observable<any> {
    let isDismissed = false;
    return new Observable((observer: Observer<any>) => {
      const alertConfig = {
        title: node.name,
        subTitle: `${this.getSubTitleText(node)}`,
        cssClass: `pcs-action-sheet_permissions ${this.getNodeIconClass(node)}`,
        buttons: [
          {
            cssClass: `pcs-action-sheet-button pcs-action-sheet-button_permissions permissions-set-edit-action-sheet
                        ${
                          node.permissionLevels.has(PermissionLevel.NoAccess) &&
                          node.permissionLevels.size === 1
                            ? 'permissions-set-edit-action-sheet_no-access'
                            : ''
                        }`,
            text: 'No Access',
            handler: () => {
              isDismissed = true;
              observer.next(PermissionLevel.NoAccess);
              observer.complete();
            },
          },
          {
            cssClass: `pcs-action-sheet-button pcs-action-sheet-button_permissions permissions-set-edit-action-sheet
                        ${
                          node.permissionLevels.has(PermissionLevel.View) &&
                          node.permissionLevels.size === 1
                            ? 'permissions-set-edit-action-sheet_view'
                            : ''
                        }`,
            text: 'View Only',
            handler: () => {
              isDismissed = true;
              observer.next(PermissionLevel.View);
              observer.complete();
            },
          },
          {
            cssClass: `pcs-action-sheet-button pcs-action-sheet-button_permissions permissions-set-edit-action-sheet
                        ${
                          node.permissionLevels.has(
                            PermissionLevel.ViewDownload
                          ) && node.permissionLevels.size === 1
                            ? 'permissions-set-edit-action-sheet_view'
                            : ''
                        }`,
            text: 'View + Download',
            handler: () => {
              isDismissed = true;
              observer.next(PermissionLevel.ViewDownload);
              observer.complete();
            },
          },
        ],
      };

      let actionSheet;

      this.actionSheetController.create(alertConfig).then((sheet) => {
        actionSheet = sheet;
        actionSheet.present();
      });

      this.internetConnectionService
        .startObserveInternetDown()
        .subscribe(() => {
          actionSheet.dismiss();
        });

      return () => {
        if (!isDismissed) {
          actionSheet.dismiss();
        }
      };
    });
  }

  private getNodeIconClass(node: PermissionNode): string {
    return this.permissionTreeService.isFile(node)
      ? 'pcs-action-sheet_permissions-file'
      : 'pcs-action-sheet_permissions-folder';
  }

  private getSubTitleText(node: PermissionNode): string {
    return this.permissionTreeService.isFile(node)
      ? ''
      : 'This change will apply to all files currently in this folder.';
  }
}
