/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';

import { type Subscriber, Observable, of as observableOf } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { AuthService } from '@core/auth/auth.service';
import { SessionStoreService } from '@core/auth/session/session-store.service';
import { type FileEntry } from '@core/native-http/models/file-entry.model';
import { NativeHttpService } from '@core/native-http/native-http.service';
import { NativeFileHelperService } from '@shared/files/native-file-helper.service';
import { type ResolveFileSystemUrl } from '@shared/files/resolve-file-system-url.type';
import { getHash } from '@shared/utils/get-hash/get-hash';

import { type FileStrategyInterface } from './file-strategy.interface';

@Injectable()
export class NativeFileStrategyService implements FileStrategyInterface {
  private baseFolder: string = 'NoCloud';
  private tempFolder: string = 'Temp';

  constructor(
    private sessionStoreService: SessionStoreService,
    private authService: AuthService,
    private nativeHttpService: NativeHttpService,
    private nativeFileHelperService: NativeFileHelperService
  ) {}

  private get documentsBasePath(): string {
    return `${cordova['file'].documentsDirectory}`;
  }

  private get resolveFileSystemUrl(): ResolveFileSystemUrl {
    return window['resolveLocalFileSystemURL'];
  }

  public isFileExistInFolder(
    fileName: string,
    folderName: string = 'Temp'
  ): Observable<boolean> {
    return new Observable((observer) => {
      const fileStorageLink = this.documentsBasePath;

      this.resolveFileSystemUrl(fileStorageLink, (dir) => {
        dir.createReader().readEntries(
          (entries) => {
            if (!entries.filter((file) => file.name === folderName).length) {
              observer.next(false);
              observer.complete();
            } else {
              const fileStorageTempLink =
                this.getFileStorageTempLink(folderName);

              window['resolveLocalFileSystemURL'](
                fileStorageTempLink,
                (tempDir) => {
                  tempDir.createReader().readEntries(
                    (tempDirEntries) => {
                      observer.next(
                        !!tempDirEntries.filter(
                          (file) => file.name === fileName.replace(/\s/g, '')
                        ).length
                      );
                      observer.complete();
                    },
                    (error) =>
                      observer.error(
                        `Error searching temp file: ${error.code}`
                      ),
                    () => observer.error("Temp doesn't exist")
                  );
                }
              );
            }
          },
          (error) => observer.error(`Error searching temp file: ${error.code}`),
          () => observer.error("Temp doesn't exist")
        );
      });
    });
  }

  public downloadFileByLink(
    url: string,
    fileName: string,
    headers = {}
  ): Observable<string> {
    const hash: string = getHash();
    const storedFileName: string = `${hash}${fileName}`.replace(/ /g, '');
    const localPath: string = `${this.documentsBasePath}${this.baseFolder}/${storedFileName}`;

    return this.nativeHttpService
      .downloadFile({
        url,
        headers,
        localPath,
      })
      .pipe(map(() => storedFileName));
  }

  public downloadAnnotationFileByLink(
    url: string,
    annotationsLocalLink = '',
    headers = {}
  ): Observable<string> {
    if (!url) {
      return observableOf(null);
    }

    const hash: string = getHash();
    const storedFileName: string = annotationsLocalLink
      ? annotationsLocalLink
      : `${hash}annotations.xfdf`;
    const localPath = `${this.documentsBasePath}${this.baseFolder}/${storedFileName}`;

    return this.nativeHttpService
      .downloadFile({
        url,
        headers,
        localPath,
      })
      .pipe(map(() => storedFileName));
  }

  public downloadTempFileByLink(
    url: string,
    fileName: string,
    headers = {}
  ): Observable<string> {
    const storedFileName = `${fileName}`.replace(/ /g, '');
    const localPath = `${this.documentsBasePath}${this.tempFolder}/${storedFileName}`;

    return this.nativeHttpService
      .downloadFile({
        url,
        headers,
        localPath,
      })
      .pipe(map(() => storedFileName));
  }

  public uploadAnnotationFileByLink(
    fileName: string,
    url: string
  ): Observable<void> {
    const SessionId = this.sessionStoreService.getSessionId();
    const accessToken = this.authService.getCurrentUser().access_token;
    const IAToken: string = `Bearer ${accessToken}`;
    const uploadFileName: string = 'AnnotationsContent';
    const headers = {
      SessionId,
      Authorization: IAToken,
    };
    const localPath = `${this.documentsBasePath}${this.baseFolder}/${fileName}`;

    return this.nativeHttpService
      .uploadFile({
        url,
        headers,
        localPath,
        fileName: uploadFileName,
      })
      .pipe(map(() => null));
  }

  public deleteTempFiles(): Observable<void> {
    return new Observable((observer) => {
      const fileStorageLink = this.getFileStorageTempLink();

      this.resolveFileSystemUrl(fileStorageLink, (dir) => {
        dir.removeRecursively(
          () => {
            console.log('The temp files has been removed successfully');
            observer.next(null);
            observer.complete();
          },
          (error) => observer.error(`Error deleting temp files: ${error.code}`),
          () => observer.error("Temp doesn't exist")
        );
      });
    });
  }

  public deleteFile(fileName: string): Observable<void> {
    const actualFileName = fileName.includes('NoCloud')
      ? fileName.split('/').pop()
      : fileName;
    return new Observable((observer) => {
      const fileStorageLink = this.getFileStorageBaseLink();

      this.resolveFileSystemUrl(fileStorageLink, (dir) => {
        dir.getFile(actualFileName, { create: false }, (fileEntry) => {
          fileEntry.remove(
            () => {
              console.log(
                `The file ${actualFileName} has been removed successfully`
              );
              observer.next(null);
              observer.complete();
            },
            (error) => observer.error(`Error deleting the file: ${error.code}`),
            () => observer.error("The file doesn't exist")
          );
        });
      });
    });
  }

  public getFreeDiskSpace(): Observable<string> {
    return new Observable((observer) => {
      cordova.exec(
        (result) => {
          // result is free Disk Space in Kilobytes
          observer.next(result);
          observer.complete();
        },
        (error) => {
          observer.error(`Error.getFreeDiskSpaceError: '${error}`);
        },
        'File',
        'getFreeDiskSpace',
        []
      );
    });
  }

  public writeFile(
    folder: string,
    filename: string,
    blob: Blob
  ): Observable<string> {
    return new Observable<string>((observer) => {
      const onError = observer.error.bind(observer);

      const onResolveFileSystem = (dirPath) => {
        dirPath.getDirectory(`/${folder}`, { create: true }, (dir) => {
          dir.getFile(
            filename,
            { create: true, exclusive: false },
            (fileEntry) => {
              fileEntry.createWriter((fileWriter) => {
                fileWriter.onwriteend = () => {
                  observer.next(filename);
                  observer.complete();
                };

                fileWriter.onerror = onError;
                fileWriter.write(blob);
              });
            },
            onError
          );
        });
      };

      this.resolveFileSystemUrl(
        this.documentsBasePath,
        onResolveFileSystem,
        onError
      );
    });
  }

  public readFile(folder: string, fileName: string): Observable<File> {
    return this.readFileEntry(folder, fileName).pipe(
      mergeMap((fileEntry: FileEntry) =>
        this.nativeFileHelperService.getFileFromFileEntry(fileEntry)
      )
    );
  }

  public readFileAsBlob(folder: string, fileName: string): Observable<Blob> {
    return this.readFile(folder, fileName).pipe(
      mergeMap((file: File) =>
        this.nativeFileHelperService.convertFileToBlob(file)
      )
    );
  }

  public getFileStorageBaseLink(): string {
    return `${this.documentsBasePath}NoCloud`;
  }

  public getFileStorageTempLink(folder = 'Temp'): string {
    return `${this.documentsBasePath}${folder}`;
  }

  private readFileEntry(
    folder: string,
    fileName: string
  ): Observable<FileEntry> {
    return new Observable<FileEntry>((observer: Subscriber<FileEntry>) => {
      const errorHandler =
        this.nativeFileHelperService.createErrorHandler(observer);

      this.resolveFileSystemUrl(
        `${this.documentsBasePath}${folder}`,
        (directory) => {
          directory.getFile(
            fileName,
            { create: false },
            (fileEntry: FileEntry) => {
              observer.next(fileEntry);
              observer.complete();
            },
            errorHandler
          );
        },
        errorHandler
      );
    });
  }
}
