/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';
import { bind } from '@pcs/core/decorators';
import { AntivirusScanStatus } from '@pcs/core/enums';

import { from, mergeMap, Observable, of, Subject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

import { AlertService } from '@core/alert/alert.service';
import { StatusNotificationToastsService } from '@shared/status-notification-toasts/status-notification-toasts.service';
import {
  fileProcessingMessage,
  infectedFileError,
  passwordProtectedFileError,
} from '@workspace-documents/constants/file-download-errors.const';
import {
  fileNamePlaceholder,
  infectedFileAlertOptions,
  passwordProtectedAndWatermarkedAlertOptions,
  showAntivirusStatusSkippedOrFailedForDownload,
} from '@workspace-documents/constants/infected-file-alert-options.const';
import { FileDownloadStatusEnum } from '@workspace-documents/enums/file-download-status.enum';
import { DownloadResponse } from '@workspace-documents/models/download-response.model';

@Injectable({ providedIn: 'root' })
export class DownloadErrorsService {
  public loadingSubject: Subject<boolean> = new Subject();
  private skippedFilesIds: number[] = [];

  constructor(
    private alertService: AlertService,
    private statusNotificationToastsService: StatusNotificationToastsService
  ) {}

  public isFileInfected(antivirusScanStatus: AntivirusScanStatus): boolean {
    return antivirusScanStatus === AntivirusScanStatus.Infected;
  }

  public isMarkedAsSkippedForDownload(fileId: number): boolean {
    return this.skippedFilesIds.includes(fileId);
  }

  public markFileAsSkippedForDownload(fileId: number) {
    this.skippedFilesIds.push(fileId);
  }

  public isInfectedFileError(error: string): boolean {
    return error === infectedFileError;
  }

  public isPasswordProtectedError(error: string): boolean {
    return error === passwordProtectedFileError;
  }

  public showInfectedFileAlert(fileName: string): Observable<void> {
    return this.alertService
      .create({
        ...infectedFileAlertOptions,
        message: infectedFileAlertOptions.message.replace(
          fileNamePlaceholder,
          fileName
        ),
      })
      .pipe(map(() => null));
  }

  public showAntivirusStatusSkippedOrFailed(
    downloadResponse: DownloadResponse
  ): Observable<DownloadResponse> {
    this.loadingSubject.next(false);
    return this.alertService
      .create(showAntivirusStatusSkippedOrFailedForDownload)
      .pipe(
        map(this.alertService.isConfirmClicked),
        mergeMap((isConfirmed: boolean) => {
          if (isConfirmed) {
            this.loadingSubject.next(true);
            return of(downloadResponse);
          }

          return of(null);
        })
      );
  }

  public showPasswordProtectedAndWatermarkedErrorAlert(): void {
    this.alertService
      .create(passwordProtectedAndWatermarkedAlertOptions)
      .subscribe();
  }

  @bind
  public handleFileDownloadError(
    downloadResponse: DownloadResponse
  ): Observable<DownloadResponse> {
    const isPasswordProtectedError: boolean = this.isPasswordProtectedErrorResponse(
      downloadResponse
    );
    const isFileInfected: boolean = this.isFileInfected(
      downloadResponse.antivirusScanStatus
    );
    const isScanningForViruses: boolean = this.isScanningForViruses(
      downloadResponse.antivirusScanStatus
    );
    const isScannedForVirusesSkippedOrFailed: boolean = this.isScanningForVirusesSkippedOrFailed(
      downloadResponse.antivirusScanStatus
    );

    if (isScanningForViruses) {
      return this.showFileProcessingToast();
    }

    if (isPasswordProtectedError) {
      return throwError(() => passwordProtectedFileError);
    }

    if (isFileInfected) {
      return throwError(() => infectedFileError);
    }

    if (isScannedForVirusesSkippedOrFailed) {
      return this.showAntivirusStatusSkippedOrFailed(downloadResponse);
    }

    return of(downloadResponse);
  }

  public isScanningForVirusesSkippedOrFailed(
    antivirusScanStatus: AntivirusScanStatus
  ): boolean {
    const statuses = [AntivirusScanStatus.Skipped, AntivirusScanStatus.Failed];
    return statuses.includes(antivirusScanStatus);
  }

  private isPasswordProtectedErrorResponse(
    response: DownloadResponse
  ): boolean {
    const isErrorStatus: boolean = [
      FileDownloadStatusEnum.Error,
      FileDownloadStatusEnum.DownloadError,
    ].includes(response?.status);

    return isErrorStatus && response?.isPasswordProtected;
  }

  private showFileProcessingToast(): Observable<any> {
    return from(
      this.statusNotificationToastsService.showNotificationToast(
        fileProcessingMessage
      )
    );
  }

  private isScanningForViruses(
    antivirusScanStatus: AntivirusScanStatus
  ): boolean {
    return antivirusScanStatus === AntivirusScanStatus.Pending;
  }
}
