/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { type Observable, forkJoin, of as observableOf } from 'rxjs';
import { map, mergeMap, take, tap } from 'rxjs/operators';

import { type OngoingCompanyDetails } from '@core/company-data/ongoingCompanyDetails';
import { InternetConnectionService } from '@core/offline-core/internet-connection/internet-connection.service';
import { SkipLoaderOptionsService } from '@core/pcs-http/skip-loader-options.service';
import { type IGlobalStorage } from '@core/user-storage/global-storage.model';
import { GlobalStorageDataService } from '@core/user-storage/global-storage.service';
import { type PcsResponse } from '@shared/pcs-response.model';

import { setActiveOngoingWorkspace } from '../../workspace/ongoing/workspace/ongoing-workspace.actions';
import { type TransactionWorkspace } from '../../workspace/transaction/workspace/transaction-workspace.model';
import { OfflineDashboardContentService } from '../offline-core/dashboard/offline-dashboard-content.service';
import {
  setCompanies,
  setOngoingCompany,
  setTransactionWorkspaces,
} from './company-data.actions';
import { companiesSelector } from './company-data.reducers';
import { CompanyDataUrls } from './company-data-urls';

@Injectable()
export class CompanyDataService {
  constructor(
    private http: HttpClient,
    private store: Store<OngoingCompanyDetails[]>,
    private internetConnectionService: InternetConnectionService,
    private offlineDashboardContentService: OfflineDashboardContentService,
    private globalStorageDataService: GlobalStorageDataService
  ) {}

  public getCompanies(
    skipLoader?: boolean
  ): Observable<OngoingCompanyDetails[]> {
    if (this.internetConnectionService.isOffline()) {
      return this.offlineDashboardContentService
        .getCompanies()
        .pipe(tap((companies) => this.store.dispatch(setCompanies(companies))));
    }

    return this.getCompaniesOnline(skipLoader);
  }

  /* This method temporary duplicates getCompanies logic */
  public getOngoingCompanyByWorkspaceId(
    workspaceId: number,
    skipLoader?: boolean
  ): Observable<OngoingCompanyDetails> {
    const callUrl = CompanyDataUrls.getCompaniesUrl();
    let serverCall;

    if (skipLoader) {
      serverCall = this.http.get<PcsResponse<OngoingCompanyDetails>>(
        callUrl,
        SkipLoaderOptionsService.getSkipLoaderOptions()
      );
    } else {
      serverCall = this.http.get<PcsResponse<OngoingCompanyDetails>>(callUrl);
    }

    return serverCall.pipe(
      map((companiesResponse: PcsResponse<OngoingCompanyDetails>) =>
        this.onGetCompanies(companiesResponse)
      ),
      map(
        (companies: OngoingCompanyDetails[]) =>
          companies &&
          companies.find(
            (company) =>
              company.ongoings &&
              company.ongoings.some((workspace) => workspace.id === workspaceId)
          )
      ),
      tap((company: OngoingCompanyDetails) => {
        if (!company) {
          return;
        }

        const ongoingWorkspace = company.ongoings.find(
          (workspace) => workspace.id === workspaceId
        );

        this.store.dispatch(setActiveOngoingWorkspace(ongoingWorkspace));
      })
    );
  }

  public getOngoingCompany(
    companyId: string,
    forceGetCompany?: boolean
  ): Observable<OngoingCompanyDetails> {
    if (this.internetConnectionService.isOffline()) {
      return this.offlineDashboardContentService
        .getOngoingCompanyDetails(companyId)
        .pipe(
          map((companyDetails) => this.onGetOngoingCompany(companyDetails))
        );
    }

    if (forceGetCompany) {
      const skipLoader = true;
      return this.getOngoingCompanyOnline(companyId, skipLoader);
    }

    return this.getCompany(companyId, false);
  }

  public getTransactionWorkspaces(
    skipLoader?: boolean
  ): Observable<TransactionWorkspace[]> {
    if (this.internetConnectionService.isOffline()) {
      return this.offlineDashboardContentService
        .getTransactionDashboardItems()
        .pipe(
          tap((dashboardItems) =>
            this.onGetTransactionDashboardItems(dashboardItems)
          )
        );
    }
    return this.getTransactionDashboardItemsOnline(skipLoader);
  }

  public getTransactionWorkspace(
    workspaceId: number,
    skipLoader?: boolean
  ): Observable<TransactionWorkspace> {
    return this.getTransactionWorkspaces(skipLoader).pipe(
      map((transactionWorkspaces: TransactionWorkspace[]) =>
        transactionWorkspaces.find((workspace) => workspace.id === workspaceId)
      )
    );
  }

  public getCompany(
    companyId: string,
    queryAllCompanies = true
  ): Observable<OngoingCompanyDetails> {
    return this.store.select(companiesSelector).pipe(
      take(1),
      mergeMap((companies: OngoingCompanyDetails[]) => {
        if (!companies.length) {
          const skipLoader = true;

          return queryAllCompanies
            ? this.getCompanies(skipLoader)
            : this.getOngoingCompanyOnline(companyId, skipLoader).pipe(
                map((company) => [company])
              );
        }

        return observableOf(companies);
      }),
      map((companies) =>
        companies.find((company) => company && company.id === companyId)
      )
    );
  }

  private getOngoingCompanyOnline(companyId: string, skipLoader?: boolean) {
    const callUrl = CompanyDataUrls.getOngoingCompanyUrl(companyId);
    let serverCall: Observable<OngoingCompanyDetails>;

    if (skipLoader) {
      serverCall = this.http.get<OngoingCompanyDetails>(
        callUrl,
        SkipLoaderOptionsService.getSkipLoaderOptions()
      );
    } else {
      serverCall = this.http.get<OngoingCompanyDetails>(callUrl);
    }

    return forkJoin([serverCall, this.globalStorageDataService.fetch()]).pipe(
      map((response: [OngoingCompanyDetails, IGlobalStorage]) =>
        this.onGetOngoingCompany(response[0])
      )
    );
  }

  private getCompaniesOnline(
    skipLoader?: boolean
  ): Observable<OngoingCompanyDetails[]> {
    const callUrl = CompanyDataUrls.getCompaniesUrl();
    let serverCall: Observable<PcsResponse<OngoingCompanyDetails>>;

    if (skipLoader) {
      serverCall = this.http.get<PcsResponse<OngoingCompanyDetails>>(
        callUrl,
        SkipLoaderOptionsService.getSkipLoaderOptions()
      );
    } else {
      serverCall = this.http.get<PcsResponse<OngoingCompanyDetails>>(callUrl);
    }

    return forkJoin([serverCall, this.globalStorageDataService.fetch()]).pipe(
      map((response: [PcsResponse<OngoingCompanyDetails>, IGlobalStorage]) =>
        this.onGetCompanies(response[0])
      )
    );
  }

  private getTransactionDashboardItemsOnline(
    skipLoader?: boolean
  ): Observable<TransactionWorkspace[]> {
    const callUrl = CompanyDataUrls.getTransactionUrl();
    let serverCall;

    if (skipLoader) {
      serverCall = this.http.get<PcsResponse<TransactionWorkspace>>(
        callUrl,
        SkipLoaderOptionsService.getSkipLoaderOptions()
      );
    } else {
      serverCall = this.http.get<PcsResponse<TransactionWorkspace>>(callUrl);
    }

    return serverCall.pipe(
      map((workspacesResponse: PcsResponse<TransactionWorkspace>) => {
        if (!workspacesResponse || !workspacesResponse.items) {
          return [];
        }
        return workspacesResponse.items;
      }),
      tap((dashboardItems: TransactionWorkspace[]) =>
        this.onGetTransactionDashboardItems(dashboardItems)
      )
    );
  }

  private onGetCompanies(
    companiesResponse: PcsResponse<OngoingCompanyDetails>
  ): OngoingCompanyDetails[] {
    this.store.dispatch(setCompanies(companiesResponse.items));

    return companiesResponse.items;
  }

  private onGetOngoingCompany(
    companyResponse: OngoingCompanyDetails
  ): OngoingCompanyDetails {
    if (companyResponse) {
      this.store.dispatch(setOngoingCompany(companyResponse));
    }
    return companyResponse;
  }

  private onGetTransactionDashboardItems(
    dashboardItems: TransactionWorkspace[]
  ): void {
    this.store.dispatch(setTransactionWorkspaces(dashboardItems));
  }
}
