/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';
import { type TokenResponse } from '@openid/appauth';

import { type Observable, merge } from 'rxjs';
import {
  debounceTime,
  first,
  map,
  share,
  startWith,
  switchMap,
  take,
} from 'rxjs/operators';

import { OpenIdFetchService } from '@core/openId/src/services/open-id-fetch.service';
import { OpenIdObservablesService } from '@core/openId/src/services/open-id-observables.service';

import { AuthorizationRequestHandlerBase } from './AuthorizationRequestHandler.base';
import { getUser } from './OpenId.calculation';
import {
  type User,
  type UserProfile,
  APPROXIMATE_DELAY_BEFORE_OPENING_INAPP_BROWSER,
} from './OpenId.dictionary';

@Injectable()
export class OpenIdService {
  public clearStaleState$: Observable<void> =
    this.openIdObservablesService.clearStaleStateSink.asObservable().pipe(
      switchMap(() => this.authorizationHandler.clear()),
      share()
    );

  public signOut$: Observable<void> =
    this.openIdObservablesService.signOutUrl$.pipe(
      debounceTime(APPROXIMATE_DELAY_BEFORE_OPENING_INAPP_BROWSER),
      switchMap((url: string) => this.authorizationHandler.signOut(url)),
      switchMap(() => this.authorizationHandler.clear())
    );

  public userInfo$: Observable<User> = merge(
    this.openIdObservablesService.tokenResponse$.pipe(
      switchMap((responseToken) =>
        this.openIdFetchService
          .fetchUserInfo(responseToken)
          .pipe(
            map((userProfile: UserProfile) =>
              getUser(responseToken, userProfile)
            )
          )
      )
    ),
    this.openIdObservablesService.userInfoSink.asObservable()
  );

  public lastUser$: Observable<User> = this.userInfo$.pipe(
    startWith(undefined as User)
  );

  public updateAccessTokenByRefreshTokenError$: Observable<Error> =
    this.authorizationHandler.updateAccessTokenByRefreshTokenError$;

  constructor(
    protected authorizationHandler: AuthorizationRequestHandlerBase,
    private openIdObservablesService: OpenIdObservablesService,
    private openIdFetchService: OpenIdFetchService
  ) {
    this.init();
  }

  public signIn(): Observable<User> {
    this.openIdObservablesService.signInSink.next();

    return merge(this.userInfo$, this.authorizationHandler.signInError$).pipe(
      take(1)
    );
  }

  public clearStaleState(): Observable<void> {
    this.openIdObservablesService.clearStaleStateSink.next();
    return this.clearStaleState$;
  }

  public startSilentRenew(): void {
    this.openIdObservablesService.silentRenewAccessTokenSink.next(true);
  }

  public stopSilentRenew(): void {
    this.openIdObservablesService.silentRenewAccessTokenSink.next(false);
  }

  public signinSilent(): Observable<User> {
    this.openIdObservablesService.signInSilentSink.next();
    return this.userInfo$.pipe(first());
  }

  public signOut(): Observable<void> {
    this.openIdObservablesService.signOutSink.next();
    return merge(this.signOut$, this.authorizationHandler.signOutError$);
  }

  public stopSignIn(): void {
    this.authorizationHandler.stopSignIn();
  }

  public getTokenFromStorage(): Observable<TokenResponse> {
    return this.authorizationHandler.getTokenFromStorage();
  }

  private init(): void {
    this.openIdObservablesService.init();
    this.clearStaleState$.subscribe(() =>
      this.openIdObservablesService.userInfoSink.next(null)
    );
  }
}
