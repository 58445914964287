/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
export class WorkspaceVizulizationOptions {
  public workspaceId: number;
  public daysTimeframe: TimeFrameOption;
  public docsTimeframe: TimeFrameOption;
  public totalEventsTimeframe: TimeFrameOption;
  public daysViewByOption: ViewByOption;
  public docsViewByOption: ViewByOption;
  public totalEventsViewByOption: ViewByOption;
  public documentsAccessedOption: string;
}

export enum TimeFrameOption {
  LastDay = 'Yesterday',
  LastWeek = 'Last 7 days',
  LastMonth = 'Last 30 days',
  AllTime = 'All Time',
}

export enum ViewByOption {
  PermissionSet = 'Permission Set',
  Entity = 'Entity',
}

export const timeFrameOptions = [
  TimeFrameOption.LastDay,
  TimeFrameOption.LastWeek,
  TimeFrameOption.LastMonth,
  TimeFrameOption.AllTime,
];

export const viewByOptions = [ViewByOption.PermissionSet, ViewByOption.Entity];

export const DAYS_ACTIVE_CHART_NAME = 'Days Active';
export const UNIQUE_DOCUMENTS_CHART_NAME = 'Documents Accessed';
export const DOCUMENT_ACCESSED_CHART_NAMES = [
  UNIQUE_DOCUMENTS_CHART_NAME,
  'Percentage of Documents Accessed',
  '% of Documents Accessed',
];
