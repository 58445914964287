/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Location } from '@angular/common';
import { type HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

@Injectable()
export class TransactionConfidentialityNoticeHandlerService {
  private exception: string = 'ConfidentialityAgreement';
  private regExpBaseUrl: RegExp = /\/dashboard\/transactionDashboard/i;
  private regExpInTransaction: RegExp = /transactionDashboard\/(.+?)\//i;

  private navigationStartUrl: string;

  constructor(private router: Router, private location: Location) {
    this.router.events.subscribe((event: NavigationStart) => {
      if (event instanceof NavigationStart) {
        this.navigationStartUrl = event.url;
      }
    });
  }

  public canHandle(response: HttpErrorResponse): boolean {
    if (!(response && response.url)) {
      return false;
    }
    const isTransactionWorkspace: boolean =
      response.url.indexOf('transaction') !== -1;

    if (response.status !== 403 || !isTransactionWorkspace) {
      return false;
    }

    const responseData = response.error;

    if (responseData && responseData.exception) {
      return responseData.exception === this.exception;
    }
    return false;
  }

  public handle(): void {
    const navigationUrl = this.getNavigationUrl();
    const baseUrl = navigationUrl.match(this.regExpBaseUrl)[0];
    const workspaceId = navigationUrl.match(this.regExpInTransaction)[1];

    this.router.navigate([
      `${baseUrl}/${workspaceId}/confidentiality-notice`,
      {
        baseUrl,
        navigationUrl,
      },
    ]);
  }

  private getNavigationUrl(): string {
    if (this.navigationStartUrl) {
      return this.navigationStartUrl;
    }

    // We should build navigation URL from current location in case of reloading page
    return this.location.path();
  }
}
