/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';

import { type BaseParentModel } from '@workspace-documents/root-structure/base-parent.model';
import { type BaseRootDocumentModel } from '@workspace-documents/root-structure/base-root-document.model';

@Injectable()
export class RootStructureService {
  public findFolder<T extends BaseRootDocumentModel>(
    documents: T,
    folderId: number
  ): T {
    if (documents.id === folderId || isNaN(folderId)) {
      return documents;
    }

    if (!documents.children) {
      return;
    }

    let result = documents.children.find(
      (document: T) => document.id === folderId
    ) as T;

    if (!result) {
      let index = 0;
      while (!result && index < documents.children.length) {
        result = this.findFolder(documents.children[index], folderId) as T;
        index++;
      }
    }

    return result;
  }

  public findFiles<T extends BaseRootDocumentModel>(
    documents: T,
    fileIds: number[] = [],
    receivedResult?: T[]
  ): T[] {
    const result = receivedResult ? receivedResult : [];
    if (!fileIds.length) {
      return [];
    }

    if (!documents.children) {
      return [];
    }

    const partialResult = documents.children.filter(
      (document: T) => fileIds.indexOf(document.id) > -1
    ) as T[];
    result.push(...partialResult);

    if (result.length < fileIds.length) {
      let index = 0;
      while (
        result.length < fileIds.length &&
        index < documents.children.length
      ) {
        this.findFiles(documents.children[index], fileIds, result);
        index++;
      }
    }

    return result;
  }

  public findAllFilesIds<T extends BaseRootDocumentModel>(
    documents: T,
    receivedResult?: number[]
  ): number[] {
    const result = receivedResult ? receivedResult : [];
    if (!documents.children) {
      return [];
    }

    const partialResult = documents.children
      .filter((document: T) => document.type === 'File')
      .map((document: T) => document.id) as number[];
    result.push(...partialResult);

    let index = 0;
    while (index < documents.children.length) {
      this.findAllFilesIds(documents.children[index], result);
      index++;
    }

    return result;
  }

  // prettier-ignore
  public findFilesWithParents<T extends BaseRootDocumentModel>( //NOSONAR
    receivedDocuments: T,
    fileIds: number[] = [],
    receivedResult?: T[]
  ): T[] {
    const documents = {
      ...receivedDocuments,
      children: receivedDocuments?.children
        .map(document => ({ ...document })),
    };
    const result = receivedResult ? receivedResult : [];

    if (!fileIds.length || !documents.children) {
      return [];
    }

    if (!documents.parents) {
      documents.parents = [];
    }

    documents.children.forEach((document) => {
      document.parents = [];
      if (documents.parentId) {
        document.parents.push({
          name: documents.name,
          id: documents.id,
        });
      }
    });

    const partialResult = documents.children.filter(
      (document: T) => fileIds.indexOf(document.id) > -1
    ) as T[];
    result.push(...partialResult);

    if (result.length < fileIds.length) {
      let index = 0;
      while (
        result.length < fileIds.length &&
        index < documents.children.length
      ) {
        if (documents.children[index].parents.length <= 1) {
          documents.children[index].parents = documents.parents.concat(
            documents.children[index].parents
          );
        }
        this.findFilesWithParents(documents.children[index], fileIds, result);
        index++;
      }
    } else {
      documents.children.forEach((document: T) => {
        if (document.parents.length <= 1) {
          document.parents = documents.parents.concat(document.parents);
        }
      });
    }

    return result;
  }

  public findParents<T extends BaseRootDocumentModel>(
    documents: T,
    fileId: number,
    receivedResult?: BaseParentModel[]
  ): BaseParentModel[] {
    const result = receivedResult ? receivedResult : [];

    const root = this.findFolder(documents, fileId);

    if (root && root.parentId) {
      result.unshift({
        name: root.name,
        id: root.id,
      });
      this.findParents(documents, root.parentId, result);
    }

    return result;
  }

  public getOfflineRoot<T extends BaseRootDocumentModel>(
    documents: T,
    fileIds: number[] = []
  ): T {
    documents.children = this.filterData(
      documents.children as T[],
      (document) => fileIds.indexOf(document.id) > -1
    );
    return documents;
  }

  public getOfflineFolder<T extends BaseRootDocumentModel>(
    documents: T[],
    folderId: number
  ): T[] {
    let result = documents.find((document: T) => document.id === folderId) as T;

    if (!result) {
      let index = 0;
      while (!result && index < documents.length) {
        result = this.findFolder(documents[index], folderId) as T;
        index++;
      }
    }

    return result.children as T[];
  }

  public getParentIndex(inputIndex: string): string {
    if (!inputIndex) {
      return null;
    }

    const temp = inputIndex.split('.');
    temp.pop();

    return temp.join('.');
  }

  public getParentName<T extends BaseRootDocumentModel>(file: T): string {
    if (!file.parents.length) {
      return '';
    }
    return file.parents[file.parents.length - 1].name;
  }

  public getParentPath<T extends BaseRootDocumentModel>(file: T): string {
    const path = file.parents.reduce(
      (prev, curr) => `${prev}/${curr.name}`,
      ''
    );

    return `Documents${path}`;
  }

  public getFilePath<T extends BaseRootDocumentModel>(file: T): string {
    const path = this.getParentPath(file);

    return `${path}/${file.name}`;
  }

  public hasAnyFiles(root): boolean {
    return !!this.findAnyFile(root);
  }

  public isEmailedDocuments<T extends BaseRootDocumentModel>(
    documents: T,
    document: T
  ): boolean {
    return (
      document.name === 'Emailed Documents' &&
      !this.findFolder(documents, document.parentId).parentId
    );
  }

  public isPrivateDocuments<T extends BaseRootDocumentModel>(
    documents: T,
    document: T
  ): boolean {
    return (
      document.name === 'Private Documents' &&
      !this.findFolder(documents, document.parentId).parentId
    );
  }

  public isAnyFilesIncluded(fileIds = [], allFiles = []): boolean {
    return fileIds.some((fileId) => allFiles.includes(fileId));
  }

  public isAllFilesIncluded(fileIds = [], allFiles = []): boolean {
    return fileIds.every((fileId) => allFiles.includes(fileId));
  }

  private findAnyFile<T extends BaseRootDocumentModel>(document: T): T {
    if (document && document.type === 'File') {
      return document;
    }

    if (!document.children) {
      return;
    }

    let result = document.children.find((item: T) => item.type === 'File') as T;

    if (!result) {
      let index = 0;
      while (!result && index < document.children.length) {
        result = this.findAnyFile(document.children[index]) as T;
        index++;
      }
    }

    return result;
  }

  private filterData<T extends BaseRootDocumentModel>(
    data: T[],
    predicate
  ): T[] {
    // if no data is sent in, return null, otherwise transform the data
    return !data
      ? null
      : data.reduce((list, entry) => {
          let clone = null;
          if (predicate(entry)) {
            // if the object matches the filter, clone it as it is
            clone = { ...entry };
          } else if (![null, undefined].includes(entry.children)) {
            // if the object has childes, filter the list of children
            const children = this.filterData(entry.children, predicate);
            if (children.length > 0) {
              // if any of the children matches, clone the parent object, overwrite
              // the children list with the filtered list
              clone = { ...entry, children };
            }
          }
          // if there's a cloned object, push it to the output list
          if (!!clone) {
            list.push(clone);
          }
          return list;
        }, []);
  }
}
