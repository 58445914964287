/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { type OnInit, Component, ViewEncapsulation } from '@angular/core';
import { Device } from '@ionic-native/device/ngx';

import { CustomConfigService } from '@core/custom-config.service';
import {
  IPadProSeriesModels,
  IPhoneXSeriesModels,
} from '@core/device/device-manufacturer-models';
import {
  kSecAttrAccessibleKey,
  kSecAttrAccessibleWhenUnlockedThisDeviceOnly,
} from '@core/ios-keychain.attributes';
import { IOSKeychainService } from '@core/ios-keychain.service';
import { OrientationService } from '@core/orientation/orientation.service';
import { PcsPlatformService } from '@core/pcs-platform.service';

@Component({
  selector: 'pcs-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.scss', './app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  public isIphoneXSeries: boolean = false;
  public isIpadProSeries: boolean = false;
  public isMobileSite: boolean = false;

  constructor(
    private device: Device,
    private pcsPlatformService: PcsPlatformService,
    private orientationService: OrientationService,
    private iOSKeychainService: IOSKeychainService,
    private platform: PcsPlatformService,
    private customConfigService: CustomConfigService
  ) {}

  public ngOnInit(): void {
    this.isMobileSite = !this.pcsPlatformService.isOnDevice();

    this.platform.ready().then(() => {
      this.isIpadProSeries = IPadProSeriesModels.includes(this.device.model);
      this.isIphoneXSeries = IPhoneXSeriesModels.includes(this.device.model);
      this.isMobileSite = !this.pcsPlatformService.isOnDevice();
      this.orientationService.lockDeviceOrientation();
      this.initApplication();
    });
  }

  private initApplication() {
    if (this.pcsPlatformService.isOnDevice()) {
      this.customConfigService.init();
      this.iOSKeychainService.setAttr(
        kSecAttrAccessibleKey,
        kSecAttrAccessibleWhenUnlockedThisDeviceOnly
      );
    }
  }
}
