/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';
import { type ActivatedRouteSnapshot, type Resolve } from '@angular/router';

import { type Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { SessionStoreService } from '@core/auth/session/session-store.service';
import { WorkspaceType } from '@core/company-data/workspace-type';
import { type ConfidentialityAgreement } from '@core/confidentiality-notice/models/confidentiality-agreement.model';
import { ConfiAgreementStoreService } from '@core/confidentiality-notice-offline/confi-agreement-store.service';
import { OfflineConfiPreviewData } from '@core/confidentiality-notice-offline/offline-confi-preview.model';
import { type OfflinePreviewInfo } from '@core/offline-core/files/offline-preview-info.model';
import { PreviewOfflineStoreService } from '@core/offline-core/files/preview-offline-store.service';
import { OngoingConfidentialityNoticeInfoModel } from '@ongoing/confidentiality-notice/ongoing-confidentiality-notice-info.model';
import { FileService } from '@shared/files/file.service';
import { TransactionConfidentialityNoticeInfoModel } from '@tvdr/confidentiality-notice/transaction-confidentiality-notice-info.model';
import { type ConfidentialityNoticeInfoModel } from '@workspace-shared/confidentiality-notice/confidentiality-notice-info.model';

@Injectable()
export class OfflineConfidentialityAgreementResolver
  implements Resolve<OfflineConfiPreviewData>
{
  private confidentialityStorageFolder: string = 'NoCloud';
  private workspaceTypeRouteDataKey: string = 'workspaceType';

  constructor(
    private confiAgreementStoreService: ConfiAgreementStoreService,
    private fileOfflineStoreService: PreviewOfflineStoreService,
    private sessionStoreService: SessionStoreService,
    private fileService: FileService
  ) {}

  public resolve(
    activatedRouteSnapshot: ActivatedRouteSnapshot
  ): Observable<OfflineConfiPreviewData> {
    const workspaceId: string = activatedRouteSnapshot.params.id;
    const confiInfo: ConfidentialityNoticeInfoModel =
      this.getConfidentialityInfo(activatedRouteSnapshot, workspaceId);

    const agreementStoreKey: string = confiInfo.agreementStoreKey;
    let confidentialityAgreement: ConfidentialityAgreement;

    return this.confiAgreementStoreService
      .getAgreementByWorkspaceId(parseInt(workspaceId, 10), agreementStoreKey)
      .pipe(
        mergeMap((agreement: ConfidentialityAgreement) => {
          confidentialityAgreement = agreement;

          const fileId: string = String(workspaceId);
          return this.fileOfflineStoreService.getData(fileId);
        }),
        mergeMap((previewInfo: OfflinePreviewInfo) =>
          this.getFileForPreview(previewInfo)
        ),
        map((file: Blob) => {
          const offlineConfiPreviewData = new OfflineConfiPreviewData();

          offlineConfiPreviewData.file = file;
          offlineConfiPreviewData.confidentialityAgreement =
            confidentialityAgreement;

          return offlineConfiPreviewData;
        })
      );
  }

  private getConfidentialityInfo(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    workspaceId: string
  ): ConfidentialityNoticeInfoModel {
    const workspaceType: WorkspaceType =
      activatedRouteSnapshot.data[this.workspaceTypeRouteDataKey];
    const userId: string = this.sessionStoreService.getUserId();
    const isOngoingWorkspace: boolean =
      workspaceType === WorkspaceType.ONGOING_REPORT;

    if (isOngoingWorkspace) {
      return new OngoingConfidentialityNoticeInfoModel(userId, workspaceId);
    }

    return new TransactionConfidentialityNoticeInfoModel(userId, workspaceId);
  }

  private getFileForPreview(previewInfo: OfflinePreviewInfo): Observable<any> {
    const [fileName] = previewInfo.downloadedFilePaths;
    return this.fileService.readFileAsBlob(
      this.confidentialityStorageFolder,
      fileName
    );
  }
}
