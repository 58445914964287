/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';

import { type Observable, of as observableOf } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';

import { AnnotationsHttpService } from '@core/annotations/annotations-http.service';
import { type OfflineAnnotationsModel } from '@core/offline-core/annotations/offline-annotations.model';
import { OfflineAnnotationsStoreService } from '@core/offline-core/annotations/offline-annotations-store.service';
import { InternetConnectionService } from '@core/offline-core/internet-connection/internet-connection.service';
import { FileService } from '@shared/files/file.service';
import { StatusNotificationToastsService } from '@shared/status-notification-toasts/status-notification-toasts.service';

@Injectable()
export class OfflineAnnotationsReleaseService {
  private readonly storageFolder: string = 'NoCloud';

  constructor(
    private offlineAnnotationsStoreService: OfflineAnnotationsStoreService,
    private internetConnectionService: InternetConnectionService,
    private toastsService: StatusNotificationToastsService,
    private fileService: FileService,
    private annotationsHttpService: AnnotationsHttpService
  ) {}

  public setup(): void {
    // Try to release offline activities once we sign in
    this.offlineAnnotationsStoreService
      .getOfflineAnnotations()
      .subscribe((offlineAnnotations) =>
        this.releaseOfflineAnnotations(offlineAnnotations)
      );

    // Start listen internet up event to release offline activities
    this.internetConnectionService
      .startObserveInternetUp()
      .pipe(
        mergeMap(() =>
          this.offlineAnnotationsStoreService.getOfflineAnnotations()
        )
      )
      .subscribe((offlineAnnotations) =>
        this.releaseOfflineAnnotations(offlineAnnotations)
      );
  }

  public releaseOfflineAnnotations(
    offlineAnnotations: OfflineAnnotationsModel[]
  ): void {
    if (!offlineAnnotations?.length) {
      return;
    }

    if (this.internetConnectionService.isOffline()) {
      return;
    }

    const currentAnnotations: OfflineAnnotationsModel = offlineAnnotations[0];

    const uploadAnnotationsObservable: Observable<void> =
      this.releaseFortDocsAnnotations(currentAnnotations);

    uploadAnnotationsObservable
      .pipe(
        switchMap(() => {
          offlineAnnotations.shift();

          return this.offlineAnnotationsStoreService.saveOfflineAnnotations(
            offlineAnnotations
          );
        }),
        catchError(() => {
          currentAnnotations.errorsCount++;

          // When we failed 3 times then not release this activity
          if (currentAnnotations.errorsCount >= 5) {
            offlineAnnotations.shift();
            this.toastsService.showErrorToast('Annotations cannot be synced.');
            return this.offlineAnnotationsStoreService.saveOfflineAnnotations(
              offlineAnnotations
            );
          }

          return observableOf(null);
        })
      )
      .subscribe(() => {
        this.releaseOfflineAnnotations(offlineAnnotations);
      });
  }

  private releaseFortDocsAnnotations(
    offlineAnnotations: OfflineAnnotationsModel
  ): Observable<void> {
    if (!offlineAnnotations.annotationsLocalLink) {
      return this.annotationsHttpService.removeAnnotations(
        offlineAnnotations.url
      );
    }

    return this.fileService
      .readFileAsBlob(
        this.storageFolder,
        offlineAnnotations.annotationsLocalLink
      )
      .pipe(
        mergeMap((blob: Blob) =>
          this.annotationsHttpService.uploadAnnotations(
            offlineAnnotations.url,
            blob
          )
        )
      );
  }
}
