/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { AlertButtonRoles } from '@core/alert/alert-button-roles.enum';
import { type AlertOptions } from '@core/alert/alert-options.model';
import { type AntivirusScanningSkippedModalType } from '@workspace-documents/enums/antivirus-scanning-skipped-modal-type.enum';

const prismSupportEmail: string = 'PrismSupport@ihsmarkit.com';

export const fileNamePlaceholder: string = '[file_name]';
export const infectedFileAlertOptions: AlertOptions = {
  header: 'File is infected',
  cssClass: 'pcs-alert--infected-file',
  message: `Please be advised that <b>${fileNamePlaceholder}</b> was determined to contain a virus. The file will not be available to recipients to view or download. Please contact owner of the file to upload un-infected version or contact <a href="mailto:${prismSupportEmail}">${prismSupportEmail}</a> if you require further assistance.`,
  buttons: [
    {
      role: 'cancel',
      text: 'OK',
    },
  ],
};
export const passwordProtectedAndWatermarkedAlertOptions: AlertOptions = {
  header: 'Unable to Download File',
  message:
    'File is watermarked and password protected and therefore cannot be downloaded.',
  backdropDismiss: false,
  buttons: [
    {
      role: 'cancel',
      text: 'Cancel',
    },
  ],
};

export const showAntivirusStatusSkippedOrFailedForDownload: AlertOptions = {
  header: 'The file has not been checked for viruses',
  message:
    'We could not perform an antivirus scan of this file. Are you sure you want to download this file?',
  backdropDismiss: false,
  buttons: [
    {
      role: AlertButtonRoles.Confirm,
      text: 'Download',
    },
    {
      role: AlertButtonRoles.Cancel,
      text: 'Cancel',
    },
  ],
};

export const showAntivirusStatusSkippedOrFailedForPreview: AlertOptions = {
  header: 'The file has not been checked for viruses',
  message:
    'We could not perform an antivirus scan of this file. Are you sure you want to preview this file?',
  backdropDismiss: false,
  buttons: [
    {
      role: AlertButtonRoles.Confirm,
      text: 'Preview',
    },
    {
      role: AlertButtonRoles.Cancel,
      text: 'Cancel',
    },
  ],
};

export const antivirusScanningSkippedOptions: Record<
  keyof typeof AntivirusScanningSkippedModalType,
  AlertOptions
> = {
  Preview: showAntivirusStatusSkippedOrFailedForPreview,
  Download: showAntivirusStatusSkippedOrFailedForDownload,
};
