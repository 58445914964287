/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';

import {
  type Observable,
  forkJoin as observableForkJoin,
  from as observableFrom,
  of as observableOf,
} from 'rxjs';
import { concatMap, map, mapTo, max, mergeMap, tap } from 'rxjs/operators';

import { SessionStoreService } from '@core/auth/session/session-store.service';
import { type PcsOfflineMigrationHistory } from '@core/offline-core/migration/pcs-offline-migration-history.model';
import { PcsOfflineMigrationListService } from '@core/offline-core/migration/pcs-offline-migration-list.service';
import { type PcsOfflineMigrationScript } from '@core/offline-core/migration/pcs-offline-migration-script';
import { type StorageStrategyInterface } from '@core/storage/storage-strategy.interface';
import { StorageStrategyFactoryResolverService } from '@core/storage/storage-strategy-factory-resolver.service';

@Injectable()
export class PcsOfflineMigrationService {
  /* Offline migration have several limitations
    - versionNumber === -1 - it is a default value, real migration numbers starts from 0
    - migration versionNumber is generated automatically
    - migration scripts order matters
    - if migration script cannot be applied due to absence of data saved in such format, it means success
    - if migration fails due to some bad data, the whole migration flow is terminated
    */

  public migrations: PcsOfflineMigrationScript[] = [];
  private rootMigrationVersionNumber: number = -1;

  constructor(
    private pcsOfflineMigrationListService: PcsOfflineMigrationListService,
    private storageStrategyFactoryResolverService: StorageStrategyFactoryResolverService,
    private sessionStoreService: SessionStoreService
  ) {}

  public run(): Observable<void> {
    const storageStrategy =
      this.storageStrategyFactoryResolverService.getStorage();
    const userId = this.sessionStoreService.getUserId();

    const migrationConstructors =
      this.pcsOfflineMigrationListService.getMigrationConstructors();

    this.migrations = migrationConstructors.map((migration) => {
      const versionNumber = migration.versionNumber;
      return new migration.migrationConstructor(
        versionNumber,
        storageStrategy,
        userId
      );
    });

    if (this.migrations.length === 0) {
      return observableOf(null);
    }

    return this.createMigrationHistoryStorage(storageStrategy).pipe(
      mergeMap(() => this.getLatestMigrationVersion(storageStrategy)),
      mergeMap((latestMigrationVersionNumber: number) => {
        const migrationsWithHigherVersion = this.migrations.filter(
          (migration) => migration.versionNumber > latestMigrationVersionNumber
        );
        const isAnyMigrationRequired = migrationsWithHigherVersion.length > 0;
        if (isAnyMigrationRequired) {
          return this.runMigrationScripts(
            migrationsWithHigherVersion,
            storageStrategy
          );
        }
        return observableOf(null);
      }),
      tap(() => (this.migrations = []))
    );
  }

  private createMigrationHistoryStorage(
    storageStrategy: StorageStrategyInterface
  ): Observable<void> {
    return storageStrategy.get(this.getMigrationHistoryKey()).pipe(
      map((stringValue) => JSON.parse(stringValue)),
      map((migrationHistory) => !!migrationHistory),
      mergeMap((isMigrationHistoryExist: boolean) => {
        if (isMigrationHistoryExist) {
          return observableOf(null);
        }
        return storageStrategy.set(this.getMigrationHistoryKey(), [
          {
            versionNumber: this.rootMigrationVersionNumber,
            migratedAt: new Date().getTime(),
          },
        ]);
      })
    );
  }

  private getLatestMigrationVersion(
    storageStrategy: StorageStrategyInterface
  ): Observable<number> {
    return storageStrategy.get(this.getMigrationHistoryKey()).pipe(
      map((stringValue) => JSON.parse(stringValue)),
      map((migrationHistory: PcsOfflineMigrationHistory[]) =>
        migrationHistory.map(
          (migrationHistoryItem) => migrationHistoryItem.versionNumber
        )
      ),
      mergeMap((migrationVersionNumbers: number[]) =>
        observableFrom(migrationVersionNumbers)
      ),
      max()
    );
  }

  private runMigrationScripts(
    migrationsToRun: PcsOfflineMigrationScript[],
    storageStrategy: StorageStrategyInterface
  ): Observable<void> {
    const runningMigrations = observableFrom(migrationsToRun).pipe(
      concatMap((migration) =>
        migration.run().pipe(mapTo(migration.versionNumber))
      ),
      mergeMap((versionNumber: number) =>
        this.logAppliedMigration(versionNumber, storageStrategy)
      )
    );
    return observableForkJoin([runningMigrations]).pipe(mapTo(null));
  }

  private logAppliedMigration(
    versionNumber: number,
    storageStrategy: StorageStrategyInterface
  ): Observable<void> {
    return storageStrategy.get(this.getMigrationHistoryKey()).pipe(
      map((value) => JSON.parse(value)),
      mergeMap((migrationHistory: PcsOfflineMigrationHistory[]) => {
        if (migrationHistory) {
          return storageStrategy.set(this.getMigrationHistoryKey(), [
            ...migrationHistory,
            {
              versionNumber,
              migratedAt: new Date().getTime(),
            },
          ]);
        }
        return storageStrategy.set(this.getMigrationHistoryKey(), [
          {
            versionNumber,
            migratedAt: new Date().getTime(),
          },
        ]);
      }),
      mapTo(null)
    );
  }

  private getMigrationHistoryKey(): string {
    const userId = this.sessionStoreService.getUserId();
    return `${userId}::PCS_MIGRATION_HISTORY_KEY`;
  }
}
