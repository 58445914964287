<!--*
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 *-->
<header class="participant-details-header">
  <button
    *ngIf="!isEditMode"
    (click)="onClose()"
    class="participant-details-header__close-btn"
  >
    <i class="fas fa-times"></i>
  </button>

  <button
    *ngIf="isEditMode"
    (click)="onCancel()"
    class="participant-details-header__cancel-btn"
  >
    Cancel
  </button>

  <h2 *ngIf="!isEditMode" class="participant-details-header__title">
    {{ headerTitle }}
  </h2>

  <div class="participant-details-header__controls">
    <button
      *ngIf="!isEditMode && !isGroupsMode && isEditModeAvailable"
      (click)="onEdit()"
      class="participant-details-header__edit-btn"
    >
      <i class="fas fa-pencil-alt"></i>
    </button>

    <button
      *ngIf="isEditMode && isSaveAvailable"
      (click)="onSave()"
      class="participant-details-header__save-btn"
    >
      Save
    </button>

    <button
      *ngIf="isGroupsMode"
      (click)="onSave()"
      [ngClass]="{
        'participant-details-header__save-btn_disabled': isDisabled
      }"
      class="participant-details-header__save-btn"
    >
      Done
    </button>
  </div>
</header>
