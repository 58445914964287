/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Component, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalController } from '@ionic/angular';

import { type Observable, of as observableOf } from 'rxjs';
import { delay, mapTo, tap } from 'rxjs/operators';

import { PasscodeSetService } from '@core/security/passcode-set.service';
import { PasscodeValidationService } from '@core/security/passcode-validation.service';

import { type PasscodeActionResult } from '../shared/models/passcode-action-result.model';
import { type PasscodeStep } from '../shared/models/passcode-step.model';
import { passcodeCheckSteps } from '../shared/passcode-check-steps.constant';
import { PasscodeTypeStatuses } from '../shared/passcode-type-statuses.enum';

@Component({
  selector: 'pcs-passcode-change',
  styleUrls: ['./passcode-change.component.scss'],
  templateUrl: './passcode-change.component.html',
})
export class PasscodeChangeComponent {
  @ViewChild('form') public form: NgForm;
  public step: PasscodeStep = passcodeCheckSteps.initial;
  public previousStep: PasscodeStep;
  public passcodeErase: EventEmitter<null> = new EventEmitter();

  private firstAttemptNewPasscodeValue: string;
  private isValidPasscodeWasEntered: boolean = false;

  constructor(
    public viewCtrl: ModalController,
    private passcodeValidationService: PasscodeValidationService,
    private passcodeSetService: PasscodeSetService
  ) {}

  public onCancel() {
    const result: PasscodeActionResult = { isSuccess: false };
    this.viewCtrl.dismiss(result);
  }

  public onPasscodeFilled(passcodeValue: string): Observable<void> {
    /* Initially validate current passcode */
    if (!this.isValidPasscodeWasEntered) {
      return this.initiallyValidate(passcodeValue);
    }

    /* Go to "re-enter" passcode set step */
    if (!this.firstAttemptNewPasscodeValue) {
      this.firstAttemptNewPasscodeValue = passcodeValue;
      this.step = passcodeCheckSteps.reEnter;
      this.passcodeErase.emit();
      return observableOf(null);
    }

    /* Go to "success" passcode set step and close modal */
    const isReEnteredPasscodeValid: boolean =
      this.firstAttemptNewPasscodeValue === passcodeValue;
    if (isReEnteredPasscodeValid) {
      this.step = passcodeCheckSteps.success;

      const passcodeSetStream = this.passcodeSetService
        .setPasscode(passcodeValue)
        .pipe(delay(500));

      passcodeSetStream.subscribe(() => this.onSuccessPasscodeSet());

      return passcodeSetStream;
    }

    /* Go to "error" passcode set step */
    const previousStep: PasscodeStep = passcodeCheckSteps.reEnter;
    this.goToErrorStep(previousStep);

    return observableOf(null);
  }

  public onPasscodeFillStart(): void {
    if (this.step.status === PasscodeTypeStatuses.Error) {
      this.step = this.previousStep;
      this.previousStep = null;
    }
  }

  private initiallyValidate(passcodeValue: string): Observable<void> {
    const validationStream = this.passcodeValidationService
      .isValid(passcodeValue)
      .pipe(
        tap((isValid: boolean) => {
          /* Go to "enterNew" passcode set step */
          if (isValid) {
            this.step = passcodeCheckSteps.enterNew;
            this.isValidPasscodeWasEntered = true;
            this.passcodeErase.emit();

            return;
          }

          /* Go to "error" passcode set step */
          const previousStep: PasscodeStep = passcodeCheckSteps.initial;
          this.goToErrorStep(previousStep);
        }),
        mapTo(null)
      );
    validationStream.subscribe();

    return validationStream;
  }

  private onSuccessPasscodeSet(): void {
    const result: PasscodeActionResult = { isSuccess: true };
    this.viewCtrl.dismiss(result);
  }

  private goToErrorStep(previousStep: PasscodeStep): void {
    this.step = this.firstAttemptNewPasscodeValue
      ? passcodeCheckSteps.errorPasscodesNotMatch
      : passcodeCheckSteps.errorInvalidPasscode;
    this.previousStep = previousStep;
    this.passcodeErase.emit();
  }
}
