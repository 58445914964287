/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';
import { type FileExtension, helpers } from '@fortdocs-frontend-sdk/webviewer';

import { DownloadErrorsService } from '@workspace-documents/download/download-errors.service';
import { type BaseRootDocumentModel } from '@workspace-documents/root-structure/base-root-document.model';

@Injectable()
export class OfflineAvailabilityService {
  constructor(private downloadErrorsService: DownloadErrorsService) {}

  public isFileAvailableForOffline(document): boolean {
    if (!!document?.name) {
      const fileExtension: FileExtension = helpers.getFileExtension(
        document.name
      );

      return helpers.isPreviewSupported(fileExtension);
    }

    return false;
  }

  public findFilesUnavailableForOffline<T extends BaseRootDocumentModel>(
    documents: T,
    result: T[] = []
  ): T[] {
    if (!documents.children) {
      return [];
    }

    const partialResult = documents.children.filter(
      (document: BaseRootDocumentModel) => {
        const isAvailableForOffline = this.isFileAvailableForOffline(document);
        return (
          document.type === 'File' &&
          (!document.canBeDownloaded || !isAvailableForOffline)
        );
      }
    ) as T[];
    result.push(...partialResult);

    let index = 0;
    while (index < documents.children.length) {
      this.findFilesUnavailableForOffline(documents.children[index], result);
      index++;
    }
    return result;
  }

  public findAllFilesAvailableForOffline<T extends BaseRootDocumentModel>(
    documents: T,
    result: T[] = []
  ): T[] {
    if (!documents.children) {
      return [];
    }

    const partialResult = documents.children.filter(
      (document: BaseRootDocumentModel) => {
        const isAvailableForOffline = this.isFileAvailableForOffline(document);
        return (
          document.type === 'File' &&
          document.canBeDownloaded &&
          isAvailableForOffline
        );
      }
    ) as T[];
    result.push(...partialResult);

    let index = 0;
    while (index < documents.children.length) {
      this.findAllFilesAvailableForOffline(documents.children[index], result);
      index++;
    }

    return result;
  }

  public findNotSavedFilesAvailableForOffline<T extends BaseRootDocumentModel>(
    documents: T,
    receivedOfflineDocuments: T[],
    result: T[] = []
  ): T[] {
    const offlineDocuments = receivedOfflineDocuments
      ? receivedOfflineDocuments
      : [];
    if (!documents.children) {
      return [];
    }

    const partialResult = documents.children.filter(
      (document: BaseRootDocumentModel) => {
        const isDocumentAlreadySaved = !!offlineDocuments.filter(
          (offlineDocument) => offlineDocument.id === document.id
        ).length;
        const isAvailableForOffline = this.isFileAvailableForOffline(document);
        const isMarkedAsSkippedForDownload: boolean =
          this.downloadErrorsService.isMarkedAsSkippedForDownload(document.id);

        return (
          document.type === 'File' &&
          document.canBeDownloaded &&
          !isDocumentAlreadySaved &&
          !isMarkedAsSkippedForDownload &&
          isAvailableForOffline
        );
      }
    ) as T[];
    result.push(...partialResult);

    let index = 0;
    while (index < documents.children.length) {
      this.findNotSavedFilesAvailableForOffline(
        documents.children[index],
        offlineDocuments,
        result
      );
      index++;
    }

    return result;
  }

  public findSavedFiles<T extends BaseRootDocumentModel>(
    documents: T,
    receivedOfflineDocuments: T[],
    result: T[] = []
  ): T[] {
    const offlineDocuments = receivedOfflineDocuments
      ? receivedOfflineDocuments
      : [];
    if (!documents.children) {
      return [];
    }

    const partialResult = documents.children.filter(
      (document: BaseRootDocumentModel) => {
        const isDocumentAlreadySaved = !!offlineDocuments.filter(
          (offlineDocument) => offlineDocument.id === document.id
        ).length;
        return document.type === 'File' && isDocumentAlreadySaved;
      }
    ) as T[];
    result.push(...partialResult);

    let index = 0;
    while (index < documents.children.length) {
      this.findSavedFiles(documents.children[index], offlineDocuments, result);
      index++;
    }

    return result;
  }
}
