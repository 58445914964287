/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';

import { type Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { SessionStoreService } from '@core/auth/session/session-store.service';
import { BaseStorageService } from '@core/storage/base-storage.service';
import { StorageStrategyFactoryResolverService } from '@core/storage/storage-strategy-factory-resolver.service';
import { type ConfidentialityNoticeInfoModel } from '@workspace-shared/confidentiality-notice/confidentiality-notice-info.model';

import { ConfidentialityNoticeAction } from '../confidentiality-notice/models/confidentiality-notice-action.model';

/* !!! This does not have any KEY to store data, so it takes it from clients as a parameter. So, physically, ongoing and TVDR
 * data are stored separately but have the same logic */
@Injectable()
export class DeferredConfiActionsStoreService extends BaseStorageService<
  ConfidentialityNoticeAction[]
> {
  constructor(
    protected storageStrategyResolverService: StorageStrategyFactoryResolverService,
    private sessionStoreService: SessionStoreService
  ) {
    super(storageStrategyResolverService);
  }

  public saveDeferredAction(
    deferredAction: ConfidentialityNoticeAction,
    confiInfo: ConfidentialityNoticeInfoModel
  ): Observable<void> {
    const userId = deferredAction.userId;
    const isUserIdsValid = this.isUserIdValid(userId);

    if (!isUserIdsValid) {
      throw new Error(
        'DeferredConfiActionsStoreService:: Cannot saveDeferredAction due to incorrect userId'
      );
    }
    return this.getDeferredActions(confiInfo).pipe(
      map((storedDeferredActions: ConfidentialityNoticeAction[]) => {
        const hasStoredDeferredActions =
          storedDeferredActions && storedDeferredActions.length > 0;
        return hasStoredDeferredActions
          ? [...storedDeferredActions, deferredAction]
          : [deferredAction];
      }),
      mergeMap((newDeferredActions: ConfidentialityNoticeAction[]) =>
        super.set(confiInfo.offlineActionStoreKey, newDeferredActions)
      ),
      map(() => null)
    );
  }

  public getDeferredActions(
    confiInfo: ConfidentialityNoticeInfoModel
  ): Observable<ConfidentialityNoticeAction[]> {
    const userId = confiInfo && confiInfo.userId;
    if (!this.isUserIdValid(userId)) {
      throw new Error(
        'DeferredConfiActionsStoreService:: Cannot get deferred action due to invalidity of userId'
      );
    }
    return super
      .get(confiInfo.offlineActionStoreKey)
      .pipe(
        map((deferredActions: ConfidentialityNoticeAction[]) =>
          deferredActions
            ? deferredActions.map(
                (deferredAction) =>
                  new ConfidentialityNoticeAction(
                    deferredAction.workspaceId,
                    deferredAction.action,
                    deferredAction.agreementUploadedOn,
                    deferredAction.userId,
                    deferredAction.actualTimestamp,
                    deferredAction.sessionId
                  )
              )
            : null
        )
      );
  }

  public removeDeferredAction(
    deferredActionToRemove: ConfidentialityNoticeAction,
    confiInfo: ConfidentialityNoticeInfoModel
  ): Observable<void> {
    const userId = deferredActionToRemove.userId;
    const isUserIdsValid = this.isUserIdValid(userId);

    if (!isUserIdsValid) {
      throw new Error(
        'DeferredConfiActionsStoreService:: Cannot saveDeferredAction due to incorrect userId'
      );
    }

    return this.getDeferredActions(confiInfo).pipe(
      map((storedDeferredActions: ConfidentialityNoticeAction[]) =>
        storedDeferredActions.filter(
          (deferredAction) => !deferredAction.isEqual(deferredActionToRemove)
        )
      ),
      mergeMap((filteredDeferredAction: ConfidentialityNoticeAction[]) =>
        super.set(confiInfo.offlineActionStoreKey, filteredDeferredAction)
      ),
      map(() => null)
    );
  }

  public clearAll(confiInfo: ConfidentialityNoticeInfoModel): Observable<void> {
    const userId = confiInfo && confiInfo.userId;
    if (!this.isUserIdValid(userId)) {
      throw new Error(
        'DeferredConfiActionsStoreService:: Cannot remove deferred action due to invalidity of userId'
      );
    }

    return super.delete(confiInfo.offlineActionStoreKey);
  }

  private isUserIdValid(userId: string): boolean {
    const currentUserId: string = this.sessionStoreService.getUserId();

    return (!!userId && !!currentUserId) || userId === currentUserId;
  }
}
