/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { type Observable, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import {
  type IGlobalStorage,
  type IOgrStorage,
  ongoingGlobalStorageKey,
} from './global-storage.model';
import { GlobalStorageUrls } from './global-storage-data-urls';

@Injectable({
  providedIn: 'root',
})
export class GlobalStorageDataService {
  private readonly globalStorageSubject: BehaviorSubject<IGlobalStorage> =
    new BehaviorSubject(null);

  constructor(private httpClient: HttpClient) {}

  public get globalStorage$(): Observable<IGlobalStorage> {
    return this.globalStorageSubject.asObservable();
  }

  public fetch(keys?: string[]): Observable<IGlobalStorage> {
    const url: string = this.getUrl(keys);

    return this.httpClient.get<IGlobalStorage>(url).pipe(
      map(this.handleReceivedStorage.bind(this)),
      tap((globalStorage: IGlobalStorage) =>
        this.globalStorageSubject.next(globalStorage)
      )
    );
  }

  public getOgrStorageSync(): IOgrStorage {
    const storage = this.globalStorageSubject.getValue();

    if (!storage) {
      return null;
    }

    return storage.records.ogr;
  }

  private createSkeletonForStorage(
    globalStorage: IGlobalStorage
  ): IGlobalStorage {
    const storage: IGlobalStorage = { ...globalStorage };

    storage.records[ongoingGlobalStorageKey] = {
      companies: [],
    };

    return storage;
  }

  private parseReceivedStorage(globalStorage: IGlobalStorage): IGlobalStorage {
    const storage: IGlobalStorage = { ...globalStorage };

    Object.entries(storage.records).forEach(
      ([key, value]: [string, string]) => {
        if (!!key && !!value) {
          try {
            storage.records[key] = JSON.parse(value);
          } catch (error) {
            storage.records[key] = value;
          }
        }
      }
    );

    return storage;
  }

  private handleReceivedStorage(globalStorage: IGlobalStorage): IGlobalStorage {
    const isEmptyStorage: boolean = !Object.keys(globalStorage.records).length;

    return isEmptyStorage
      ? this.createSkeletonForStorage(globalStorage)
      : this.parseReceivedStorage(globalStorage);
  }

  private getUrl(keys?: string[]): string {
    let url: string = GlobalStorageUrls.getGlobalStorageUrl();

    if (!!keys && keys.length) {
      const queryParams: string = keys.map((key) => `keys=${key}`).join('&');

      url = `${url}?${queryParams}`;
    }

    return url;
  }
}
