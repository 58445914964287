/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';
import { TouchID } from '@ionic-native/touch-id/ngx';

import {
  type Observable,
  combineLatest as observableCombineLatest,
  defer as observableDefer,
  of as observableOf,
  throwError as observableThrowError,
} from 'rxjs';
import { catchError, map, mergeMap, retryWhen } from 'rxjs/operators';

import { BiometricIDAuthStoreService } from '@core/auth/biometric-id/biometric-id-auth-store.service';
import { BiometricIDNotSetForAuthException } from '@core/auth/biometric-id/biometric-id-not-set-for-auth.exception';
import { BiometricIDService } from '@core/biometric-id/biometric-id.service';
import { type BiometricIDError } from '@core/biometric-id/biometric-id-error.model';

import { BiometricIDCancelledByUserException } from './biometric-id-cancelled.exception';

@Injectable()
export class BiometricIDAuthVerifyService {
  constructor(
    private biometricIDAuthStoreService: BiometricIDAuthStoreService,
    private biometricIDService: BiometricIDService,
    private touchID: TouchID
  ) {}

  public verify(): Observable<boolean> {
    return this.checkBiometricAuthAvailability().pipe(
      mergeMap((isBiometricAuthAvailable) => {
        if (isBiometricAuthAvailable) {
          return this.verifyViaHardware();
        }
        return observableThrowError(
          () =>
            new BiometricIDNotSetForAuthException(
              'BiometricIDAuthService:: Unable to verify user with Biometric ID'
            )
        );
      })
    );
  }

  public checkBiometricAuthAvailability(): Observable<boolean> {
    return observableCombineLatest([
      this.biometricIDService.isAvailable(),
      this.biometricIDAuthStoreService.isTurnedOn(),
    ]).pipe(map(([isAvailable, isTurnedOn]) => isAvailable && isTurnedOn));
  }

  public verifyViaHardware(): Observable<boolean> {
    /*
         The plugin cordova-plugin-touch-id will reject for various reasons.
         Your app will most likely need to respond to the cases differently.

         Here is a list of some of the error codes:

         -1 - Fingerprint scan failed more than 3 times
         -2 or -128 - User tapped the 'Cancel' button
         -3 - User tapped the 'Enter Passcode' or 'Enter Password' button
         -4 - The scan was cancelled by the system (Home button for example)
         -6 - TouchID is not Available
         -8 - TouchID is locked out from too many tries
         */

    return observableDefer(() =>
      this.touchID.verifyFingerprint('Authenticate to "Ipreo Prism Portal"')
    ).pipe(
      map(() => true),
      retryWhen((errors: Observable<BiometricIDError>) =>
        errors.pipe(
          mergeMap((error) => {
            if (!error) {
              throw error;
            }
            const isThreeFailsInARow = error.code === -1;
            const isCancelledBySystem = error.code === -4;

            if (isThreeFailsInARow || isCancelledBySystem) {
              return observableOf(null);
            }

            throw error;
          })
        )
      ),
      catchError((error) => {
        if (!error) {
          throw error;
        }

        const isVerifyCancelled = error.code === -2 || error.code === -128;
        const isBiometricIDNotAvailable = error.code === -6;
        const isLockedOutFromTooManyTries = error.code === -8;

        if (isVerifyCancelled) {
          throw new BiometricIDCancelledByUserException(
            'BiometricIDAuthService:: Cancelled by user'
          );
        }

        if (isBiometricIDNotAvailable || isLockedOutFromTooManyTries) {
          return observableOf(false);
        }

        throw error;
      })
    );
  }
}
