/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import {
  type Observable,
  forkJoin as observableForkJoin,
  of as observableOf,
  throwError as observableThrowError,
} from 'rxjs';
import { catchError, map, mapTo, mergeMap, take } from 'rxjs/operators';

import {
  ongoingCompanySelector,
  transactionWorkspacesSelector,
} from '@core/company-data/company-data.reducers';
import { CompanyDataService } from '@core/company-data/company-data.service';
import { type OngoingCompanyDetails } from '@core/company-data/ongoingCompanyDetails';
import { type TransactionWorkspace } from '@tvdr/workspace/transaction-workspace.model';

import { type OngoingWorkspace } from '../../../workspace/ongoing/workspace/ongoing-workspace.model';
import { CompanyOfflineStoreService } from './company-offline-store.service';
import { OngoingOfflineDashboardStoreService } from './ongoing-offline-dashboard-store.service';
import { TransactionOfflineDashboardStoreService } from './transaction-offline-dashboard-store.service';

@Injectable()
export class OfflineDashboardSaveService {
  constructor(
    private companyOfflineStoreService: CompanyOfflineStoreService,
    private ongoingOfflineDashboardStoreService: OngoingOfflineDashboardStoreService,
    private transactionOfflineDashboardStoreService: TransactionOfflineDashboardStoreService,
    private companyDataService: CompanyDataService,
    private store: Store<any>
  ) {}

  public saveForOngoing(
    companyId: string,
    workspaceId: number
  ): Observable<void> {
    const companyStream = this.companyDataService.getCompany(companyId);
    const ongoingDashboardItemStream = this.getOngoingDashboardItem(
      companyId,
      workspaceId
    );

    return observableForkJoin([companyStream, ongoingDashboardItemStream]).pipe(
      mergeMap(([company, ongoingDashboardItem]) => {
        const saveCompany =
          this.companyOfflineStoreService.saveCompany(company);
        const saveOngoingDashboardItem =
          this.ongoingOfflineDashboardStoreService.saveDashboardItem(
            ongoingDashboardItem
          );
        return observableForkJoin([saveCompany, saveOngoingDashboardItem]);
      }),
      mapTo(null)
    );
  }

  public saveForTransaction(workspaceId: number): Observable<void> {
    return this.getTransactionDashboardItem(workspaceId).pipe(
      mergeMap((dashboardItem) =>
        this.transactionOfflineDashboardStoreService.saveDashboardItem(
          dashboardItem
        )
      ),
      mapTo(null)
    );
  }

  public removeTransactionDashboardItem(workspaceId: number): Observable<void> {
    return this.transactionOfflineDashboardStoreService.removeDashboardItemById(
      workspaceId
    );
  }

  public removeOngoingDashboardItem(workspaceId: number): Observable<void> {
    return this.ongoingOfflineDashboardStoreService.removeDashboardItemById(
      workspaceId
    );
  }

  public removeOngoingCompany(companyId: string): Observable<void> {
    return this.companyOfflineStoreService.removeCompany(companyId);
  }

  private getOngoingDashboardItem(
    companyId: string,
    workspaceId: number
  ): Observable<OngoingWorkspace> {
    return this.store.select(ongoingCompanySelector).pipe(
      take(1),
      mergeMap((ongoingCompanyDetails: OngoingCompanyDetails) => {
        if (
          !ongoingCompanyDetails ||
          ongoingCompanyDetails.id !== companyId ||
          !ongoingCompanyDetails.ongoings.find(
            (workspace) => workspace.id === workspaceId
          )
        ) {
          return this.companyDataService.getOngoingCompany(companyId);
        }
        return observableOf(ongoingCompanyDetails);
      }),
      map((ongoingCompanyDetails) => ongoingCompanyDetails.ongoings),
      catchError((error) => observableThrowError(error)),
      map((ongoingDashboardItems: OngoingWorkspace[]) =>
        ongoingDashboardItems.find((workspace) => workspace.id === workspaceId)
      )
    );
  }

  private getTransactionDashboardItem(
    workspaceId
  ): Observable<TransactionWorkspace> {
    return this.store.select(transactionWorkspacesSelector).pipe(
      take(1),
      mergeMap((transactionDashboardItems: TransactionWorkspace[]) => {
        if (!transactionDashboardItems) {
          const skipLoader = true;
          return this.companyDataService.getTransactionWorkspaces(skipLoader);
        }
        return observableOf(
          transactionDashboardItems as TransactionWorkspace[]
        );
      }),
      map((transactionDashboardItems) =>
        transactionDashboardItems.find(
          (dashboardItem: TransactionWorkspace) =>
            dashboardItem.id === workspaceId
        )
      )
    );
  }
}
