/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

import { CalendarModule } from 'ion2-calendar';

import { MinimizeHeaderDirectiveModule } from '@shared/minimize-header/minimize-header-directive.module';
import { DocumentsFilterModule } from '@workspace-documents/documents-filter/documents-filter.module';
import { DocumentsOptionsTabComponent } from '@workspace-documents/documents-options-tab/documents-options-tab.component';
import { DocumentsSortModule } from '@workspace-documents/documents-sort/documents-sort.module';
import { DocumentsTableHeadModule } from '@workspace-documents/documents-table-head/documents-table-head.module';
import { FileOptionModule } from '@workspace-documents/file-option/file-option.module';
import { DocumentsHeaderComponent } from '@workspace-documents/header/documents-header.component';
import { HighlightModule } from '@workspace-documents/highlight-query/highlight.module';
import { InputValidatorService } from '@workspace-documents/input-validator/input-validator.service';
import { ManageWorkspaceComponent } from '@workspace-documents/manage/manage-workspace/manage-workspace.component';
import { ManageWorkspaceModalService } from '@workspace-documents/manage/manage-workspace-modal.service';
import { ManageWorkspaceDismissModalService } from '@workspace-documents/manage/manage-workspace-modal-dismiss.service';
import { DocumentsNavItemComponent } from '@workspace-documents/navigation-panel/documents-nav-item/documents-nav-item.component';
import { MoreNavItemComponent } from '@workspace-documents/navigation-panel/more-nav-item/more-nav-item.component';
import { RootStructureService } from '@workspace-documents/root-structure/root-structure.service';

import { ManageWorkspaceSharedModule } from '../manage/manage-workspace-shared.module';
import { ModalHeaderModule } from '../modal-header/modal-header.module';
import { BlurOnEnterDirective } from './blur-on-enter/blur-on-enter.directive';
import { SearchFieldComponent } from './documents-search/search-field/search-field.component';
import { SearchSuggestionComponent } from './documents-search/search-suggestion/search-suggestion.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
    ReactiveFormsModule,
    CalendarModule,
    ModalHeaderModule,
    FormsModule,
    DocumentsFilterModule,
    MinimizeHeaderDirectiveModule,
    DocumentsTableHeadModule,
    HighlightModule,
    FileOptionModule,
    ManageWorkspaceSharedModule,
    DocumentsSortModule,
  ],
  exports: [
    BlurOnEnterDirective,
    SearchFieldComponent,
    SearchSuggestionComponent,
    DocumentsHeaderComponent,
    DocumentsNavItemComponent,
    DocumentsOptionsTabComponent,
    MoreNavItemComponent,
  ],
  declarations: [
    BlurOnEnterDirective,
    SearchFieldComponent,
    SearchSuggestionComponent,
    DocumentsHeaderComponent,
    ManageWorkspaceComponent,
    DocumentsNavItemComponent,
    DocumentsOptionsTabComponent,
    MoreNavItemComponent,
  ],
  providers: [
    RootStructureService,
    ManageWorkspaceModalService,
    ManageWorkspaceDismissModalService,
    InputValidatorService,
  ],
  entryComponents: [ManageWorkspaceComponent],
})
export class DocumentsModule {}
