/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import {
  type ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';

import { OfflineActivityReleaseService } from '@core/offline-core/activity/offline-activity-release.service';
import { OfflineActivityStoreService } from '@core/offline-core/activity/offline-activity-store.service';
import { OfflineAnnotationsReleaseService } from '@core/offline-core/annotations/offline-annotations-release.service';
import { OfflineAnnotationsStoreService } from '@core/offline-core/annotations/offline-annotations-store.service';
import { DashboardOfflineModule } from '@core/offline-core/dashboard/dashboard-offline.module';
import { OfflineAvailabilityService } from '@core/offline-core/files/offline-availability/offline-availability.service';
import { OfflineProcessingService } from '@core/offline-core/files/offline-processing.service';
import { PreviewOfflineStoreService } from '@core/offline-core/files/preview-offline-store.service';
import { OfflineSaveRetryService } from '@core/offline-core/files/retry/offline-save-retry.service';
import { OfflineSaveRetryConfirmService } from '@core/offline-core/files/retry/offline-save-retry-confirm.service';
import { InternetConnectionService } from '@core/offline-core/internet-connection/internet-connection.service';
import { PcsOfflineMigrationService } from '@core/offline-core/migration/pcs-offline-migration.service';
import { PcsOfflineMigrationListService } from '@core/offline-core/migration/pcs-offline-migration-list.service';

import { PasscodeTurnOffVerificationService } from './passcode/passcode-turn-off-verification.service';
import { PasscodeVerificationService } from './passcode/passcode-verification.service';

@NgModule({
  imports: [DashboardOfflineModule],
  declarations: [],
  exports: [],
  entryComponents: [],
  providers: [],
})
export class OfflineCoreModule {
  constructor(@Optional() @SkipSelf() parentModule: OfflineCoreModule) {
    if (parentModule) {
      throw new Error(
        'OfflineCoreModule is already loaded. Import it in the CoreModule only'
      );
    }
  }

  public static forRoot(): ModuleWithProviders<OfflineCoreModule> {
    return {
      ngModule: OfflineCoreModule,
      providers: [
        PasscodeVerificationService,
        PasscodeTurnOffVerificationService,
        PreviewOfflineStoreService,
        OfflineProcessingService,
        OfflineAvailabilityService,
        PcsOfflineMigrationService,
        PcsOfflineMigrationListService,
        OfflineActivityStoreService,
        OfflineActivityReleaseService,
        OfflineSaveRetryService,
        OfflineSaveRetryConfirmService,
        InternetConnectionService,
        OfflineAnnotationsReleaseService,
        OfflineAnnotationsStoreService,
      ],
    };
  }
}
