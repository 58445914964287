/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { type ManageWorkspaceAgreementType } from '@workspace-documents/manage/manage-workspace-agreement-type.enum';
import { type WorkspaceEmailedDocuments } from '@workspace-documents/manage/models/workspace-emailed-documents.model';
import { type WorkspaceUsageStatistic } from '@workspace-documents/manage/models/workspace-usage-statistic.model';

import { type TransactionWorkspaceSettings } from '../documents/shared/models/transaction-workspace-settings.model';
import { type TransactionWorkspaceStatus } from '../documents/shared/transaction-workspace-status';

export const transactionManageWorkspaceActions = {
  SET_WORKSPACE_SETTINGS: 'SET_TRANSACTION_WORKSPACE_SETTINGS',
  SET_WORKSPACE_SETTINGS_NAME: 'SET_TRANSACTION_WORKSPACE_SETTINGS_NAME',
  SET_WORKSPACE_SETTINGS_WATERMARK:
    'SET_TRANSACTION_WORKSPACE_SETTINGS_WATERMARK',
  SET_WORKSPACE_SETTINGS_NOTIFICATIONS:
    'SET_TRANSACTION_WORKSPACE_SETTINGS_NOTIFICATIONS',
  SET_WORKSPACE_SETTINGS_EMAILED_DOCUMENTS:
    'SET_TRANSACTION_WORKSPACE_SETTINGS_EMAILED_DOCUMENTS',
  SET_WORKSPACE_SETTINGS_STATUS: 'SET_TRANSACTION_WORKSPACE_SETTINGS_STATUS',
  SET_WORKSPACE_SETTINGS_STATISTIC:
    'SET_TRANSACTION_WORKSPACE_SETTINGS_STATISTIC',
  SET_WORKSPACE_SETTINGS_AGREEMENT:
    'SET_TRANSACTION_WORKSPACE_SETTINGS_AGREEMENT',
  REMOVE_WORKSPACE_SETTINGS: 'REMOVE_WORKSPACE_SETTINGS',
};

export const setTransactionWorkspaceSettings = (
  workspaceSettings: TransactionWorkspaceSettings
) => ({
  type: transactionManageWorkspaceActions.SET_WORKSPACE_SETTINGS,
  payload: workspaceSettings,
});

export const setTransactionWorkspaceSettingsName = (workspaceName: string) => ({
  type: transactionManageWorkspaceActions.SET_WORKSPACE_SETTINGS_NAME,
  payload: workspaceName,
});

export const setTransactionWorkspaceSettingsWatermark = (
  workspaceWatermarkStatus: boolean
) => ({
  type: transactionManageWorkspaceActions.SET_WORKSPACE_SETTINGS_WATERMARK,
  payload: workspaceWatermarkStatus,
});

export const setTransactionWorkspaceSettingsNotifications = (
  workspaceNotificationsStatus: boolean
) => ({
  type: transactionManageWorkspaceActions.SET_WORKSPACE_SETTINGS_NOTIFICATIONS,
  payload: workspaceNotificationsStatus,
});

export const setTransactionWorkspaceSettingsEmailedDocuments = (
  workspaceEmailedDocuments: WorkspaceEmailedDocuments
) => ({
  type: transactionManageWorkspaceActions.SET_WORKSPACE_SETTINGS_EMAILED_DOCUMENTS,
  payload: workspaceEmailedDocuments,
});

export const setTransactionWorkspaceSettingsStatus = (
  workspaceStatus: TransactionWorkspaceStatus
) => ({
  type: transactionManageWorkspaceActions.SET_WORKSPACE_SETTINGS_STATUS,
  payload: workspaceStatus,
});

export const setTransactionWorkspaceSettingsStatistic = (
  workspaceUsageStatistic: WorkspaceUsageStatistic
) => ({
  type: transactionManageWorkspaceActions.SET_WORKSPACE_SETTINGS_STATISTIC,
  payload: workspaceUsageStatistic,
});

export const setTransactionWorkspaceSettingsAgreement = (
  workspaceAgreementType: ManageWorkspaceAgreementType
) => ({
  type: transactionManageWorkspaceActions.SET_WORKSPACE_SETTINGS_AGREEMENT,
  payload: workspaceAgreementType,
});

export const removeTransactionWorkspaceSettings = () => ({
  type: transactionManageWorkspaceActions.REMOVE_WORKSPACE_SETTINGS,
});
