/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';

import {
  type Observable,
  combineLatest as observableCombineLatest,
  of as observableOf,
} from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { SessionStoreService } from '@core/auth/session/session-store.service';
import { ConfidentialityAgreementFrequency } from '@core/confidentiality-notice/enums/confidentiality-agreement-frequency.enum';
import { ConfiAgreementStoreService } from '@core/confidentiality-notice-offline/confi-agreement-store.service';
import { DeferredConfiActionsStoreService } from '@core/confidentiality-notice-offline/deferred-confi-actions-store.service';
import { ExecutedConfiActionsStoreService } from '@core/confidentiality-notice-offline/executed-confi-actions-store.service';
import { InternetConnectionService } from '@core/offline-core/internet-connection/internet-connection.service';
import { type ConfidentialityNoticeInfoModel } from '@workspace-shared/confidentiality-notice/confidentiality-notice-info.model';
import { PcsConfiIsNotRequiredException } from '@workspace-shared/pcs-exceptions/pcs-confi-is-no-required.exception';

import { UserActions } from '../confidentiality-notice/enums/confidentiality-notice.user-actions.enum';
import { type ConfidentialityAgreement } from '../confidentiality-notice/models/confidentiality-agreement.model';
import { type ConfidentialityNoticeAction } from '../confidentiality-notice/models/confidentiality-notice-action.model';

@Injectable()
export class ConfidentialityOfflineCheckService {
  private readonly agreementNotRequiredErrorDescription: string =
    'ConfidentialityOfflineCheckService:isRequired agreement is not required';

  constructor(
    private confiAgreementStoreService: ConfiAgreementStoreService,
    private internetConnectionService: InternetConnectionService,
    private deferredConfiActionsStoreService: DeferredConfiActionsStoreService,
    private executedConfiActionsStoreService: ExecutedConfiActionsStoreService,
    private sessionStoreService: SessionStoreService
  ) {}

  public isRequired(
    confiInfo: ConfidentialityNoticeInfoModel
  ): Observable<boolean> {
    if (!this.internetConnectionService.isOffline()) {
      const isConfidentialityAgreementRequired = false;
      return observableOf(isConfidentialityAgreementRequired);
    }

    const workspaceId = parseInt(confiInfo.workspaceId, 10);
    const agreementStoreKey = confiInfo.agreementStoreKey;

    return this.confiAgreementStoreService
      .getAgreementByWorkspaceId(workspaceId, agreementStoreKey)
      .pipe(
        mergeMap((confiAgreement: ConfidentialityAgreement) => {
          const hasAgreement = !!confiAgreement;
          if (!hasAgreement) {
            throw new PcsConfiIsNotRequiredException(
              this.agreementNotRequiredErrorDescription
            );
          }
          return observableCombineLatest([
            observableOf(confiAgreement),
            this.hasExecutedAcceptance(confiInfo, confiAgreement.uploadedOn),
          ]);
        }),
        mergeMap(
          ([confiAgreement, hasAcceptedOnline]: [
            ConfidentialityAgreement,
            boolean
          ]) => {
            const isOneTimeAgreement: boolean =
              this.isOneTimeAgreement(confiAgreement);
            if (hasAcceptedOnline || isOneTimeAgreement) {
              throw new PcsConfiIsNotRequiredException(
                this.agreementNotRequiredErrorDescription
              );
            }
            return this.hasAcceptedAgreementOffline(
              confiInfo,
              confiAgreement.uploadedOn
            ).pipe(map((hasAccepted) => !hasAccepted));
          }
        ),
        catchError((error) => {
          if (error instanceof PcsConfiIsNotRequiredException) {
            const isConfiRequired = false;
            return observableOf(isConfiRequired);
          }
          return observableOf(error);
        })
      );
  }

  private hasAcceptedAgreementOffline(
    confiInfo: ConfidentialityNoticeInfoModel,
    uploadedOn: string
  ): Observable<boolean> {
    const sessionId = this.sessionStoreService.getSessionId();
    return this.deferredConfiActionsStoreService
      .getDeferredActions(confiInfo)
      .pipe(
        map((deferredActions: ConfidentialityNoticeAction[]) => {
          const hasAcceptedAction =
            !!deferredActions &&
            deferredActions.some(
              (deferredAction) =>
                deferredAction.workspaceId === confiInfo.workspaceId &&
                deferredAction.action === UserActions.Accept &&
                deferredAction.agreementUploadedOn === uploadedOn &&
                deferredAction.sessionId === sessionId
            );
          return hasAcceptedAction;
        })
      );
  }

  private hasExecutedAcceptance(
    confiInfo: ConfidentialityNoticeInfoModel,
    uploadedOn: string
  ): Observable<boolean> {
    const sessionId = this.sessionStoreService.getSessionId();
    return this.executedConfiActionsStoreService.getActions(confiInfo).pipe(
      map((onlineActions: ConfidentialityNoticeAction[]) => {
        const hasAcceptedAction =
          !!onlineActions &&
          onlineActions.some(
            (onlineAction) =>
              onlineAction.workspaceId === confiInfo.workspaceId &&
              onlineAction.action === UserActions.Accept &&
              onlineAction.agreementUploadedOn === uploadedOn &&
              onlineAction.sessionId === sessionId
          );
        return hasAcceptedAction;
      })
    );
  }

  private isOneTimeAgreement(agreement: ConfidentialityAgreement): boolean {
    return agreement?.frequency === ConfidentialityAgreementFrequency.OneTime;
  }
}
