/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import {
  type OnDestroy,
  type OnInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { type Subscription } from 'rxjs';

import { PasscodeStep } from '../shared/models/passcode-step.model';
import { PasscodeTypeStatuses } from '../shared/passcode-type-statuses.enum';
import { type PasscodeKeyboardNumber } from './passcode-keyboard-number.model';
import { passcodeKeyboardNumbers } from './passcode-keyboard-numbers.constant';

@Component({
  selector: 'pcs-passcode-keyboard',
  styleUrls: [
    './passcode-keyboard.component.scss',
    './passcode-keyboard-number-button.scss',
    './passcode-display.scss',
  ],
  templateUrl: 'passcode-keyboard.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasscodeKeyboardComponent implements OnInit, OnDestroy {
  @Input() public passcodeErase: EventEmitter<null>;
  @Input() public step: PasscodeStep;
  @Output() public cancel: EventEmitter<null> = new EventEmitter();
  @Output() public passcodeFillStart: EventEmitter<null> = new EventEmitter();
  @Output() public passcodeFilled: EventEmitter<string> = new EventEmitter();

  public stepPossibleStatuses: typeof PasscodeTypeStatuses =
    PasscodeTypeStatuses;
  public passcodeKeyboardNumbers: PasscodeKeyboardNumber[] =
    passcodeKeyboardNumbers;
  public passcodeFilledDigitsCount: number = 0;

  private passcode: string = '';
  private passcodeEraseSubscription: Subscription;

  public ngOnInit(): void {
    if (this.passcodeErase) {
      this.passcodeEraseSubscription = this.passcodeErase.subscribe(() =>
        this.eraseAllDigits()
      );
    }
  }

  public ngOnDestroy(): void {
    if (this.passcodeEraseSubscription) {
      this.passcodeEraseSubscription.unsubscribe();
    }
  }

  public typeDigit(digit: number): void {
    if (!this.isValidDigit(digit)) {
      throw new Error(
        'PasscodeKeyboardComponent: typeDigit(): Cannot type non digit value into passcode (seems like error in template)'
      );
    }

    this.passcode = `${this.passcode}${digit}`;
    this.passcodeFilledDigitsCount = this.passcode.length;

    if (this.isPasscodeFull(this.passcode)) {
      /* Delay added to remove blicking between different steps of passcode typing
       * without it just after you type last digit, screen disappears immediately */
      setTimeout(() => this.passcodeFilled.emit(this.passcode), 100);
      return;
    }

    const isFirstDigitTyped: boolean = this.passcodeFilledDigitsCount === 1;
    if (isFirstDigitTyped) {
      this.passcodeFillStart.emit();
      return;
    }
  }

  public eraseDigit(): void {
    this.passcode = this.passcode.slice(0, -1);
    this.passcodeFilledDigitsCount = this.passcode.length;
  }

  public eraseAllDigits(): void {
    this.passcode = '';
    this.passcodeFilledDigitsCount = this.passcode.length;
  }

  public onCancel(): void {
    this.cancel.emit();
  }

  public onReject(): void {
    if (this.passcodeFilledDigitsCount > 0) {
      this.eraseDigit();
      return;
    }

    if (this.passcodeFilledDigitsCount === 0) {
      this.onCancel();
      return;
    }
  }

  public getRejectButtonText(): string {
    return this.passcodeFilledDigitsCount === 0 ? 'Cancel' : 'Delete';
  }

  private isPasscodeFull(passcode: string): boolean {
    return passcode.length === 4;
  }

  private isValidDigit(digit): boolean {
    return /^\d$/.test(digit);
  }
}
