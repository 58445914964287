<!--*
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 *-->
<ion-header>
  <pcs-participant-details-header
    (close)="onClose()"
    (save)="onApply()"
    [headerTitle]="'Entity'"
    [isDisabled]="isSaveDisabled()"
    [isGroupsMode]="true"
  >
  </pcs-participant-details-header>
</ion-header>

<ion-content class="user-entity__wrapper">
  <form
    *ngIf="isSearchAvailable || createEntityMode"
    class="user-entity__company"
    novalidate
    [formGroup]="formInput"
  >
    <ion-item class="pcs-item-with-input">
      <ion-label
        [ngClass]="{
          'user-entity__company-name user-entity__company-name_required': createEntityMode
        }"
        [position]="'stacked'"
      >
        Entity
      </ion-label>

      <ion-input
        formControlName="inputName"
        [(ngModel)]="companyName"
        [disabled]="
          companyMatchesFound || isSearchInProgress || isGetCountriesInProgress
        "
        (ionBlur)="onCompanyNameChange()"
        pcsClearInputCustom
        pcsControlErrors
        [errorsElement]="errorsTemplate"
        pcsSafeControlValidation
      >
      </ion-input>
    </ion-item>

    <span #errorsTemplate class="pcs-control-errors pl--16"></span>
  </form>

  <div
    class="user-entity__spinner"
    *ngIf="isSearchInProgress || isGetCountriesInProgress"
  >
    <pcs-spinner></pcs-spinner>
  </div>

  <div class="user-entity__error" *ngIf="!!error">
    <div class="user-entity__error-content" *ngIf="!!error">
      <span class="user-entity__error-icon">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
      </span>

      <span class="user-entity__error-message">
        {{ error }}
      </span>
    </div>
  </div>

  <div *ngIf="searchResults.length" class="user-entity__content">
    <h4 class="user-entity__sub-header pt--12 pb--12 pl--16">
      All Matches
    </h4>

    <ion-item *ngFor="let entity of searchResults" class="user-entity__item">
      <ion-label class="user-entity__title">
        {{ entity.companyName }}
      </ion-label>
      <ion-label class="user-entity__subtitle">
        {{ entity.country }}
      </ion-label>
      <ion-checkbox
        [(ngModel)]="entity.companyId === userCompanyId"
        (ngModelChange)="onUserEntitySelected(entity.companyId)"
      >
      </ion-checkbox>
    </ion-item>
  </div>

  <div
    class="user-entity__btn-wrapper"
    *ngIf="isSearchAvailable && !createEntityMode"
  >
    <button
      class="user-entity__search-btn"
      *ngIf="!error && !companyMatchesFound"
      [ngClass]="{
        'user-entity__search-btn_active': companyName && !isSearchInProgress
      }"
      (click)="onSearch(companyName)"
    >
      {{ isSearchInProgress ? 'Searching...' : 'Search' }}
    </button>

    <button
      class="user-entity__back-to-search-btn"
      [ngClass]="{
        'user-entity__back-to-search-btn_disabled':
          isGetCountriesInProgress || isSearchInProgress
      }"
      *ngIf="!!error || companyMatchesFound"
      (click)="onBackToSearch()"
    >
      Back To Search
    </button>
  </div>

  <div
    *ngIf="
      companyMatches.recent.length && !companyMatchesFound && !createEntityMode
    "
    class="user-entity__content"
  >
    <h4 class="user-entity__sub-header pt--12 pb--12 pl--16">
      Recent Entities
    </h4>

    <ion-item
      *ngFor="let entity of companyMatches.recent"
      class="user-entity__item"
    >
      <ion-label class="user-entity__title">
        {{ entity.companyName }}
      </ion-label>
      <ion-label class="user-entity__subtitle">
        {{ entity.country }}
      </ion-label>
      <ion-checkbox
        [(ngModel)]="entity.companyId === userCompanyId"
        (ngModelChange)="onUserEntitySelected(entity.companyId)"
      >
      </ion-checkbox>
    </ion-item>
  </div>

  <div
    *ngIf="companyMatches.all.length && !createEntityMode"
    class="user-entity__content"
  >
    <h4 class="user-entity__sub-header pt--12 pb--12 pl--16">
      All Matches
    </h4>

    <ion-item
      *ngFor="let entity of companyMatches.all"
      class="user-entity__item"
    >
      <ion-label class="user-entity__title">
        {{ entity.companyName }}
      </ion-label>
      <ion-label class="user-entity__subtitle">
        {{ entity.country }}
      </ion-label>
      <ion-checkbox
        [(ngModel)]="entity.companyId === userCompanyId"
        (ngModelChange)="onUserEntitySelected(entity.companyId)"
      >
      </ion-checkbox>
    </ion-item>
  </div>

  <div
    *ngIf="createEntityMode && !isGetCountriesInProgress"
    class="user-entity__content"
  >
    <h4 class="user-entity__sub-header pt--12 pb--12 pl--16">
      Address
    </h4>

    <pcs-select
      [label]="'Country'"
      [labelPosition]="'floating'"
      [bindLabel]="'name'"
      [bindValue]="'name'"
      [(ngModel)]="newEntity.country"
      [items]="countries"
      [required]="true"
      (ngModelChange)="onCountryChanged($event)"
    >
    </pcs-select>

    <pcs-editable-form-item
      [title]="'Street'"
      [content]="newEntity.street"
      (onValueChanged)="onStreetChanged($event)"
    >
    </pcs-editable-form-item>

    <pcs-editable-form-item
      [title]="'Zip'"
      [content]="newEntity.zip"
      (onValueChanged)="onZipChanged($event)"
    >
    </pcs-editable-form-item>

    <pcs-editable-form-item
      [title]="'City'"
      [content]="newEntity.city"
      (onValueChanged)="onCityChanged($event)"
    >
    </pcs-editable-form-item>

    <pcs-select
      [labelPosition]="'floating'"
      [label]="'State'"
      [disabled]="
        !country.provinces || (country.provinces && !country.provinces.length)
      "
      [(ngModel)]="newEntity.state"
      [items]="country?.provinces"
      [bindValue]="'name'"
      [bindLabel]="'name'"
    >
    </pcs-select>
  </div>

  <div
    class="user-entity__btn-wrapper"
    *ngIf="
      createEntityMode ||
      !isSearchAvailable ||
      (isSearchAvailable && (!!error || companyMatchesFound))
    "
  >
    <button
      class="user-entity__back-to-search-btn"
      [ngClass]="{
        'user-entity__back-to-search-btn_disabled':
          isGetCountriesInProgress || isSearchInProgress
      }"
      *ngIf="createEntityMode"
      (click)="onBackToSearch()"
    >
      Back To Search
    </button>
    <button
      class="user-entity__create-btn"
      [ngClass]="{
        'user-entity__create-btn_disabled':
          isGetCountriesInProgress || isSearchInProgress,
        'user-entity__create-btn_unique': !isSearchAvailable
      }"
      *ngIf="
        (!isSearchAvailable ||
          (isSearchAvailable && (!!error || companyMatchesFound))) &&
        !createEntityMode
      "
      (click)="onCreateNewEntity()"
    >
      Create New Entity
    </button>
  </div>
</ion-content>
