/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Inject, Injectable } from '@angular/core';
import { type TokenResponseJson, TokenResponse } from '@openid/appauth';

import {
  type Observable,
  of as observableOf,
  timer as observableTimer,
} from 'rxjs';
import { mapTo } from 'rxjs/operators';

import { CustomConfigService } from '@core/custom-config.service';
import {
  DEFAULT_TIME_TO_CHECK_TOKEN_TO_UPDATE_IN_SECONDS,
  HOW_OFTEN_CHECK_ACCESS_TOKEN_FOR_REFRESH,
  ID_TOKEN_HINT,
  MILLSEC_IN_SECONDS,
  OPEN_ID_CONFIG,
  OpenIdConfig,
} from '@core/openId/src/OpenId.dictionary';

@Injectable()
export class OpenIdHelperService {
  private readonly clientSecretKey: string = 'CLIENT_SECRET_SAM';

  constructor(
    @Inject(OPEN_ID_CONFIG) public config: OpenIdConfig,
    private configXMLService: CustomConfigService
  ) {}

  public getClientSecret(): string {
    return this.configXMLService.getConfigValueByKey(this.clientSecretKey);
  }

  public createTokenResponse(response: TokenResponseJson): TokenResponse {
    return new TokenResponse(response);
  }

  public getLogoutUri(
    endSessionEndpoint: string,
    tokenResponse: TokenResponse
  ) {
    const tokenHintPart: string =
      tokenResponse && tokenResponse.idToken
        ? `${ID_TOKEN_HINT}=${tokenResponse.idToken}&`
        : '';

    return `${endSessionEndpoint}?${tokenHintPart}`;
  }

  public defineSilentRenewAccessTokenInterval(
    silentRenew: boolean,
    token: TokenResponse
  ): Observable<TokenResponse> {
    if (silentRenew && token) {
      const tokenExpiresTime: number =
        token.expiresIn || DEFAULT_TIME_TO_CHECK_TOKEN_TO_UPDATE_IN_SECONDS;
      const timeToCheckTokenForRefresh: number = Math.floor(
        (tokenExpiresTime * MILLSEC_IN_SECONDS) /
          HOW_OFTEN_CHECK_ACCESS_TOKEN_FOR_REFRESH
      );

      return observableTimer(
        timeToCheckTokenForRefresh,
        timeToCheckTokenForRefresh
      ).pipe(mapTo(token));
    }

    return observableOf(undefined);
  }
}
