/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { createFeatureSelector } from '@ngrx/store';

import { type UnsafeAction } from '@core/ngrx-helper';

import { ongoingDocumentsReducers } from '../documents/reducers/ongoing-documents.reducers';
import { ongoingPreferencesReducer } from '../manage/ongoing-preferences.reducer';
import { ongoingNavPanelItemsReducer } from '../navigation-panel/ongoing-nav-panel.reducer';
import { ongoingActiveWorkspaceStatisticsReducer } from './ongoing-active-workspace-statistics/ongoing-active-workspace-statistics.reducer';
import { ongoingWorkspaceActions } from './ongoing-workspace.actions';
import { type OngoingWorkspace } from './ongoing-workspace.model';
import { ongoingActiveWorkspaceFeaturesReducer } from './ongoing-workspace-features/ongoing-active-workspace-features.reducer';
import { ongoingActiveWorkspacePermissionsReducer } from './ongoing-workspace-permissions/ongoing-active-workspace-permissions.reducer';
import { ongoingParticipantProfileReducer } from './participant-profile/ongoing-participant-profile.reducer';

const activeWorkspaceReducer = (
  state: OngoingWorkspace,
  action: UnsafeAction
) => {
  switch (action.type) {
    case ongoingWorkspaceActions.SET_ACTIVE_WORKSPACE:
      return { ...action.payload };
    case ongoingWorkspaceActions.SET_ACTIVE_WORKSPACE_NAME:
      const isWorkspaceSet = !!state;
      if (isWorkspaceSet) {
        const newWorkspaceName = action.payload;
        return { ...state, name: newWorkspaceName };
      }
      return state;
    default:
      return state;
  }
};

export const ongoingWorkspaceReducers = {
  ongoingActiveWorkspace: activeWorkspaceReducer,
  ongoingActiveWorkspaceStatistics: ongoingActiveWorkspaceStatisticsReducer,
  ongoingActiveWorkspacePermissions: ongoingActiveWorkspacePermissionsReducer,
  ongoingActiveWorkspaceFeatures: ongoingActiveWorkspaceFeaturesReducer,
  ongoingParticipantProfile: ongoingParticipantProfileReducer,
  ongoingActiveFolder: ongoingDocumentsReducers.ongoingActiveFolder,
  ongoingDocumentsCache: ongoingDocumentsReducers.ongoingDocumentsCache,
  ongoingWorkspaceSettings: ongoingDocumentsReducers.ongoingManageWorkspace,
  ongoingWorkspaceCustomization:
    ongoingDocumentsReducers.ongoingWorkspaceCustomization,
  ongoingParticipants: ongoingDocumentsReducers.ongoingParticipants,
  ongoingNavPanelItems: ongoingNavPanelItemsReducer,
  ongoingUserPreferences: ongoingPreferencesReducer,
  ongoingMeetings: ongoingDocumentsReducers.ongoingMeetings,
};

export const ongoingActiveWorkspaceSelector =
  createFeatureSelector<OngoingWorkspace>('ongoingActiveWorkspace');
