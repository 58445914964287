
export const environment = {
  environment: 'beta',
  BASE_ENDPOINT: 'https://beta.ipreoprism.com/mobile/api',
  ASSOCIATED_DOMAIN: 'beta.ipreoprism.com',
  appVersion: '2017.99.0',
  appStoreVersion: '1.0.0',
  developmentTeam: 'developmentTeam_mock',
  appBundleId: 'undefined',
  securityReportsEmail: 'undefined',
  enableSecurityChecks: 'undefined',
  ENABLE_LOGGING: '',
  AUTH_authority_SAM: 'https://sam.samexternal.net/sso/oauth2',
  AUTH_client_id_web_SAM: 'finance-pcmprism-webapp-uat-5Gi2ewAHSo',
  AUTH_client_id_native_SAM: 'finance-pcmprism-mobilenative-uat-Jp02G43ZtR',
  AUTH_redirect_uri_web_SAM: 'https://beta.ipreoprism.com/mobile/api/oauth2/callback',
  AUTH_redirect_uri_native_SAM: 'https://beta.ipreoprism.com/mobile/api/oauth2/callback-native',
  AUTH_post_logout_redirect_uri_web: 'https://mobile.beta.ipreoprism.com',
  LOGS_SECRET_KEY: 'q8Z3eWmX34KXYq6RyypaafSWs45Vw2JDHPphkTSc',
};
