/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { Device } from '@ionic-native/device/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
import { Network } from '@ionic-native/network/ngx';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { TouchID } from '@ionic-native/touch-id/ngx';
import { EffectsModule } from '@ngrx/effects';

import { authSAMSettingsNative } from '@core/auth/auth-settings-native';
import { authSAMSettingsWeb } from '@core/auth/auth-settings-web';
import { OPEN_ID_CONFIG_WEB } from '@core/auth/auth-settings-web-token';
import { BiometricIDModule } from '@core/biometric-id/biometric-id.module';
import { ConfidentialityNoticeModule } from '@core/confidentiality-notice/confidentiality-notice.module';
import { ConfidentialityNoticeOfflineModule } from '@core/confidentiality-notice-offline/confidentiality-notice-offline.module';
import { NativeHttpService } from '@core/native-http/native-http.service';
import { PcsNativeSettingsService } from '@core/native-settings/pcs-native-settings.service';
import { OpenIDModule } from '@core/openId/openId.module';
import { OPEN_ID_CONFIG } from '@core/openId/src/OpenId.dictionary';
import { PCSDateService } from '@core/pcs-date/pcs-date.service';
import { HttpParamsService } from '@core/pcs-http/http-params.service';
import { PcsPlatformService } from '@core/pcs-platform.service';
import { RouterHackService } from '@core/router-hack/router-hack.service';
import { StorageModule } from '@core/storage/storage.module';
import { UserFeaturesDataService } from '@core/user/user-features-data.service';
import { UserFeaturesStoreService } from '@core/user/user-features-store.service';
/* There is no way to put this logic inside of ongoing and don't put it here */
import { OngoingConfidentialityNoticeHandlerModule } from '@ongoing/confidentiality-notice/confidentilaity-notice-interceptor/ongoing-confidentiality-notice-handler.module';
import { FakeFileStrategyService } from '@shared/files/fake-file-strategy.service';
import { FileService } from '@shared/files/file.service';
import { FileStrategyResolverService } from '@shared/files/file-strategy-resolver.service';
import { NativeFileStrategyService } from '@shared/files/native-file-strategy.service';
import { AppVersionMock } from '@shared/pcs-app-version/app-version-mock.service';
import { PcsAppVersion } from '@shared/pcs-app-version/pcs-app-version.service';
import { PcsUniversalLinksParser } from '@shared/pcs-universal-links-parser';
import { SpinnerAppStartingService } from '@shared/spinners/spinner-app-starting/spinner-app-starting.service';
import { StatusNotificationToastsService } from '@shared/status-notification-toasts/status-notification-toasts.service';
import { UpdateAppService } from '@shared/update-app/update-app.service';
import { TransactionConfidentialityNoticeHandlerModule } from '@tvdr/confidentiality-notice/confidentilaity-notice-handler-interceptor/transaction-confidentiality-notice-handler.module';

import { AuthModule } from './auth/auth.module';
import { CompanyDataService } from './company-data/company-data.service';
import { DocumentNodesService } from './document-node/document-node.service';
import { FileNameHelperService } from './documents/file-name-helper.service';
import { OfflineCoreModule } from './offline-core/offline-core.module';
import { SetupModule } from './setup/setup.module';

@NgModule({
  imports: [
    HttpClientModule,
    AuthModule,
    SetupModule,
    CommonModule,
    ConfidentialityNoticeModule.forRoot(),
    ConfidentialityNoticeOfflineModule.forRoot(),
    OfflineCoreModule.forRoot(),
    OngoingConfidentialityNoticeHandlerModule.forRoot(),
    TransactionConfidentialityNoticeHandlerModule.forRoot(),
    StorageModule.forRoot(),
    BiometricIDModule,
    EffectsModule.forRoot([]),
    OpenIDModule,
  ],
  providers: [
    CompanyDataService,
    Network,
    Keyboard,
    Device,
    TouchID,
    OpenNativeSettings,
    SocialSharing,
    RouterHackService,
    HttpParamsService,
    PcsNativeSettingsService,
    UserFeaturesDataService,
    DocumentNodesService,
    FileNameHelperService,
    PCSDateService,
    ScreenOrientation,
    LaunchNavigator,
    UserFeaturesStoreService,
    HTTP,
    NativeHttpService,
    PcsPlatformService,
    PcsUniversalLinksParser,
    PcsAppVersion,
    AppVersionMock,
    UpdateAppService,
    FileService,
    FakeFileStrategyService,
    NativeFileStrategyService,
    FileStrategyResolverService,
    SpinnerAppStartingService,
    StatusNotificationToastsService,
    {
      provide: OPEN_ID_CONFIG_WEB,
      useValue: authSAMSettingsWeb,
    },
    {
      provide: OPEN_ID_CONFIG,
      useValue: authSAMSettingsNative,
    },
  ],
})
export class CoreModule {}
