/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { type OnDestroy, Injectable } from '@angular/core';

import {
  type Observable,
  type Subscription,
  forkJoin as observableForkJoin,
  merge as observableMerge,
  of as observableOf,
  throwError as observableThrowError,
} from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { SessionEventEmitterService } from '@core/auth/session/session-event-emitter.service';
import { SessionStoreService } from '@core/auth/session/session-store.service';
import { UserActions } from '@core/confidentiality-notice/enums/confidentiality-notice.user-actions.enum';
import { ConfidentialityNoticeAction } from '@core/confidentiality-notice/models/confidentiality-notice-action.model';
import { DeferredConfiActionReleaseService } from '@core/confidentiality-notice-offline/deferred-confi-action-release.service';
import { DeferredConfiActionsStoreService } from '@core/confidentiality-notice-offline/deferred-confi-actions-store.service';
import { ExecutedConfiActionsStoreService } from '@core/confidentiality-notice-offline/executed-confi-actions-store.service';
import { InternetConnectionService } from '@core/offline-core/internet-connection/internet-connection.service';
import { type Session } from '@core/shared/session.model';

import { TransactionConfiNavigationService } from '../transaction-confi-navigation.service';
import { TransactionConfidentialityNoticeInfoModel } from '../transaction-confidentiality-notice-info.model';

@Injectable({
  providedIn: 'root',
})
export class TransactionDeferredConfiHandler implements OnDestroy {
  private NO_DEFERRED_ACTIONS_TO_HANDLE_ERROR: string =
    'NO_DEFERRED_ACTIONS_TO_HANDLE_ERROR';
  private revealDeferredConfiActionsSubscription: Subscription;
  private cleanOnlineStoredActionSubscription: Subscription;

  constructor(
    private transactionConfiNavigationService: TransactionConfiNavigationService,
    private deferredConfiActionsStoreService: DeferredConfiActionsStoreService,
    private executedConfiActionsStoreService: ExecutedConfiActionsStoreService,
    private sessionStoreService: SessionStoreService,
    private internetConnectionService: InternetConnectionService,
    private sessionEventEmitterService: SessionEventEmitterService,
    private deferredConfiActionReleaseService: DeferredConfiActionReleaseService
  ) {}

  public setup(): void {
    const startSessionEvents =
      this.sessionEventEmitterService.getStartSessionEvents();
    const internetUpEvents =
      this.internetConnectionService.startObserveInternetUp();

    this.revealDeferredConfiActionsSubscription = observableMerge(
      startSessionEvents,
      internetUpEvents
    )
      .pipe(mergeMap(() => this.revealDeferredConfiActions()))
      .subscribe();

    this.cleanOnlineStoredActionSubscription = startSessionEvents
      .pipe(
        mergeMap((newSession) => this.clearExecutedStoredActions(newSession))
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.stopAtemptingToRevealDeferredActions();
  }

  public handleAccept(
    workspaceId: string,
    agreementUploadedOn: string
  ): Observable<void> {
    const sessionId = this.sessionStoreService.getSessionId();
    const userId = this.sessionStoreService.getUserId();
    const actualTimestamp = new Date().toISOString();
    const deferredAction = new ConfidentialityNoticeAction(
      workspaceId,
      UserActions.Accept,
      agreementUploadedOn,
      userId,
      actualTimestamp,
      sessionId
    );
    const confiInfo = new TransactionConfidentialityNoticeInfoModel(
      userId,
      workspaceId
    );

    return this.deferredConfiActionsStoreService
      .saveDeferredAction(deferredAction, confiInfo)
      .pipe(
        mergeMap(() =>
          this.transactionConfiNavigationService.navigateToWorkspaceOffline(
            workspaceId
          )
        ),
        map(() => null)
      );
  }

  public handleCancel(
    workspaceId: string,
    agreementUploadedOn: string
  ): Observable<void> {
    const userId = this.sessionStoreService.getUserId();
    const sessionId = this.sessionStoreService.getSessionId();
    const actualTimestamp = new Date().toISOString();
    const deferredAction = new ConfidentialityNoticeAction(
      workspaceId,
      UserActions.Decline,
      agreementUploadedOn,
      userId,
      actualTimestamp,
      sessionId
    );
    const confiInfo = new TransactionConfidentialityNoticeInfoModel(
      userId,
      workspaceId
    );

    return this.deferredConfiActionsStoreService
      .saveDeferredAction(deferredAction, confiInfo)
      .pipe(
        mergeMap(() =>
          this.transactionConfiNavigationService.navigateToCompanyDashboard()
        ),
        map(() => null)
      );
  }

  public revealDeferredConfiActions(): Observable<void | void[]> {
    const userId = this.sessionStoreService.getUserId();
    const confiInfo = new TransactionConfidentialityNoticeInfoModel(userId);

    return this.deferredConfiActionsStoreService
      .getDeferredActions(confiInfo)
      .pipe(
        mergeMap((deferredActions) => {
          if (deferredActions) {
            return observableForkJoin(
              deferredActions.map((deferredAction) => {
                const confiInfoDeferred =
                  new TransactionConfidentialityNoticeInfoModel(
                    userId,
                    deferredAction.workspaceId
                  );
                return this.deferredConfiActionReleaseService.releaseDeferredConfiAction(
                  confiInfoDeferred,
                  deferredAction
                );
              })
            );
          }
          return observableThrowError(this.NO_DEFERRED_ACTIONS_TO_HANDLE_ERROR);
        }),
        catchError((error) => {
          if (error === this.NO_DEFERRED_ACTIONS_TO_HANDLE_ERROR) {
            return observableOf(null);
          }
          return observableThrowError(error);
        })
      );
  }

  public stopAtemptingToRevealDeferredActions(): void {
    if (this.revealDeferredConfiActionsSubscription) {
      this.revealDeferredConfiActionsSubscription.unsubscribe();
    }

    if (this.cleanOnlineStoredActionSubscription) {
      this.cleanOnlineStoredActionSubscription.unsubscribe();
    }
  }

  private clearExecutedStoredActions(newSession: Session): Observable<void> {
    const userId = this.sessionStoreService.getUserId();
    const confiInfo = new TransactionConfidentialityNoticeInfoModel(userId);
    return this.executedConfiActionsStoreService.getActions(confiInfo).pipe(
      map(
        (actions: ConfidentialityNoticeAction[]) =>
          !!actions &&
          actions.every((action) => action.sessionId !== newSession.id)
      ),
      mergeMap((allActionsHaveExpiredSessionId) =>
        allActionsHaveExpiredSessionId
          ? this.executedConfiActionsStoreService.clearAll(confiInfo)
          : observableOf(null)
      )
    );
  }
}
