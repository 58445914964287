/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { type HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  type InAppBrowserEvent,
  InAppBrowser,
} from '@awesome-cordova-plugins/in-app-browser/ngx';
import {
  type AuthorizationRequestResponse,
  type AuthorizationServiceConfiguration,
  AuthorizationError,
  AuthorizationNotifier,
  AuthorizationRequest,
  AuthorizationRequestHandler,
  AuthorizationResponse,
  BaseTokenRequestHandler,
  BasicQueryStringUtils,
  DefaultCrypto,
  GRANT_TYPE_AUTHORIZATION_CODE,
  GRANT_TYPE_REFRESH_TOKEN,
  TokenRequest,
  TokenResponse,
} from '@openid/appauth';

import {
  type Observable,
  EMPTY,
  from as observableFrom,
  of as observableOf,
  Subject,
  throwError as observableThrowError,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
} from 'rxjs/operators';

import { AngularRequestor } from './AngularRequestor';
import {
  getAuthorizationRequestKey,
  getAuthorizationServiceConfigurationKey,
  getRandomNumber,
} from './OpenId.calculation';
import {
  type SignInBrowserState,
  type User,
  ACCESS_TOKEN_REQUEST_ERROR,
  ACCESS_TOKEN_UPDATE_ERROR,
  AUTH_CODE_KEY,
  AUTHORIZATION_REQUEST_ERROR,
  AUTHORIZATION_REQUEST_HANDLE_KEY,
  AUTHORIZATION_RESPONSE_KEY,
  AuthorizationResponseParsed,
  OpenIdConfig,
  TOKEN_RESPONSE_KEY,
  UNEXPECTED_BROWSER_CLOSING_ERROR,
} from './OpenId.dictionary';
import { StorageService } from './storage-service';

@Injectable()
abstract class AuthorizationRequestHandlerBase extends AuthorizationRequestHandler {
  public onSignInErrorSink: Subject<void> = new Subject<void>();
  public responseAuthorizationCodeSink: Subject<string> = new Subject<string>();

  public updateAccessTokenByRefreshTokenErrorSink: Subject<Error> =
    new Subject<Error>();

  public authorizationCode$: Observable<string> =
    this.responseAuthorizationCodeSink // tslint:disable-line:member-ordering
      .asObservable()
      .pipe(
        filter((v) => !!v),
        distinctUntilChanged()
      );
  // tslint:disable-next-line:member-ordering
  public updateAccessTokenByRefreshTokenError$: Observable<Error> =
    this.updateAccessTokenByRefreshTokenErrorSink.asObservable();

  public signInError$: Observable<User> = this.onSignInErrorSink // tslint:disable-line:member-ordering
    .asObservable()
    .pipe(
      switchMap(() =>
        observableThrowError(() => new Error(UNEXPECTED_BROWSER_CLOSING_ERROR))
      )
    );

  public signOutError$: Observable<void>;

  protected onSignOutErrorSink: Subject<InAppBrowserEvent> =
    new Subject<InAppBrowserEvent>();
  protected notifier: AuthorizationNotifier;

  constructor(
    // use the provided storage backend
    // or initialize local storage with the default storage backend which
    // uses window.localStorage
    protected requestor: AngularRequestor,
    protected config: OpenIdConfig,
    // @ts-ignore
    protected iab: InAppBrowser,
    protected storage: StorageService,
    public utils: BasicQueryStringUtils = new BasicQueryStringUtils(),
    protected crypto: DefaultCrypto = new DefaultCrypto()
  ) {
    super(utils, crypto);

    this.signOutError$ = this.onSignOutErrorSink //tslint:disable-line:member-ordering
      .asObservable()
      .pipe(
        switchMap(() =>
          observableThrowError(
            () => new Error(UNEXPECTED_BROWSER_CLOSING_ERROR)
          )
        )
      );
  }

  public async performAuthorizationRequest(
    configuration: AuthorizationServiceConfiguration,
    request: AuthorizationRequest
  ): Promise<void> {
    const handle: string = this.crypto.generateRandom(1);

    const requestJSONObject = await request.toJson();

    const persisted: Promise<void[]> = Promise.all([
      this.storage.setItem(AUTHORIZATION_REQUEST_HANDLE_KEY, handle),
      this.storage.setItem(
        getAuthorizationRequestKey(handle),
        JSON.stringify(requestJSONObject)
      ),
      this.storage.setItem(
        getAuthorizationServiceConfigurationKey(handle),
        JSON.stringify(configuration.toJson())
      ),
    ]);

    await persisted;

    const url: any = this.buildRequestUrl(configuration, request);
    this.signIn(url);
  }

  public async prepareAuthorizationRequestResponse(
    request: AuthorizationRequest,
    { error, state, code, error_description }: AuthorizationResponseParsed,
    key: string
  ): Promise<AuthorizationRequestResponse> {
    let authorizationResponse: AuthorizationResponse;
    let authorizationError: AuthorizationError;

    if (error) {
      authorizationError = new AuthorizationError({
        error,
        error_description,
        error_uri: undefined,
        state,
      });
    } else {
      authorizationResponse = new AuthorizationResponse({
        code,
        state,
      });
    }

    await this.clearAuthorizationResponseFormStorage(key);

    return {
      request,
      response: authorizationResponse,
      error: authorizationError,
    };
  }

  public async clearAuthorizationResponseFormStorage(
    key: string
  ): Promise<void[]> {
    const tasks: Array<Promise<void>> = [
      this.storage.removeItem(AUTHORIZATION_REQUEST_HANDLE_KEY),
      this.storage.removeItem(getAuthorizationRequestKey(key)),
      this.storage.removeItem(getAuthorizationServiceConfigurationKey(key)),
    ];

    return Promise.all(tasks);
  }

  public async parseAuthorizationResponse(): Promise<AuthorizationResponseParsed> {
    const response: any = await this.storage.getItem(
      AUTHORIZATION_RESPONSE_KEY
    );
    const parts: any = response.split('#');

    if (parts.length !== 2) {
      throw new Error('Invalid auth repsonse string');
    }

    // Get the info from the calback URL
    const hash: any = parts[1];
    const queryParams: any = this.utils.parseQueryString(hash);

    return new AuthorizationResponseParsed(
      queryParams['state'], // tslint:disable-line:no-string-literal type
      queryParams['code'], // tslint:disable-line:no-string-literal type
      queryParams['error'], // tslint:disable-line:no-string-literal type
      queryParams['error_description'] // tslint:disable-line:no-string-literal type
    );
  }

  public abstract signOut(endPoint: string): Observable<void>;

  public abstract getSignInBrowserState(): Observable<SignInBrowserState>;

  public completeAuthorization(url: string): Observable<void> {
    this.storage.setItem(AUTHORIZATION_RESPONSE_KEY, url);
    return observableFrom(this.completeAuthorizationRequestIfPossible()).pipe(
      switchMap(() =>
        observableFrom(this.storage.removeItem(AUTHORIZATION_RESPONSE_KEY))
      )
    );
  }

  public requestAuthorizationToken(
    configuration: AuthorizationServiceConfiguration
  ): Observable<void> {
    const request = new AuthorizationRequest(
      {
        client_id: this.config.client_id,
        redirect_uri: this.config.redirect_uri,
        scope: this.config.scope,
        response_type: `${AuthorizationRequest.RESPONSE_TYPE_CODE} id_token`,
        state: undefined,
        extras: {
          access_type: 'offline',
          nonce: getRandomNumber(),
        },
      },
      this.crypto,
      false
    );

    return observableFrom(
      this.performAuthorizationRequest(configuration, request)
    ).pipe(
      catchError((err: any) => {
        console.error(`${AUTHORIZATION_REQUEST_ERROR} ${JSON.stringify(err)}`);
        this.onSignInErrorSink.next();

        return observableThrowError(() => err);
      })
    );
  }

  public requestAccessToken(
    configuration: AuthorizationServiceConfiguration,
    code: string,
    client_secret
  ): Observable<TokenResponse> {
    const tokenHandler = new BaseTokenRequestHandler(this.requestor);
    const request = new TokenRequest({
      client_id: this.config.client_id,
      redirect_uri: this.config.redirect_uri,
      grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
      code,
      refresh_token: undefined,
      extras: { client_secret },
    });

    return observableFrom(
      tokenHandler.performTokenRequest(configuration, request)
    ).pipe(
      catchError((err: any) => {
        console.error(`${ACCESS_TOKEN_REQUEST_ERROR} ${JSON.stringify(err)}`);
        return EMPTY;
      })
    );
  }

  public updateAccessTokenByRefreshToken(
    token: TokenResponse,
    configuration: AuthorizationServiceConfiguration,
    client_secret
  ): Observable<TokenResponse> {
    const tokenHandler = new BaseTokenRequestHandler(this.requestor);
    const request = new TokenRequest({
      client_id: this.config.client_id,
      redirect_uri: this.config.redirect_uri,
      grant_type: GRANT_TYPE_REFRESH_TOKEN,
      code: undefined,
      refresh_token: token.refreshToken,
      extras: { client_secret },
    });

    return observableFrom(
      tokenHandler.performTokenRequest(configuration, request)
    ).pipe(
      catchError((err: HttpErrorResponse) => {
        // this check is needed to not propagate error on switching to offline during request
        if (err.status !== 0) {
          this.updateAccessTokenByRefreshTokenErrorSink.next(err);
        }

        console.error(`${ACCESS_TOKEN_UPDATE_ERROR} ${JSON.stringify(err)}`);
        return EMPTY;
      })
    );
  }

  public getAuthorizationToken(
    configuration: AuthorizationServiceConfiguration
  ): Observable<void> {
    const authorizationResponseKey = this.getAuthorizationResponseKey();

    return !!authorizationResponseKey
      ? this.completeAuthorization(authorizationResponseKey)
      : this.requestAuthorizationToken(configuration);
  }

  public getTokenFromStorage(): Observable<TokenResponse> {
    return observableFrom(this.storage.getItem(TOKEN_RESPONSE_KEY)).pipe(
      map((token: string) => {
        if (token) {
          return new TokenResponse(JSON.parse(token));
        }

        return undefined;
      })
    );
  }

  public saveToken(response: TokenResponse): Observable<TokenResponse> {
    return observableFrom(
      this.storage.setItem(
        TOKEN_RESPONSE_KEY,
        JSON.stringify(response.toJson())
      )
    ).pipe(map(() => response));
  }

  public clear(): Observable<void> {
    const tasks: Array<Promise<any>> = [
      this.storage.removeItem(TOKEN_RESPONSE_KEY),
      this.storage.removeItem(AUTH_CODE_KEY),
    ];

    return observableFrom(Promise.all(tasks)).pipe(
      map(() => undefined),
      catchError((err: Error) => {
        console.error(
          `[AuthorizationRequestHandlerBase] Error while removing ${TOKEN_RESPONSE_KEY} and ${AUTH_CODE_KEY}`
        );
        return observableOf(err);
      })
    );
  }

  public abstract stopSignIn(): void;

  protected async completeAuthorizationRequest(): Promise<AuthorizationRequestResponse> {
    const handleKey: any = await this.storage.getItem(
      AUTHORIZATION_REQUEST_HANDLE_KEY
    );

    if (!handleKey) {
      return undefined;
    }

    const requestJSON: string = await this.storage.getItem(
      getAuthorizationRequestKey(handleKey)
    );

    const authorizationRequest: AuthorizationRequest = new AuthorizationRequest(
      JSON.parse(requestJSON),
      this.crypto,
      false
    );

    const authorizationResponseParsed = await this.parseAuthorizationResponse();

    if (authorizationResponseParsed.state === authorizationRequest.state) {
      return this.prepareAuthorizationRequestResponse(
        authorizationRequest,
        authorizationResponseParsed,
        handleKey
      );
    }

    return undefined;
  }

  protected abstract getAuthorizationResponseKey(): string;

  protected abstract signIn(url: string): void;

  protected init(): void {
    this.notifier = new AuthorizationNotifier();

    this.setAuthorizationNotifier(this.notifier);

    this.notifier.setAuthorizationListener(
      (
        request: AuthorizationRequest,
        response: AuthorizationResponse,
        error: AuthorizationError
      ) => {
        this.authorizationListener(request, response, error);
      }
    );
  }

  private authorizationListener(
    _request: AuthorizationRequest,
    response: AuthorizationResponse,
    _error: AuthorizationError
  ): void {
    if (response) {
      this.storage.setItem(AUTH_CODE_KEY, response.code);
      this.responseAuthorizationCodeSink.next(response.code);
    }
  }
}

export { AUTHORIZATION_RESPONSE_KEY, AuthorizationRequestHandlerBase };
