/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

import { type Subscription } from 'rxjs';

import { BiometricIDAuthVerifyService } from '@core/auth/biometric-id/biometric-id-auth-verify.service';
import { type SilentTokenRenewStrategyInterface } from '@core/auth/silent-token-renew/silent-token-renew.strategy.interface';
import { AuthNativeService } from '@core/auth/strategies/native/auth-native.service';
import { type PcsUser } from '@core/auth/strategies/pcs-user.model';
import { UnauthorizedHandleNativeService } from '@core/auth/unauthorized/unauthorized-handle-native.service';
import { PcsException } from '@shared/pcs-exceptions/pcs.exception';

export class PcsUnableSilentTokenRenewStartException extends PcsException {}

@Injectable()
export class SilentTokenRenewNativeService
  implements SilentTokenRenewStrategyInterface
{
  public timer: ReturnType<typeof setTimeout>;
  public onPauseSubscription: Subscription;
  public onResumeSubscription: Subscription;

  private minTokenLifetimeInSeconds: number = 10;
  private regularBeforeExpireTime: number = 60000;

  constructor(
    private authNativeService: AuthNativeService,
    private unauthorizedHandleNativeService: UnauthorizedHandleNativeService,
    private biometricIDAuthVerifyService: BiometricIDAuthVerifyService,
    private platform: Platform
  ) {}

  public start(user: PcsUser, isFromOffline: boolean): void {
    if (!user) {
      throw new PcsUnableSilentTokenRenewStartException(
        'SilentTokenRenewNativeService: start(): Cannot start, no authorized user '
      );
    }

    if (user.expires_in < this.minTokenLifetimeInSeconds) {
      throw new PcsUnableSilentTokenRenewStartException(
        'SilentTokenRenewNativeService: start(): access token lifetime is lower then 10 seconds'
      );
    }
    this.stop();

    const expiresInFromUser = user.expires_in * 1000;
    const beforeExpireUpdateTime =
      expiresInFromUser / this.regularBeforeExpireTime > 2
        ? this.regularBeforeExpireTime
        : expiresInFromUser / 2;

    const actualExpiresIn = this.calculateExpiresInTime(user.expires_at);
    const isAuthorized = actualExpiresIn > 0;

    this.biometricIDAuthVerifyService
      .checkBiometricAuthAvailability()
      .subscribe((isAvailable: boolean) => {
        if ((isAvailable || !isFromOffline) && !isAuthorized) {
          this.unauthorizedHandleNativeService.handle().subscribe();
          return;
        }

        const isNeedRenewRightNow = actualExpiresIn < beforeExpireUpdateTime;
        if (isNeedRenewRightNow) {
          this.authNativeService.signinSilent().subscribe();
          return;
        }

        const silentTokenRenewDelay = actualExpiresIn - beforeExpireUpdateTime;

        this.timer = setTimeout(
          () => this.updateTokenSilently(user),
          silentTokenRenewDelay
        );
        this.onPauseSubscription = this.platform.pause.subscribe(() =>
          this.onPause()
        );
        this.onResumeSubscription = this.platform.resume.subscribe(() =>
          this.onResume(user, isFromOffline)
        );
      });
  }

  public stop(): void {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    if (this.onPauseSubscription) {
      this.onPauseSubscription.unsubscribe();
      this.onPauseSubscription = null;
    }

    if (this.onResumeSubscription) {
      this.onResumeSubscription.unsubscribe();
      this.onResumeSubscription = null;
    }
  }

  private updateTokenSilently(user: PcsUser): void {
    const expiresIn = this.calculateExpiresInTime(user.expires_at);

    if (expiresIn < 0) {
      return;
    }

    this.authNativeService.signinSilent().subscribe();
    this.stop();
  }

  private onPause(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  private onResume(user: PcsUser, isFromOffline: boolean): void {
    this.start(user, isFromOffline);
  }

  private calculateExpiresInTime(expiresAtInSeconds: number): number {
    const expiresAt = expiresAtInSeconds * 1000;
    const currentTime = new Date().getTime();

    return expiresAt - currentTime;
  }
}
