/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';

import { type Observable, of as observableOf } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { SessionStoreService } from '@core/auth/session/session-store.service';
import { BaseStorageService } from '@core/storage/base-storage.service';
import { StorageStrategyFactoryResolverService } from '@core/storage/storage-strategy-factory-resolver.service';

import { type OngoingWorkspace } from '../../../workspace/ongoing/workspace/ongoing-workspace.model';
import { putItemToCollection } from '../../shared/store-utils';

@Injectable()
export class OngoingOfflineDashboardStoreService extends BaseStorageService<
  OngoingWorkspace[]
> {
  constructor(
    protected storageStrategyResolverService: StorageStrategyFactoryResolverService,
    private sessionStoreService: SessionStoreService
  ) {
    super(storageStrategyResolverService);
  }

  public getDashboardItemByCompanyId(
    companyId: string
  ): Observable<OngoingWorkspace[]> {
    const key = this.getKey();

    return super.get(key).pipe(
      map((allDashboardItems: OngoingWorkspace[]) => {
        if (!allDashboardItems) {
          return null;
        }
        return allDashboardItems.filter(
          (dashboardItem) => dashboardItem.warehouseCompanyId === companyId
        );
      })
    );
  }

  public saveDashboardItem(
    dashboardItemToSave: OngoingWorkspace
  ): Observable<void> {
    if (!dashboardItemToSave) {
      return observableOf(null);
    }

    const key = this.getKey();

    return super.get(key).pipe(
      mergeMap((savedDashboardItems: OngoingWorkspace[]) => {
        if (!savedDashboardItems) {
          return super.set(key, [dashboardItemToSave]);
        }

        const isDashboardItemEqual = (
          dashboardItemA: OngoingWorkspace,
          dashboardItemB: OngoingWorkspace
        ) => dashboardItemA.id === dashboardItemB.id;
        const allItemsToSave = putItemToCollection<OngoingWorkspace>(
          dashboardItemToSave,
          savedDashboardItems,
          isDashboardItemEqual
        );

        return super.set(key, allItemsToSave);
      })
    );
  }

  public removeDashboardItemById(dashboardId: number): Observable<void> {
    const key = this.getKey();

    return super.get(key).pipe(
      mergeMap((dashboardItems: OngoingWorkspace[]) => {
        if (!dashboardItems || dashboardItems.length === 0) {
          return observableOf(null);
        }
        const itemsToSet = dashboardItems.filter(
          (item) => item.id !== dashboardId
        );

        return super.set(key, itemsToSet);
      })
    );
  }

  public removeDashboardsByCompanyId(companyId: string): Observable<void> {
    const key = this.getKey();

    return super.get(key).pipe(
      mergeMap((dashboardItems: OngoingWorkspace[]) => {
        if (!dashboardItems || dashboardItems.length === 0) {
          return observableOf(null);
        }
        const itemsToSet = dashboardItems.filter(
          (item) => item.warehouseCompanyId !== companyId
        );

        return super.set(key, itemsToSet);
      })
    );
  }

  private getKey(): string {
    const userId: string = this.sessionStoreService.getUserId();

    return `ONGOING_DASHBOARD_ITEMS_OFFLINE::${userId}`;
  }
}
