/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';

import { type Observable, of } from 'rxjs';
import { catchError, mapTo, mergeMap, shareReplay, tap } from 'rxjs/operators';

import { BiometricIDAuthVerifyService } from '@core/auth/biometric-id/biometric-id-auth-verify.service';
import { BiometricIDNotVerifiedException } from '@core/auth/biometric-id/biometric-id-not-verified.exception';
import { AuthNativeService } from '@core/auth/strategies/native/auth-native.service';
import { type UnauthorizedHandleStrategyInterface } from '@core/auth/unauthorized/unauthorized-handle-strategy.interface';
import { InternetConnectionService } from '@core/offline-core/internet-connection/internet-connection.service';
@Injectable()
export class UnauthorizedHandleNativeService
  implements UnauthorizedHandleStrategyInterface
{
  public verifyFlow: Observable<void>;

  constructor(
    private authNativeService: AuthNativeService,
    private biometricIDAuthVerifyService: BiometricIDAuthVerifyService,
    private internetConnectionService: InternetConnectionService
  ) {}

  public handle(): Observable<void> {
    if (this.verifyFlow) {
      return this.verifyFlow;
    }

    const calculateExpiresInTime = (expiresAtInSeconds: number): number => {
      const expiresAt = expiresAtInSeconds * 1000;
      const currentTime = new Date().getTime();

      return expiresAt - currentTime;
    };

    const isAuthorized =
      this.internetConnectionService.isOffline() ||
      calculateExpiresInTime(
        this.authNativeService.getCurrentUser().expires_at
      ) > 0;
    const verifyObs = isAuthorized
      ? of(true)
      : this.biometricIDAuthVerifyService.verify();
    this.verifyFlow = verifyObs.pipe(
      mergeMap((isVerified: boolean) => {
        if (isVerified) {
          // By default startSignInMainWindow makes call for new token, so it equals silent token renew ¯\_(ツ)_/¯
          return this.authNativeService
            .startSignInMainWindow()
            .pipe(mapTo(null));
        }
        throw new BiometricIDNotVerifiedException(
          'UnauthorizedHandleNativeService: Can not resign in, biometric ID is not verified'
        );
      }),
      tap(() => {
        this.verifyFlow = null;
      }),
      catchError((error) => {
        this.verifyFlow = null;

        return this.authNativeService.startSignOutMainWindow().pipe(
          catchError((signOutError) => {
            // eslint-disable-next-line no-throw-literal
            throw [error, signOutError]; //NOSONAR
          }),
          mergeMap(() => {
            throw error;
          })
        );
      }),
      shareReplay()
    );

    return this.verifyFlow;
  }
}
