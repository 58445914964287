/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';

import { type Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { OpenIdObservablesService } from '@core/openId/src/services/open-id-observables.service';

import { type User } from '../OpenId.dictionary';
import { OpenIdService } from '../OpenId.service';
import { UserManagerEvent } from './Event';
import { type UserManagerEventHandlerModel } from './UserManager.dictionary';

@Injectable()
class UserManagerEvents implements UserManagerEventHandlerModel {
  private userLoaded: UserManagerEvent;
  private userSignedOut: UserManagerEvent;
  private silentRenewError: UserManagerEvent;
  private silentRenewSuccessful: UserManagerEvent;

  private raiseUserLoaded$: Observable<void> =
    this.openIdService.userInfo$.pipe(
      filter((v) => !!v),
      map((user: User) => this.raiseUserLoaded(user))
    );

  private raiseUserSignOut$: Observable<void> =
    this.openIdService.signOut$.pipe(map(() => this.raiseUserSignedOut()));

  private raiseSilentRenewError$: Observable<void> =
    this.openIdService.updateAccessTokenByRefreshTokenError$.pipe(
      map((err: Error) => this.raiseSilentRenewError(err))
    );

  private raiseSilentRenewSuccessful$: Observable<void> =
    this.openIdObservablesService.accessTokenResponseByRefreshToken$.pipe(
      map(() => this.raiseSilentRenewSuccessful())
    );

  constructor(
    private openIdService: OpenIdService,
    private openIdObservablesService: OpenIdObservablesService
  ) {
    this.init();
  }

  public unload(): void {
    /* fix ts-lint error (non-empty method) */
  }

  public addUserLoaded(cb): void {
    this.userLoaded.registerCallback(cb);
  }

  public addUserSignedOut(cb): void {
    this.userSignedOut.registerCallback(cb);
  }

  public addSilentRenewError(cb): void {
    this.silentRenewError.registerCallback(cb);
  }

  public addSilentRenewSuccessful(cb): void {
    this.silentRenewSuccessful.registerCallback(cb);
  }

  private raiseUserLoaded(user: User): void {
    this.userLoaded.raise(user);
  }

  private raiseUserSignedOut(): void {
    this.userSignedOut.raise();
  }

  private raiseSilentRenewError(err: Error): void {
    this.silentRenewError.raise(err);
  }

  private raiseSilentRenewSuccessful(): void {
    this.silentRenewSuccessful.raise();
  }

  private init(): void {
    this.userLoaded = new UserManagerEvent('User loaded');
    this.userSignedOut = new UserManagerEvent('User signed out');
    this.silentRenewError = new UserManagerEvent('User silent renew error');
    this.silentRenewSuccessful = new UserManagerEvent(
      'User silent renew successful'
    );

    this.raiseUserLoaded$.subscribe();

    this.raiseUserSignOut$.subscribe();

    this.raiseSilentRenewError$.subscribe();

    this.raiseSilentRenewSuccessful$.subscribe();
  }
}

export { UserManagerEvents };
