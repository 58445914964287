/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';

import {
  type Observable,
  forkJoin,
  forkJoin as observableForkJoin,
  of as observableOf,
  throwError as observableThrowError,
} from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { AnnotationsHttpService } from '@core/annotations/annotations-http.service';
import { AnnotationsOfflineService } from '@core/annotations/annotations-offline.service';
import { type FortDocsSaveFileOptions } from '@core/offline-core/files/models/fort-docs-save-file-options.model';
import { FileService } from '@shared/files/file.service';
import { PcsInsufficientStorageException } from '@workspace-shared/pcs-exceptions/psc-insufficient-storage.exception';

import { PreviewOfflineStoreService } from './preview-offline-store.service';

@Injectable()
export class OfflineProcessingService {
  constructor(
    private fileService: FileService,
    private previewOfflineStoreService: PreviewOfflineStoreService,
    private annotationsHttpService: AnnotationsHttpService,
    private annotationsOfflineService: AnnotationsOfflineService
  ) {}

  public saveStandaloneFortFile(
    options: FortDocsSaveFileOptions
  ): Observable<boolean> {
    return this.fileService.getFreeDiskSpace().pipe(
      mergeMap((freeSpace: string) => observableOf(Number(freeSpace))),
      mergeMap((space: number) => {
        if (this.isEnoughSpaceAvailable(space, options.documentSize)) {
          return forkJoin([
            this.fileService.downloadFileByLink(
              options.downloadLink,
              options.documentName
            ),
            this.tryToSaveFortDocsAnnotations(options),
          ]);
        }

        return observableThrowError(
          () => new PcsInsufficientStorageException('insufficient storage')
        );
      }),
      mergeMap(([storedFileName, annotationsFileName]) =>
        this.previewOfflineStoreService.save({
          id: options.documentId,
          downloadedFilePaths: [storedFileName],
          bookmarks: options.bookmarks,
          annotationsPath: annotationsFileName,
          agenda: options.agenda,
          watermark: options.watermark,
          fortDocsPreviewPassword: options.fortDocsPreviewPassword,
        })
      ),
      map(() => true)
    );
  }

  public removeFile(documentId: string) {
    return this.previewOfflineStoreService
      .getDownloadedPresentations(documentId)
      .pipe(
        mergeMap((downloadedFilePaths: string[]) =>
          this.removeDownloadedFiles(downloadedFilePaths)
        ),
        mergeMap(() => this.previewOfflineStoreService.remove(documentId))
      );
  }

  private tryToSaveFortDocsAnnotations({
    annotationsDownloadLink,
  }: FortDocsSaveFileOptions): Observable<string> {
    if (!annotationsDownloadLink) {
      return observableOf(null);
    }

    return this.annotationsHttpService
      .getAnnotations(annotationsDownloadLink)
      .pipe(
        mergeMap((annotations: string) =>
          this.annotationsOfflineService.saveAnnotationsToOffline(annotations)
        ),
        catchError(() => observableOf(null))
      );
  }

  private removeDownloadedFiles(
    downloadedFilePaths: string[]
  ): Observable<void> {
    const removeFilesQueue = downloadedFilePaths.map(
      (downloadedFilePath: string) =>
        this.fileService.deleteFile(downloadedFilePath)
    );

    return observableForkJoin(removeFilesQueue).pipe(map(() => null));
  }

  private isEnoughSpaceAvailable(freeSpace, fileSize): boolean {
    return freeSpace > fileSize * 2;
  }
}
