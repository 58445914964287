/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { type ModuleWithProviders } from '@angular/core';
import { type Routes, PreloadAllModules, RouterModule } from '@angular/router';

import { AuthGuardService } from '@core/auth/auth-guard.service';
import { SessionResolver } from '@core/auth/session/session.resolver';
import { CheckVersionGuardService } from '@core/version/check-version-guard.service';

import { LayoutComponent } from './layout/layout.component';

const routes: Routes = [
  {
    path: 'no-access',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./no-access/no-access.module').then((m) => m.NoAccessModule),
      },
    ],
  },
  {
    path: 'maintenance',
    loadChildren: () =>
      import('./maintenance/maintenance-page.module').then(
        (m) => m.MaintenancePageModule
      ),
  },
  {
    path: 'recover',
    loadChildren: () =>
      import('./recover/recover.module').then((m) => m.RecoverModule),
  },
  {
    path: '',
    resolve: {
      session: SessionResolver,
    },
    canActivate: [CheckVersionGuardService, AuthGuardService],
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'server-error',
        loadChildren: () =>
          import('./server-error/server-error.module').then(
            (m) => m.ServerErrorModule
          ),
      },
      {
        path: 'universal-links-landing',
        loadChildren: () =>
          import(
            './universal-links-landing/universal-links-landing.module'
          ).then((m) => m.UniversalLinksLandingModule),
      },
      {
        path: 'not-found-page',
        loadChildren: () =>
          import('./not-found-page/not-found-page.module').then(
            (m) => m.NotFoundPageModule
          ),
      },
      {
        path: '**',
        redirectTo: '/not-found-page',
      },
    ],
  },
];

export const APP_ROUTES_PROVIDER: ModuleWithProviders<any> =
  RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    paramsInheritanceStrategy: 'always',
  });
