/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import {
  type AuthorizationServiceConfiguration,
  type TokenResponse,
} from '@openid/appauth';

import {
  type Observable,
  combineLatest,
  from as observableFrom,
  merge as observableMerge,
  Subject,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  mapTo,
  publishReplay,
  refCount,
  share,
  skip,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { UserLoggedIn } from '@core/openId/src/authorization.actions';
import { AuthorizationRequestHandlerBase } from '@core/openId/src/AuthorizationRequestHandler.base';
import {
  type User,
  APPROXIMATE_DELAY_BEFORE_OPENING_INAPP_BROWSER,
  SignInBrowserState,
} from '@core/openId/src/OpenId.dictionary';
import { OpenIdFetchService } from '@core/openId/src/services/open-id-fetch.service';
import { OpenIdHelperService } from '@core/openId/src/services/open-id-helper.service';

@Injectable()
export class OpenIdObservablesService {
  public clearStaleStateSink: Subject<void> = new Subject<void>();
  public requestWithRefreshTokenSink: Subject<TokenResponse> =
    new Subject<TokenResponse>();
  public silentRenewAccessTokenSink: Subject<boolean> = new Subject<boolean>();
  public signInSink: Subject<void> = new Subject<void>();
  public signInSilentSink: Subject<void> = new Subject<void>();
  public signOutSink: Subject<void> = new Subject<void>();
  public userInfoSink: Subject<User> = new Subject<User>();
  public platformReady$: Observable<string> = observableFrom(
    this.platform.ready()
  );

  public accessTokenResponse$: Observable<TokenResponse> =
    this.authorizationHandler.authorizationCode$.pipe(
      switchMap((code) =>
        this.openIdFetchService.fetchConfiguration().pipe(
          switchMap((configuration) =>
            this.authorizationHandler.requestAccessToken(
              configuration,
              code,
              this.openIdHelperService.getClientSecret()
            )
          ),
          share(),
          tap(() => {
            this.store.dispatch(new UserLoggedIn());
          })
        )
      )
    );

  public signIn$: Observable<void> = this.signInSink.asObservable().pipe(
    switchMap(() => this.authorizationHandler.getSignInBrowserState()),
    filter((v: SignInBrowserState) => v !== SignInBrowserState.open),
    mapTo(undefined)
  );

  public initialSignIn$: Observable<void> = observableMerge(
    this.signIn$.pipe(first())
  ).pipe(mapTo(undefined), publishReplay(1), refCount());

  public authorizationTokenRequestTrigger$: Observable<TokenResponse> =
    observableMerge(this.initialSignIn$, this.signIn$.pipe(skip(1))).pipe(
      switchMap(() => this.authorizationHandler.getTokenFromStorage()),
      publishReplay(1),
      refCount()
    );

  public initialTokenFromStorage$: Observable<TokenResponse> = combineLatest([
    this.initialSignIn$,
    this.platformReady$,
  ]).pipe(
    switchMap(() => this.authorizationHandler.getTokenFromStorage()),
    share()
  );

  public fetchConfigurationRetryTrigger$: Observable<void> = observableMerge(
    this.signInSink.asObservable(),
    this.signInSilentSink.asObservable(),
    this.signOutSink.asObservable()
  );

  public requestAuthorizationToken$: Observable<void> =
    this.authorizationTokenRequestTrigger$.pipe(
      debounceTime(APPROXIMATE_DELAY_BEFORE_OPENING_INAPP_BROWSER),
      filter((tokenResponse: TokenResponse) => !tokenResponse),
      switchMap(() => this.openIdFetchService.fetchConfiguration()),
      switchMap((configuration: AuthorizationServiceConfiguration) =>
        this.authorizationHandler.getAuthorizationToken(configuration)
      )
    );

  public accessTokenResponseByRefreshToken$: Observable<TokenResponse> =
    observableMerge(
      this.authorizationTokenRequestTrigger$,
      this.requestWithRefreshTokenSink.asObservable(),
      this.initialTokenFromStorage$
    ).pipe(
      filter((tokenResponse: TokenResponse) => !!tokenResponse),
      switchMap((token) =>
        this.openIdFetchService.fetchConfiguration().pipe(
          switchMap((configuration) =>
            this.authorizationHandler.updateAccessTokenByRefreshToken(
              token,
              configuration,
              this.openIdHelperService.getClientSecret()
            )
          ),
          map((newToken: TokenResponse) => [token, newToken])
        )
      ),
      map(([{ refreshToken }, newToken]) => {
        newToken.refreshToken = newToken.refreshToken
          ? newToken.refreshToken
          : refreshToken;
        return this.openIdHelperService.createTokenResponse(newToken.toJson());
      }),
      share()
    );

  public tokenResponse$: Observable<TokenResponse> = observableMerge(
    this.accessTokenResponse$,
    this.accessTokenResponseByRefreshToken$
  ).pipe(
    filter((v) => !!v),
    distinctUntilChanged(),
    switchMap((tokenResponse: TokenResponse) =>
      this.authorizationHandler.saveToken(tokenResponse)
    ),
    share()
  );

  public silentRenewAccessToken$: Observable<TokenResponse> = combineLatest([
    this.silentRenewAccessTokenSink.asObservable(),
    this.tokenResponse$,
  ]).pipe(
    switchMap(([silentRenew, token]) =>
      this.openIdHelperService.defineSilentRenewAccessTokenInterval(
        silentRenew,
        token
      )
    ),
    filter((v) => !!v)
  );

  public signOutUrl$: Observable<string> = this.signOutSink.asObservable().pipe(
    switchMap(() => this.openIdFetchService.fetchConfiguration()),
    switchMap(({ endSessionEndpoint }: AuthorizationServiceConfiguration) =>
      this.authorizationHandler
        .getTokenFromStorage()
        .pipe(
          map((tokenResponse: TokenResponse) => [
            endSessionEndpoint,
            tokenResponse,
          ])
        )
    ),
    map(([endSessionEndpoint, tokenResponse]: [string, TokenResponse]) =>
      this.openIdHelperService.getLogoutUri(endSessionEndpoint, tokenResponse)
    )
  );

  public signInSilent$: Observable<TokenResponse> = this.signInSilentSink
    .asObservable()
    .pipe(
      withLatestFrom(this.tokenResponse$, (_, token: TokenResponse) => token)
    );

  constructor(
    private store: Store<any>,
    private platform: Platform,
    private authorizationHandler: AuthorizationRequestHandlerBase,
    private openIdHelperService: OpenIdHelperService,
    private openIdFetchService: OpenIdFetchService
  ) {}

  public init(): void {
    this.openIdFetchService.setConfigurationRetryTrigger(
      this.fetchConfigurationRetryTrigger$
    );
    this.silentRenewAccessToken$.subscribe(this.requestWithRefreshTokenSink);
    this.signInSilent$.subscribe(this.requestWithRefreshTokenSink);

    this.requestAuthorizationToken$.subscribe();
  }
}
