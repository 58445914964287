/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import {
  type OnDestroy,
  type OnInit,
  ChangeDetectorRef,
  Component,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { IonMenu } from '@ionic/angular';
import { type PendoStatisticsConfigModel } from '@pcs/pendo-statistics';

import { type Observable, from, Subscription } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import { SessionStoreService } from '@core/auth/session/session-store.service';
import { InternetConnectionService } from '@core/offline-core/internet-connection/internet-connection.service';
import { PcsPlatformService } from '@core/pcs-platform.service';
import { PendoAgentMetadataService } from '@core/pendo/pendo-agent-metadata.service';

import { AccountMenuService } from '../navigation-bar/account-menu.service';

@Component({
  selector: 'pcs-layout',
  styleUrls: ['./layout.component.scss', './ion-override.scss'],
  templateUrl: './layout.component.html',
})
export class LayoutComponent implements OnInit, OnDestroy {
  @ViewChild(IonMenu)
  public sideMenu: IonMenu;
  public isMenuOpened: boolean = false;
  public pendoConfig: PendoStatisticsConfigModel;
  public isRunUnderBrowser: boolean;
  public userProfilePicture: string;
  public sideMenuDisabled$: Observable<boolean>;

  private routeReplacerRegexp: RegExp = new RegExp(/\/menu[^\(]*\)|\(/g);
  private subscriptions: Subscription = new Subscription();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private accountMenuService: AccountMenuService,
    private pendoAgentMetadataService: PendoAgentMetadataService,
    private pcsPlatformService: PcsPlatformService,
    private router: Router,
    private sessionStoreService: SessionStoreService,
    private internetConnectionService: InternetConnectionService
  ) {}

  private get isOffline(): boolean {
    return this.internetConnectionService.isOffline();
  }

  public ngOnInit(): void {
    this.initPendoStatisticsConfig();
    this.cacheProfilePicture();

    this.sideMenuDisabled$ = this.accountMenuService.sideMenuDisabled$;
    this.pcsPlatformService.ready().then(() => {
      this.isRunUnderBrowser = !this.pcsPlatformService.isOnDevice();
    });
    this.listenToSideMenuClose();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onMenuWillOpen(): void {
    this.accountMenuService.openAccountMenu();
    this.isMenuOpened = true;
    this.changeDetectorRef.detectChanges();
  }

  public onMenuWillClose(): void {
    this.accountMenuService.closeAccountMenu();
    this.isMenuOpened = false;
    this.changeDetectorRef.detectChanges();
  }

  public onMenuDidClose(): void {
    const urlAfterClose = this.getCleanUrl();
    this.router.navigateByUrl(urlAfterClose, {
      skipLocationChange: true,
    });
  }

  private cacheProfilePicture(): void {
    if (!this.isOffline) {
      this.userProfilePicture = this.sessionStoreService.getProfilePhoto();
    }
  }

  private getCleanUrl(): string {
    return this.router.url
      .replace('/(', '/')
      .replace('//', '/')
      .replace(this.routeReplacerRegexp, '');
  }

  private initPendoStatisticsConfig(): void {
    this.pendoConfig = {
      apiKey: '3827ad35-24db-4a88-6e67-e3bc5a63ff38',
      agentMetadata: this.pendoAgentMetadataService.getAgentMetadata(),
    };
  }

  private listenToSideMenuClose(): void {
    const sub: Subscription = this.accountMenuService.sideMenuClosed$
      .pipe(
        switchMap(() => from(this.sideMenu.isOpen())),
        filter(
          (isSideMenuComponentOpened: boolean) => isSideMenuComponentOpened
        ),
        tap(() => {
          this.sideMenu.close(true);
        })
      )
      .subscribe();

    this.subscriptions.add(sub);
  }
}
