/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { type TokenResponse } from '@openid/appauth';

import {
  type UserProfile,
  AUTHORIZATION_REQUEST_KEY,
  AUTHORIZATION_SERVICE_CONFIGURATION,
  MILLSEC_IN_SECONDS,
  User,
} from './OpenId.dictionary';

// In terms of our project there is nothing in this here by default
const MODULE: any = this || {};

const nowInSeconds = (): number => Math.round(new Date().getTime() / 1000);

MODULE.nowInSeconds = nowInSeconds;

const getRandomNumber = (): string =>
  Math.floor(Math.random() * 100000).toString();

MODULE.getRandomNumber = getRandomNumber;

const shouldRefreshToken = (
  tokenResponse: TokenResponse,
  timeBeforeUpdateToken: number
): boolean => {
  if (tokenResponse.expiresIn) {
    const timeSinceIssued: number =
      MODULE.nowInSeconds() - tokenResponse.issuedAt;
    const expiresInSec: number = tokenResponse.expiresIn;

    return (
      timeSinceIssued >
      expiresInSec - timeBeforeUpdateToken / MILLSEC_IN_SECONDS
    );
  }
  return true;
};

MODULE.shouldRefreshToken = shouldRefreshToken;

const getUser = (
  {
    idToken,
    accessToken,
    tokenType,
    scope,
    refreshToken,
    issuedAt,
    expiresIn,
  }: TokenResponse,
  userProfile: UserProfile,
  expired: boolean = false
): User =>
  new User({
    id_token: idToken,
    access_token: accessToken,
    token_type: tokenType,
    scope,
    profile: userProfile,
    refreshToken,
    expires_at: issuedAt + (expiresIn || 0),
    expires_in: expiresIn,
    expired,
  });

const getAuthorizationRequestKey = (key: string): string =>
  `${key}_${AUTHORIZATION_REQUEST_KEY}`;

const getAuthorizationServiceConfigurationKey = (key: string): string =>
  `${key}_${AUTHORIZATION_SERVICE_CONFIGURATION}`;

const isUserProfilesEqual = (
  first: UserProfile,
  second: UserProfile
): boolean => {
  if (!first || !second) {
    return first === second;
  }

  return first.name === second.name && first.email === second.email;
};

export {
  nowInSeconds,
  getRandomNumber,
  shouldRefreshToken,
  getUser,
  getAuthorizationRequestKey,
  getAuthorizationServiceConfigurationKey,
  isUserProfilesEqual,
};
