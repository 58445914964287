/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TreeModule } from '@circlon/angular-tree-component';
import { IonicModule } from '@ionic/angular';
import { ControlDirectivesModule } from '@pcs/core/directives/control';

import { ClearInputDirective } from '@shared/clear-input/clear-input.directive';
import { TimezonePipe } from '@shared/ipreo-account-zone/timezone-pipe';
import { SelectComponentModule } from '@shared/select/select-component.module';
import { SpinnerComponent } from '@shared/spinners/spinner/spinner.component';

import { FormItemsModule } from '../form-items/form-items.module';
import { ModalHeaderModule } from '../modal-header/modal-header.module';
import { AddUserComponent } from './participants/add-user/add-user.component';
import { UserEntityComponent } from './participants/add-user/user-entity/user-entity.component';
import { UserEntityDataService } from './participants/add-user/user-entity/user-entity-data.service';
import { UserEntityModalService } from './participants/add-user/user-entity/user-entity-modal.service';
import { UserEntityAddressItemComponent } from './participants/add-user/user-entity-address-item/user-entity-address-item.component';
import { UserRolesComponent } from './participants/add-user/user-roles/user-roles.component';
import { UserRolesModalService } from './participants/add-user/user-roles/user-roles-modal.service';
import { ParticipantDetailsComponent } from './participants/partcipant-details/participant-details.component';
import { ParticipantDetailsActionButtonComponent } from './participants/partcipant-details/participant-details-action-button/participant-details-action-button.component';
import { ParticipantDetailsHeaderComponent } from './participants/partcipant-details/participant-details-header/participant-details-header.component';
import { ParticipantDetailsItemComponent } from './participants/partcipant-details/participant-details-item/participant-details-item.component';
import { ParticipantEntityItemComponent } from './participants/partcipant-details/participant-entity-item/participant-entity-item.component';
import { ParticipantGroupsComponent } from './participants/partcipant-details/participant-groups/participant-groups.component';
import { ParticipantGroupsModalService } from './participants/partcipant-details/participant-groups/participant-groups-modal.service';
import { ParticipantPermissionSetsComponent } from './participants/partcipant-details/participant-permission-sets/participant-permission-sets.component';
import { ParticipantPermissionSetsModalService } from './participants/partcipant-details/participant-permission-sets/participant-permission-sets-modal.service';
import { ParticipantCardComponent } from './participants/participant-card/participant-card.component';
import { ParticipantsTableHeadComponent } from './participants/participants-table-head/participants-table-head.component';
import { AddPermissionSetComponent } from './permission-sets/add-permission-set/add-permission-set.component';
import { PermissionSetComponent } from './permission-sets/permission-set/permission-set.component';
import { PermissionSetActionSheetService } from './permission-sets/permission-set-action-sheet.service';
import { FolderSettingsTreeService } from './permission-sets/permission-set-details/folder-settings-tree/folder-settings-tree.service';
import { FolderSettingsTreeComponent } from './permission-sets/permission-set-details/folder-settings-tree/folder-settings-tree/folder-settings-tree.component';
import { PermissionSetDetailsComponent } from './permission-sets/permission-set-details/permission-set-details.component';
import { PermissionSetDetailsHeaderComponent } from './permission-sets/permission-set-details/permission-set-details-header/permission-set-details-header.component';
import { PermissionSetDetailsLabelComponent } from './permission-sets/permission-set-details/permission-set-details-label/permission-set-details-label.component';
import { PermissionSetDetailsTreeComponent } from './permission-sets/permission-set-details/permission-set-details-tree/permission-set-details-tree.component';
import { PermissionSetDetailsUserComponent } from './permission-sets/permission-set-details/permission-set-details-user/permission-set-details-user.component';
import { PermissionsSetDetailsActionSheetService } from './permission-sets/permission-set-details/permissions-set-details-action-sheet.service';
import { PermissionsSetDetailsDataService } from './permission-sets/permission-set-details/permissions-set-details-data.service';
import { PermissionTreeService } from './permission-sets/permission-set-details/permissions-tree.service';
import { PermissionSetsHelperService } from './permission-sets/permission-sets-helper.service';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SelectComponentModule,
    ModalHeaderModule,
    FormItemsModule,
    TreeModule,
    FormsModule,
    ReactiveFormsModule,
    ControlDirectivesModule,
    TimezonePipe,
    ClearInputDirective,
    SpinnerComponent,
  ],
  exports: [
    ParticipantsTableHeadComponent,
    ParticipantCardComponent,
    ParticipantDetailsComponent,
    ParticipantDetailsItemComponent,
    ParticipantDetailsActionButtonComponent,
    ParticipantDetailsHeaderComponent,
    ParticipantGroupsComponent,
    ParticipantPermissionSetsComponent,
    PermissionSetComponent,
    PermissionSetDetailsHeaderComponent,
    PermissionSetDetailsComponent,
    PermissionSetDetailsLabelComponent,
    PermissionSetDetailsUserComponent,
    PermissionSetDetailsTreeComponent,
    AddUserComponent,
    UserRolesComponent,
    UserEntityComponent,
    AddPermissionSetComponent,
    ParticipantEntityItemComponent,
    UserEntityAddressItemComponent,
    FolderSettingsTreeComponent,
  ],
  declarations: [
    ParticipantsTableHeadComponent,
    ParticipantCardComponent,
    ParticipantDetailsComponent,
    ParticipantDetailsItemComponent,
    ParticipantDetailsActionButtonComponent,
    ParticipantDetailsHeaderComponent,
    ParticipantGroupsComponent,
    ParticipantPermissionSetsComponent,
    PermissionSetComponent,
    PermissionSetDetailsHeaderComponent,
    PermissionSetDetailsComponent,
    PermissionSetDetailsLabelComponent,
    PermissionSetDetailsUserComponent,
    PermissionSetDetailsTreeComponent,
    AddUserComponent,
    UserRolesComponent,
    UserEntityComponent,
    AddPermissionSetComponent,
    ParticipantEntityItemComponent,
    UserEntityAddressItemComponent,
    FolderSettingsTreeComponent,
  ],
  entryComponents: [
    ParticipantDetailsComponent,
    ParticipantGroupsComponent,
    ParticipantPermissionSetsComponent,
    PermissionSetComponent,
    PermissionSetDetailsComponent,
    AddUserComponent,
    UserRolesComponent,
    UserEntityComponent,
    AddPermissionSetComponent,
  ],
  providers: [
    ParticipantGroupsModalService,
    ParticipantPermissionSetsModalService,
    PermissionSetActionSheetService,
    PermissionTreeService,
    UserRolesModalService,
    PermissionsSetDetailsActionSheetService,
    PermissionsSetDetailsDataService,
    UserEntityModalService,
    UserEntityDataService,
    PermissionSetsHelperService,
    FolderSettingsTreeService,
  ],
})
export class UserManagementModule {}
