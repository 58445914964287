/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';
import {
  type Moment,
  type MomentRef,
  MomentRefService,
} from '@pcs/core/moment';

import { AuthService } from '@core/auth/auth.service';
import { TimeFrameOption } from '@shared/visualization-chart/visualization-timeframe.model';

@Injectable()
export class PCSDateService {
  private momentRef: MomentRef;

  constructor(
    private authService: AuthService,
    private momentRefService: MomentRefService
  ) {
    this.momentRef = this.momentRefService.getMomentRef();
  }

  public get zoneInfo(): string {
    return (
      this.authService.getCurrentUser().profile.zoneinfo ||
      this.momentRef.tz.guess()
    );
  }

  public create(
    date: string | Date = new Date(),
    timeZone: string = this.zoneInfo
  ): Moment {
    return this.momentRef(date).tz(timeZone);
  }

  public formatDateInTimeZone(
    date: string | Date = new Date(),
    tz?: string
  ): Date {
    return this.getNativeDate(this.create(date, tz));
  }

  public getNativeDate(date: Moment): Date {
    return new Date(
      date.year(),
      date.month(),
      date.date(),
      date.hour(),
      date.minutes(),
      date.second()
    );
  }

  public addDays(date: Date, days: number): Date {
    const momentDate = this.momentRef(date).add(days, 'days');

    return new Date(momentDate.year(), momentDate.month(), momentDate.date());
  }

  public substractDays(date: Date, days: number): Date {
    const momentDate = this.momentRef(date).subtract(days, 'days');
    return this.getNativeDate(momentDate);
  }

  public isPast(
    date: string | Date = new Date(),
    timeZone: string = this.zoneInfo
  ): boolean {
    const now = this.momentRef(new Date()).tz(timeZone).toDate();

    const dateToCompare = this.momentRef(date).tz(timeZone).toDate();

    return dateToCompare.getTime() < now.getTime();
  }

  public getAggregationDateRange(dateOption: TimeFrameOption) {
    const userEndDate: Date = this.getDateInUserTimezone(new Date());
    const userStartDate: Date = this.getAggregationStartDate(
      userEndDate,
      dateOption
    );
    const roundedUserEndDate: Date = this.roundDate(userEndDate);
    const roundedUserStartDate: Date = this.roundDate(userStartDate);

    return {
      startTime: this.asUtc(roundedUserStartDate),
      endTime: this.asUtc(roundedUserEndDate),
    };
  }

  private getAggregationStartDate(
    endDate: Date,
    dateOption: TimeFrameOption
  ): Date {
    if (dateOption === TimeFrameOption.LastDay) {
      const result: Date = new Date(endDate);
      result.setDate(result.getDate() - 1);

      return result;
    }
    if (dateOption === TimeFrameOption.LastWeek) {
      const result: Date = new Date(endDate);
      result.setDate(result.getDate() - 7);

      return result;
    }
    if (dateOption === TimeFrameOption.LastMonth) {
      const result: Date = new Date(endDate);
      result.setMonth(result.getMonth() - 1);

      return result;
    }
    if (dateOption === TimeFrameOption.AllTime) {
      return new Date(0);
    }
  }

  private getDateInUserTimezone(date: Date): Date {
    const ipreoAccountTimeZoneName =
      this.authService.getCurrentUser().profile.zoneinfo ||
      this.momentRef.tz.guess();

    const dateObject = this.momentRef(date.toUTCString())
      .tz(ipreoAccountTimeZoneName)
      .toObject();

    return new Date(
      dateObject.years,
      dateObject.months,
      dateObject.date,
      dateObject.hours,
      dateObject.minutes,
      dateObject.seconds,
      dateObject.milliseconds
    );
  }

  private roundDate(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  private asUtc(date: Date): Date {
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds()
      )
    );
  }
}
