/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';

import {
  type Observable,
  EMPTY,
  forkJoin as observableForkJoin,
  from as observableFrom,
  of as observableOf,
} from 'rxjs';
import { concatMap, defaultIfEmpty, mapTo, mergeMap } from 'rxjs/operators';

import { OngoingOfflineDashboardStoreService } from '@core/offline-core/dashboard/ongoing-offline-dashboard-store.service';
import { type WorkspaceNameWrapper } from '@core/offline-core/dashboard/workspace-name.model';
import { InternetConnectionService } from '@core/offline-core/internet-connection/internet-connection.service';

import { type OngoingWorkspace } from '../../../workspace/ongoing/workspace/ongoing-workspace.model';

@Injectable()
export class OngoingOfflineWorkspaceNameSyncService {
  constructor(
    private ongoingOfflineDashboardStoreService: OngoingOfflineDashboardStoreService,
    private internetConnectionService: InternetConnectionService
  ) {}

  public syncWorkspaceNames(
    companyId: string,
    onlineWorkspaces: WorkspaceNameWrapper[]
  ): Observable<void> {
    const noWorkspaces = !onlineWorkspaces || onlineWorkspaces.length === 0;
    if (noWorkspaces || this.internetConnectionService.isOffline()) {
      return observableOf(null);
    }

    const syncFlow = this.ongoingOfflineDashboardStoreService
      .getDashboardItemByCompanyId(companyId)
      .pipe(
        mergeMap((offlineWorkspaces: OngoingWorkspace[]) => {
          const isNoOfflineDashboardItems =
            !offlineWorkspaces || offlineWorkspaces.length === 0;
          if (isNoOfflineDashboardItems) {
            return EMPTY;
          }
          return observableFrom(offlineWorkspaces);
        }),
        concatMap((offlineWorkspace: OngoingWorkspace) => {
          const onlineWorkspaceItem = onlineWorkspaces.find(
            (onlineWorkspace) => onlineWorkspace.id === offlineWorkspace.id
          );
          if (
            onlineWorkspaceItem &&
            onlineWorkspaceItem.name !== offlineWorkspace.name
          ) {
            offlineWorkspace.name = onlineWorkspaceItem.name;
            return this.ongoingOfflineDashboardStoreService
              .saveDashboardItem(offlineWorkspace)
              .pipe(mapTo(null));
          }
          return EMPTY;
        }),
        defaultIfEmpty(null)
      );

    return observableForkJoin([syncFlow]).pipe(mapTo(null));
  }
}
