/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Inject, Injectable } from '@angular/core';
import {
  type AuthorizationServiceConfiguration,
  type TokenResponse,
} from '@openid/appauth';

import jwt_decode from 'jwt-decode';
import {
  defer,
  from as observableFrom,
  Observable,
  of,
  throwError as observableThrowError,
} from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';

import { AngularRequestor } from '@core/openId/src/AngularRequestor';
import { IonicAuthorizationServiceConfiguration } from '@core/openId/src/IonicAuthorizationServiceConfiguration';
import {
  type UserProfile,
  GET_CONFIGURATION_ERROR,
  OPEN_ID_CONFIG,
  OpenIdConfig,
} from '@core/openId/src/OpenId.dictionary';
import { ReusableStream } from '@shared/decorators/reusable-stream.decorator';

@Injectable()
export class OpenIdFetchService {
  private fetchConfigurationRetryTrigger$: Observable<any>;

  constructor(
    @Inject(OPEN_ID_CONFIG) public config: OpenIdConfig,
    private requestor: AngularRequestor
  ) {}

  public setConfigurationRetryTrigger(
    fetchConfigurationTrigger$: Observable<any>
  ): void {
    this.fetchConfigurationRetryTrigger$ = fetchConfigurationTrigger$;
  }

  @ReusableStream
  public fetchConfiguration(): Observable<AuthorizationServiceConfiguration> {
    return defer(() =>
      observableFrom(
        IonicAuthorizationServiceConfiguration.fetchFromIssuer(
          this.config.authority,
          this.requestor
        )
      )
    ).pipe(
      catchError((error: Error) => {
        console.error(`${GET_CONFIGURATION_ERROR} ${JSON.stringify(error)}`);

        return observableThrowError(error);
      }),
      retryWhen(() => this.fetchConfigurationRetryTrigger$)
    );
  }

  public fetchUserInfo(token: TokenResponse): Observable<UserProfile> {
    const decodedIdToken = jwt_decode(token.idToken);
    return of(decodedIdToken as UserProfile);
  }
}
