/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import {
  type HttpEvent,
  type HttpHandler,
  type HttpInterceptor,
  type HttpRequest,
} from '@angular/common/http';
import { type Router } from '@angular/router';

import { type Observable, defer, from, throwError } from 'rxjs';
import { catchError, retryWhen, switchMap } from 'rxjs/operators';

import { type PcsUser } from '@core/auth/strategies/pcs-user.model';
import { type UnauthorizedHandleService } from '@core/auth/unauthorized/unauthorized-handle.service';

import { type AuthService } from './auth.service';
import { type SessionStoreService } from './session/session-store.service';

export class AuthHttpInterceptor implements HttpInterceptor {
  public responseType: string;

  private isSignOutCalled: boolean = false;

  constructor(
    private authService: AuthService,
    private unauthorizedHandleService: UnauthorizedHandleService,
    private sessionStoreService: SessionStoreService,
    private router: Router
  ) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return defer(() => this.createRequest(request, next)).pipe(
      catchError((err) => this.handleNotPrismUserError(err)),
      retryWhen((errors) =>
        errors.pipe(
          switchMap((error) => {
            if (error?.status === 401 && !this.isSignOutCalled) {
              this.isSignOutCalled = true;
              return this.unauthorizedHandleService.handle();
            }
            throw error;
          })
        )
      )
    );
  }

  private handleNotPrismUserError(err): Observable<any> {
    try {
      this.responseType = JSON.parse(err._body).type;
    } catch (e) {
      this.responseType = '';
    }

    if (err.status === 401) {
      if (this.responseType === 'NotPrismUser') {
        this.router.navigate(['/no-access'], { replaceUrl: true });
        return from([]);
      }
    }
    return throwError(() => err);
  }

  private createRequest(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    const currentUser: PcsUser = this.authService.getCurrentUser();
    let clonedRequest: HttpRequest<any> = request.clone();

    const Authorization = `${currentUser.token_type || ''} ${
      currentUser.access_token || ''
    }`;

    if (
      !clonedRequest.headers.get('Authorization') &&
      Boolean(Authorization.trim())
    ) {
      clonedRequest = clonedRequest.clone({
        setHeaders: {
          Authorization,
        },
      });
    }

    const SessionId = this.sessionStoreService.getSessionId();
    if (SessionId) {
      clonedRequest = clonedRequest.clone({
        setHeaders: {
          SessionId,
        },
      });
    }

    return next.handle(clonedRequest);
  }
}
