/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import {
  type HttpEvent,
  HttpClient,
  HttpEventType,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { of as observableOf } from 'rxjs';
import { catchError, filter, mergeMap, switchMap } from 'rxjs/operators';

import { FailReleaseOfflineActivityException } from '@core/offline-core/activity/fail-release-offline-activity.exception';
import { type OfflineActivityModel } from '@core/offline-core/activity/offline-activity.model';
import { OfflineActivityStoreService } from '@core/offline-core/activity/offline-activity-store.service';
import { InternetConnectionService } from '@core/offline-core/internet-connection/internet-connection.service';
import { PCSErrorHandler } from '@core/pcs-error-handler/pcs-error-handler';

@Injectable()
export class OfflineActivityReleaseService {
  constructor(
    private http: HttpClient,
    private offlineActivityStoreService: OfflineActivityStoreService,
    private internetConnectionService: InternetConnectionService,
    private pcsErrorHandler: PCSErrorHandler
  ) {}

  public setup(): void {
    // Try to release offline activities once we sign in
    this.offlineActivityStoreService
      .getOfflineActivities()
      .subscribe((offlineActivities) =>
        this.releaseOfflineActivities(offlineActivities)
      );

    // Start listen internet up event to release offline activities
    this.internetConnectionService
      .startObserveInternetUp()
      .pipe(
        mergeMap(() => this.offlineActivityStoreService.getOfflineActivities())
      )
      .subscribe((offlineActivities) =>
        this.releaseOfflineActivities(offlineActivities)
      );
  }

  public releaseOfflineActivities(
    offlineActivities: OfflineActivityModel[]
  ): void {
    if (!offlineActivities || !offlineActivities.length) {
      return;
    }

    if (this.internetConnectionService.isOffline()) {
      return;
    }

    const currentActivity = offlineActivities[0];

    const params = new HttpParams().set('skipLoader', 'true');
    const request = new HttpRequest(
      currentActivity.method,
      currentActivity.url,
      currentActivity.body,
      { params }
    );

    this.http
      .request(request)
      .pipe(
        filter(
          (event: HttpEvent<HttpEventType>) =>
            event.type === HttpEventType.Response
        ),
        switchMap(() => {
          offlineActivities.shift();

          return this.offlineActivityStoreService.saveOfflineActivities(
            offlineActivities
          );
        }),
        catchError(() => {
          currentActivity.errorsCount++;

          // When we failed 3 times then not release this activity
          if (currentActivity.errorsCount >= 3) {
            this.pcsErrorHandler.handlePCSError(
              new FailReleaseOfflineActivityException(currentActivity)
            );

            offlineActivities.shift();
            return this.offlineActivityStoreService.saveOfflineActivities(
              offlineActivities
            );
          }

          return observableOf(null);
        })
      )
      .subscribe(() => {
        this.releaseOfflineActivities(offlineActivities);
      });
  }
}
