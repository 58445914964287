/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ErrorHandler, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Dialogs } from '@ionic-native/dialogs/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { PendoStatisticsModule } from '@pcs/pendo-statistics';

import { PcsCacheModule } from '@core/cache/cache.module';
// Core folders imports
import { CoreModule } from '@core/core.module';
import { RouterStoreConfig } from '@core/ngrx-store/router/router-store-config';
import { PCSErrorHandler } from '@core/pcs-error-handler/pcs-error-handler';
import { SecurityModule } from '@core/security/security.module';
import { AddFabButtonModule } from '@shared/add-fab-button/add-fab-button.module';
import { DocumentsReorderModule } from '@shared/drag-and-drop/documents-reoder.module';
import { SpinnerAppStartingComponent } from '@shared/spinners/spinner-app-starting/spinner-app-starting.component';

import { AppComponent } from './app.component';
import { getReducers, REDUCER_TOKEN } from './app.reducers';
import { APP_ROUTES_PROVIDER } from './app.router';
import { httpInterceptors } from './http-interceptors';
import { IONIC_CONFIG } from './ionic-config';
import { LayoutComponent } from './layout/layout.component';
import { MainHeaderModule } from './main-header/main-header.module';

@NgModule({
  declarations: [AppComponent, LayoutComponent],
  imports: [
    APP_ROUTES_PROVIDER,
    CoreModule,
    PcsCacheModule.forRoot(),
    SecurityModule.forRoot(),
    MainHeaderModule,
    StoreModule.forRoot(REDUCER_TOKEN),
    StoreRouterConnectingModule.forRoot(RouterStoreConfig),
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(IONIC_CONFIG),
    IonicStorageModule.forRoot(),
    AddFabButtonModule.forRoot(),
    PendoStatisticsModule,
    DocumentsReorderModule,
    SpinnerAppStartingComponent,
  ],
  bootstrap: [AppComponent],
  providers: [
    StatusBar,
    SplashScreen,
    Dialogs,
    AppVersion,
    ...httpInterceptors,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: REDUCER_TOKEN,
      deps: [],
      useFactory: getReducers,
    },
    {
      provide: ErrorHandler,
      deps: [Injector],
      useClass: PCSErrorHandler,
    },
    PCSErrorHandler,
    WebView,
  ],
})
export class AppModule {}
