/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';

import {
  type Observable,
  of as observableOf,
  throwError as observableThrowError,
} from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { ConfidentialityNoticeDataService } from '@core/confidentiality-notice/confidentiality-notice-data.service';
import { type GetAgreementOptions } from '@core/confidentiality-notice/models/get-agreement-options.model';
import { ConfiAgreementStoreService } from '@core/confidentiality-notice-offline/confi-agreement-store.service';
import { ConfidentialityFileService } from '@core/confidentiality-notice-offline/confidentiality-file.service';
import { AGREEMENT_OFFLINE_SAVE_ERROR } from '@core/confidentiality-notice-offline/constants/agreement-offline-save-error.constant';
import { InternetConnectionService } from '@core/offline-core/internet-connection/internet-connection.service';
import { PcsNoInternetConnectionException } from '@shared/pcs-exceptions/pcs-no-internet-connection.exception';
import { type ConfidentialityNoticeInfoModel } from '@workspace-shared/confidentiality-notice/confidentiality-notice-info.model';

import { type ConfidentialityAgreement } from '../confidentiality-notice/models/confidentiality-agreement.model';

@Injectable()
export class ConfidentialityNoticeSaveService {
  constructor(
    private confidentialityNoticeDataService: ConfidentialityNoticeDataService,
    private confiAgreementStoreService: ConfiAgreementStoreService,
    private confidentialityFileService: ConfidentialityFileService,
    private internetConnectionService: InternetConnectionService
  ) {}

  public attemptToSaveConfidentialityNotice(
    confiInfo: ConfidentialityNoticeInfoModel
  ): Observable<void> {
    if (this.internetConnectionService.isOffline()) {
      throw new PcsNoInternetConnectionException(AGREEMENT_OFFLINE_SAVE_ERROR);
    }
    const options: GetAgreementOptions = {
      skipLoader: true,
      skipNotFoundError: true,
    };

    return this.confidentialityNoticeDataService
      .getAgreement(confiInfo.noticeUrl, options)
      .pipe(
        mergeMap((agreement: ConfidentialityAgreement) => {
          const isAgreementRequired: boolean = !!agreement;

          if (isAgreementRequired) {
            return this.saveConfidentialityNotice(confiInfo, agreement);
          }

          return this.attemptToRemoveConfidentialityNotice(confiInfo);
        }),
        catchError((error) => {
          /* When there is no agreement for workspace, server called in ongoingConfidentialityNoticeDataService.getAgreement
           * return 404 error, and we should handle this case*/
          if (error.status === 404) {
            return this.attemptToRemoveConfidentialityNotice(confiInfo);
          }
          return observableThrowError(error);
        }),
        map(() => null)
      );
  }

  public attemptToRemoveConfidentialityNotice(
    confiInfo: ConfidentialityNoticeInfoModel
  ): Observable<void> {
    const agreementStoreKey = confiInfo.agreementStoreKey;
    const workspaceId = parseInt(confiInfo.workspaceId, 10);

    return this.confiAgreementStoreService
      .getAgreementByWorkspaceId(workspaceId, agreementStoreKey)
      .pipe(
        mergeMap((storedAgreement: ConfidentialityAgreement) => {
          const isAgreementAlreadyStored = !!storedAgreement;

          if (isAgreementAlreadyStored) {
            return this.removeConfiAgreementWithFile(
              confiInfo,
              storedAgreement
            );
          }

          return observableOf(null);
        })
      );
  }

  private saveConfidentialityNotice(
    confiInfo: ConfidentialityNoticeInfoModel,
    agreement: ConfidentialityAgreement
  ): Observable<void> {
    const agreementStoreKey: string = confiInfo.agreementStoreKey;
    const workspaceId: number = parseInt(confiInfo.workspaceId, 10);

    return this.confiAgreementStoreService
      .getAgreementByWorkspaceId(workspaceId, agreementStoreKey)
      .pipe(
        mergeMap((storedAgreement: ConfidentialityAgreement) => {
          const isAgreementAlreadyStored: boolean = !!storedAgreement;
          const isStoredAgreementEqualToNewOne: boolean =
            this.isStoredAgreementEqualToNewOne(agreement, storedAgreement);

          if (isStoredAgreementEqualToNewOne) {
            return observableOf(null);
          }

          if (isAgreementAlreadyStored && !isStoredAgreementEqualToNewOne) {
            /* We should remove previous version of confi. agreement when get the newer one*/
            return this.removeConfiAgreementWithFile(
              confiInfo,
              storedAgreement
            ).pipe(
              mergeMap(() =>
                this.saveConfiAgreementWithFile(confiInfo, agreement)
              )
            );
          }

          return this.saveConfiAgreementWithFile(confiInfo, agreement);
        })
      );
  }

  private saveConfiAgreementWithFile(
    confiInfo: ConfidentialityNoticeInfoModel,
    agreement: ConfidentialityAgreement
  ): Observable<void> {
    const agreementStoreKey: string = confiInfo.agreementStoreKey;
    agreement.workspaceId = parseInt(confiInfo.workspaceId, 10);

    return this.confiAgreementStoreService
      .saveAgreement(agreement, agreementStoreKey)
      .pipe(
        mergeMap(() =>
          this.confidentialityFileService.saveAgreementFile(agreement)
        )
      );
  }

  private removeConfiAgreementWithFile(
    confiInfo: ConfidentialityNoticeInfoModel,
    agreement: ConfidentialityAgreement
  ): Observable<void> {
    const agreementStoreKey = confiInfo.agreementStoreKey;
    agreement.workspaceId = Number(confiInfo.workspaceId);
    const workspaceId = parseInt(confiInfo.workspaceId, 10);

    return this.confiAgreementStoreService
      .removeAgreement(workspaceId, agreementStoreKey)
      .pipe(
        mergeMap(() =>
          this.confidentialityFileService.removeSavedAgreementFile(agreement)
        )
      );
  }

  private isStoredAgreementEqualToNewOne(agreement, storedAgreement): boolean {
    if (storedAgreement) {
      const isSameAgreementFile: boolean =
        storedAgreement.uploadedOn === agreement.uploadedOn;
      const isSameFrequency: boolean =
        storedAgreement.frequency === agreement.frequency;

      return isSameAgreementFile && isSameFrequency;
    }

    return false;
  }
}
