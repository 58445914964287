/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { type Observable, of as observableOf } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { type StorageStrategyInterface } from './storage-strategy.interface';
import { type StorageStrategyFactoryResolverService } from './storage-strategy-factory-resolver.service';

export class BaseStorageService<T> {
  private storeStrategy: StorageStrategyInterface;
  private cache: Map<string, T> = new Map();

  constructor(
    protected storageStrategyResolverService: StorageStrategyFactoryResolverService
  ) {}

  protected get(key: string): Observable<T> {
    this.setup();

    if (this.cache.has(key)) {
      return observableOf(this.cache.get(key));
    }

    return this.storeStrategy.get(key).pipe(
      map((value) => JSON.parse(value)),
      tap((value: T) => this.cache.set(key, value))
    );
  }

  protected set(key: string, value: T): Observable<void> {
    this.setup();

    return this.storeStrategy
      .set(key, value)
      .pipe(tap(() => this.cache.set(key, value)));
  }

  protected delete(key: string): Observable<void> {
    this.setup();

    return this.storeStrategy
      .delete(key)
      .pipe(tap(() => this.cache.delete(key)));
  }

  private setup(): void {
    if (!this.storeStrategy) {
      this.storeStrategy = this.storageStrategyResolverService.getStorage();
    }
  }
}
